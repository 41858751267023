import { getEntity } from '@ngneat/elf-entities';
import { useEffect } from 'react';

import {
  upsertFiltersWithProvidedValue,
  ROLE_GRANULARITY_FILTERS,
  clearRoleCacheFilters,
  filterStore,
  addActiveFiltersIds,
  useRoleTaxonomySetting,
  RoleTaxonomySettings,
  useSingleOrMoreFilterState,
  OtherFilterNames,
  SelectFilter,
  FilterList,
  FilterItem,
  ViewTypes,
  SelectionCategories,
  upsertFilter,
} from '@revelio/filtering';
import { pipe, tap } from 'rxjs';

export const useAdaptiveRoleTaxonomy = ({
  viewType,
  primaryFilters,
}: {
  viewType: ViewTypes;
  primaryFilters: SelectionCategories[];
}) => {
  const { isEnabled: isCustomRoleTaxonomyEnabled, value } =
    useRoleTaxonomySetting();

  useEffect(() => {
    if (isCustomRoleTaxonomyEnabled) {
      const customTaxonomyFilter = filterStore.query(
        getEntity(OtherFilterNames.ROLE_TAXONOMY)
      );
      if (!customTaxonomyFilter) {
        upsertFiltersWithProvidedValue(
          {
            [OtherFilterNames.ROLE_TAXONOMY]: 'default',
          },
          true
        );
      } else {
        addActiveFiltersIds([OtherFilterNames.ROLE_TAXONOMY]);
      }
    }
  }, [isCustomRoleTaxonomyEnabled]);

  useSingleOrMoreFilterState<SelectFilter<string>, SelectFilter<FilterList>[]>(
    [
      OtherFilterNames.ROLE_TAXONOMY,
      ...primaryFilters,
      ...ROLE_GRANULARITY_FILTERS,
    ],
    pipe(
      tap((filters) => {
        if (viewType === ViewTypes.COMPANY && isCustomRoleTaxonomyEnabled) {
          const companyFilter = (filters as SelectFilter<FilterList>[]).find(
            (item) => item.id === SelectionCategories.COMPANY
          );

          const usingAdaptiveRoleTaxonomy =
            value === RoleTaxonomySettings.ADAPTIVE;
          const currentCustomTaxonomyFilter = (
            filters as SelectFilter<FilterItem>[]
          ).find((item) => item.id === OtherFilterNames.ROLE_TAXONOMY);
          if (usingAdaptiveRoleTaxonomy) {
            const ricsK50Id = companyFilter?.value[0].ricsK50Id;
            const calculatedCustomRoleAdaptiveTaxonomy = ricsK50Id;
            if (
              ricsK50Id &&
              currentCustomTaxonomyFilter?.value.id !==
                calculatedCustomRoleAdaptiveTaxonomy
            ) {
              clearRoleCacheFilters();
              upsertFilter(OtherFilterNames.ROLE_TAXONOMY, {
                value: {
                  id: calculatedCustomRoleAdaptiveTaxonomy,
                  value: calculatedCustomRoleAdaptiveTaxonomy,
                },
              });
            }
          } else {
            const REVELIO_CUSTOM_ROLE_TAXONOMY = 'default';
            if (
              currentCustomTaxonomyFilter?.value.id !==
              REVELIO_CUSTOM_ROLE_TAXONOMY
            ) {
              clearRoleCacheFilters();
              upsertFilter(OtherFilterNames.ROLE_TAXONOMY, {
                value: {
                  id: REVELIO_CUSTOM_ROLE_TAXONOMY,
                  value: REVELIO_CUSTOM_ROLE_TAXONOMY,
                },
              });
            }
          }
        }
      })
    )
  );
};
