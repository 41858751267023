/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string | number; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
};

export type ApiKey = {
  __typename?: 'ApiKey';
  apiKey: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

export enum BadUsersVersion {
  V0 = 'V0',
  V1 = 'V1',
  V2 = 'V2',
}

export enum BinaryOperation {
  And = 'AND',
  Or = 'OR',
}

export type CategoryMetric = {
  __typename?: 'CategoryMetric';
  category?: Maybe<Array<Maybe<CategoryTimeseries>>>;
};

export type CategorySubfilter = {
  custom_role?: InputMaybe<Array<InputMaybe<CustomRoleFilterElement>>>;
  education?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  ethnicity?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  gender?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  geography?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  industry?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  job_category?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  seniority?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  skill?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type CategoryTimeseries = {
  __typename?: 'CategoryTimeseries';
  metadata?: Maybe<CompositionMetadata>;
  timeseries?: Maybe<Array<Maybe<TimeDataPoint>>>;
};

export type Client = {
  __typename?: 'Client';
  active: Scalars['Boolean']['output'];
  client_name?: Maybe<Scalars['String']['output']>;
  company_lists?: Maybe<Array<Scalars['String']['output']>>;
  data_builder_configuration?: Maybe<PipelineClientConfig>;
  deliverables?: Maybe<Array<PipelineConfig>>;
  linkup_postings: Scalars['Boolean']['output'];
  live: Scalars['Boolean']['output'];
  num_seats: Scalars['String']['output'];
  reports_configuration?: Maybe<ClientReportsConfig>;
  tabs?: Maybe<Array<Tab>>;
  unified_postings: Scalars['Boolean']['output'];
  users?: Maybe<Array<Maybe<User>>>;
};

export type ClientDeliverable = {
  __typename?: 'ClientDeliverable';
  client: Scalars['String']['output'];
  company_reference?: Maybe<Scalars['String']['output']>;
  company_sets?: Maybe<Array<Maybe<CompanySet>>>;
  dashboard_companies?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  deliverables: Array<Maybe<DeliverableOutput>>;
  lag_data?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  pipeline_input?: Maybe<Scalars['String']['output']>;
};

export type ClientDeliverableInput = {
  client: Scalars['String']['input'];
  company_reference?: InputMaybe<Scalars['String']['input']>;
  company_sets?: InputMaybe<Array<InputMaybe<CompanySet>>>;
  company_tickers?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dashboard_companies?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  deliverables: Array<InputMaybe<Deliverable>>;
  lag_data?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  pipeline_input?: InputMaybe<Scalars['String']['input']>;
};

export type ClientDeliverableOutput = {
  __typename?: 'ClientDeliverableOutput';
  created_at?: Maybe<Scalars['String']['output']>;
  deliverable: ClientDeliverable;
  job_id: Scalars['Int']['output'];
  job_name?: Maybe<Scalars['String']['output']>;
  status: DeliveryStatus;
  updated_at?: Maybe<Scalars['String']['output']>;
  user?: Maybe<User>;
};

export type ClientDeliverableResponse = {
  __typename?: 'ClientDeliverableResponse';
  delivery_id?: Maybe<Scalars['Int']['output']>;
  user_id?: Maybe<Scalars['String']['output']>;
};

export type ClientReportsConfig = {
  __typename?: 'ClientReportsConfig';
  all_report_types: Array<Scalars['String']['output']>;
  defaults: Array<ReportTypeEnum>;
  report_types: Array<ReportTypeEnum>;
};

export type ClientReportsConfigInput = {
  report_types: Array<ReportTypeEnum>;
};

export type ClientRequest = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  client_name: Scalars['String']['input'];
  company_lists?: InputMaybe<Array<Scalars['String']['input']>>;
  data_builder_configuration?: InputMaybe<PipelineClientConfigInput>;
  linkup_postings?: InputMaybe<Scalars['Boolean']['input']>;
  live?: InputMaybe<Scalars['Boolean']['input']>;
  num_seats?: InputMaybe<Scalars['String']['input']>;
  reports_configuration?: InputMaybe<ClientReportsConfigInput>;
  tabs?: InputMaybe<Array<Tab>>;
  unified_postings?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ColumnInfo = {
  __typename?: 'ColumnInfo';
  name?: Maybe<Scalars['String']['output']>;
  output_name?: Maybe<Scalars['String']['output']>;
};

export type ColumnInfoInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  output_name?: InputMaybe<Scalars['String']['input']>;
};

export type CompanyInfoConfiguration = {
  include_subsidiaries?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CompanyInfoConfigurationOutput = {
  __typename?: 'CompanyInfoConfigurationOutput';
  include_subsidiaries?: Maybe<Scalars['Boolean']['output']>;
};

export type CompanyList = {
  __typename?: 'CompanyList';
  clients?: Maybe<Array<Maybe<Client>>>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  viewable?: Maybe<Scalars['Boolean']['output']>;
};

export enum CompanyMapperVersion {
  V1 = 'V1',
  V2 = 'V2',
}

export type CompanyRefInfo = {
  __typename?: 'CompanyRefInfo';
  dataUnavailableIn?: Maybe<ManifestKind>;
  id?: Maybe<Scalars['ID']['output']>;
  industryId?: Maybe<Scalars['ID']['output']>;
  isin?: Maybe<Scalars['String']['output']>;
  longName?: Maybe<Scalars['String']['output']>;
  rank?: Maybe<Scalars['Int']['output']>;
  rcid?: Maybe<Scalars['String']['output']>;
  ricksK50?: Maybe<Scalars['String']['output']>;
  ricsK50Id?: Maybe<Scalars['ID']['output']>;
  sedol?: Maybe<Scalars['String']['output']>;
  shortName?: Maybe<Scalars['String']['output']>;
  ticker?: Maybe<Scalars['String']['output']>;
  version?: Maybe<Scalars['String']['output']>;
};

export type CompanyRefInfoInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  industryId?: InputMaybe<Scalars['ID']['input']>;
  isin?: InputMaybe<Scalars['String']['input']>;
  longName?: InputMaybe<Scalars['String']['input']>;
  rank?: InputMaybe<Scalars['Int']['input']>;
  rcid?: InputMaybe<Scalars['String']['input']>;
  sedol?: InputMaybe<Scalars['String']['input']>;
  shortName?: InputMaybe<Scalars['String']['input']>;
  ticker?: InputMaybe<Scalars['String']['input']>;
  version?: InputMaybe<Scalars['String']['input']>;
};

export enum CompanySet {
  Eurostoxx_50 = 'eurostoxx_50',
  Ftse_100 = 'ftse_100',
  Nasdaq_100 = 'nasdaq_100',
  PublicUs = 'public_us',
  RevelioShared = 'revelio_shared',
  RevelioSharedPrivate = 'revelio_shared_private',
  RevelioSharedPublic = 'revelio_shared_public',
  Russell_1000 = 'russell_1000',
  Sp_500 = 'sp_500',
}

export type CompensationCategory = {
  __typename?: 'CompensationCategory';
  metadata?: Maybe<Metadata>;
  metrics?: Maybe<CompensationMetrics>;
};

export enum CompensationDimension {
  Company = 'company',
  Geography = 'geography',
  Role = 'role',
}

export type CompensationFilters = {
  company?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  end_date?: InputMaybe<Scalars['String']['input']>;
  ethnicity?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  gender?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  highest_degree?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  industry?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  job_category?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  msa?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  region?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  role_k150?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  role_k1500?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  seniority?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  start_date?: InputMaybe<Scalars['String']['input']>;
};

export type CompensationMetrics = {
  __typename?: 'CompensationMetrics';
  count?: Maybe<Scalars['Int']['output']>;
  share?: Maybe<Scalars['Float']['output']>;
};

export type CompensationResponse = {
  __typename?: 'CompensationResponse';
  category?: Maybe<Array<Maybe<CompensationCategory>>>;
  metadata?: Maybe<Metadata>;
};

export enum CompositionDimension1 {
  Company = 'company',
  Geography = 'geography',
  Role = 'role',
}

export enum CompositionDimension2 {
  Month = 'month',
}

export type CompositionMetadata = {
  __typename?: 'CompositionMetadata';
  id?: Maybe<Scalars['Int']['output']>;
  longName?: Maybe<Scalars['String']['output']>;
  metadata_type?: Maybe<Scalars['String']['output']>;
  shortName?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type CompositionMetrics = {
  __typename?: 'CompositionMetrics';
  attrition_rate?: Maybe<SingleValueMetric>;
  educations?: Maybe<CategoryMetric>;
  ethnicities?: Maybe<CategoryMetric>;
  genders?: Maybe<CategoryMetric>;
  geographies?: Maybe<CategoryMetric>;
  growth_rate?: Maybe<SingleValueMetric>;
  headcount?: Maybe<SingleValueMetric>;
  hiring_rate?: Maybe<SingleValueMetric>;
  industries?: Maybe<CategoryMetric>;
  job_categories?: Maybe<CategoryMetric>;
  salary?: Maybe<SingleValueMetric>;
  seniorities?: Maybe<CategoryMetric>;
  skills?: Maybe<CategoryMetric>;
  tenure?: Maybe<SingleValueMetric>;
};

export type CompositionResponse = {
  __typename?: 'CompositionResponse';
  metadata?: Maybe<CompositionMetadata>;
  metrics?: Maybe<CompositionMetrics>;
};

export type CreatePresetRequest = {
  default: Scalars['Boolean']['input'];
  filters: Scalars['String']['input'];
  name: Scalars['String']['input'];
  shared: Scalars['Boolean']['input'];
  view: View;
};

export type CreateReportRequest = {
  client: Scalars['String']['input'];
  name: Scalars['String']['input'];
  reports?: InputMaybe<Array<ReportRequest>>;
};

export type CreateReportResponse = {
  __typename?: 'CreateReportResponse';
  report_id?: Maybe<Scalars['Int']['output']>;
  user_id?: Maybe<Scalars['String']['output']>;
};

export enum CrossSectionalScalingVersion {
  V1 = 'V1',
  V1_1 = 'V1_1',
  V2 = 'V2',
}

export type CustomColumn = {
  __typename?: 'CustomColumn';
  name: Scalars['String']['output'];
  step?: Maybe<Array<Maybe<CustomColumnStep>>>;
};

export type CustomColumnCase = {
  __typename?: 'CustomColumnCase';
  binary?: Maybe<BinaryOperation>;
  conditions?: Maybe<Array<Maybe<CustomColumnCondition>>>;
};

export type CustomColumnCaseInput = {
  binary?: InputMaybe<BinaryOperation>;
  conditions: Array<InputMaybe<CustomColumnConditionInput>>;
};

export type CustomColumnCondition = {
  __typename?: 'CustomColumnCondition';
  operation: OperationType;
  value: Array<Maybe<Scalars['String']['output']>>;
  variable: CustomColumnConditionVariable;
};

export type CustomColumnConditionInput = {
  operation: OperationType;
  value: Array<InputMaybe<Scalars['String']['input']>>;
  variable: CustomColumnConditionVariable;
};

export enum CustomColumnConditionVariable {
  Company = 'company',
  Country = 'country',
  DaysActive = 'days_active',
  Description = 'description',
  Enddate = 'enddate',
  Ethnicity = 'ethnicity',
  FieldOfStudy = 'field_of_study',
  Gender = 'gender',
  HighestDegree = 'highest_degree',
  MappedRole = 'mapped_role',
  MetroArea = 'metro_area',
  Month = 'month',
  Msa = 'msa',
  Prestige = 'prestige',
  Provider = 'provider',
  RatingOverall = 'rating_overall',
  Region = 'region',
  Remote = 'remote',
  RemoteSuitability = 'remote_suitability',
  ReviewCons = 'review_cons',
  ReviewPros = 'review_pros',
  RoleK7 = 'role_k7',
  RoleK50 = 'role_k50',
  RoleK150 = 'role_k150',
  RoleK300 = 'role_k300',
  RoleK500 = 'role_k500',
  RoleK1000 = 'role_k1000',
  RoleK1250 = 'role_k1250',
  Salary = 'salary',
  School = 'school',
  Seniority = 'seniority',
  Skill = 'skill',
  SkillExtracted = 'skill_extracted',
  SkillK25 = 'skill_k25',
  SkillK25Extracted = 'skill_k25_extracted',
  SkillK25Predicted = 'skill_k25_predicted',
  SkillK50 = 'skill_k50',
  SkillK50Extracted = 'skill_k50_extracted',
  SkillK50Predicted = 'skill_k50_predicted',
  SkillK75 = 'skill_k75',
  SkillK75Extracted = 'skill_k75_extracted',
  SkillK75Predicted = 'skill_k75_predicted',
  SkillK700 = 'skill_k700',
  SkillK700Extracted = 'skill_k700_extracted',
  SkillK700Predicted = 'skill_k700_predicted',
  SkillK2500 = 'skill_k2500',
  SkillK2500Extracted = 'skill_k2500_extracted',
  SkillK2500Predicted = 'skill_k2500_predicted',
  SkillMapped = 'skill_mapped',
  SkillMappedExtracted = 'skill_mapped_extracted',
  SkillMappedPredicted = 'skill_mapped_predicted',
  SkillPredicted = 'skill_predicted',
  Soc6d = 'soc6d',
  Startdate = 'startdate',
  State = 'state',
  Title = 'title',
  TotalCompensation = 'total_compensation',
}

export type CustomColumnInput = {
  name: Scalars['String']['input'];
  step: Array<InputMaybe<CustomColumnStepInput>>;
};

export type CustomColumnStep = {
  __typename?: 'CustomColumnStep';
  case?: Maybe<CustomColumnCase>;
  then?: Maybe<Scalars['String']['output']>;
};

export type CustomColumnStepInput = {
  case?: InputMaybe<CustomColumnCaseInput>;
  then: Scalars['String']['input'];
};

export type CustomRoleFilter = {
  entities?: InputMaybe<Array<InputMaybe<CustomRoleFilterElement>>>;
  taxonomyId?: InputMaybe<CustomRoleTaxonomySelection>;
};

export type CustomRoleFilterElement = {
  id?: InputMaybe<Scalars['String']['input']>;
  levelId?: InputMaybe<Scalars['String']['input']>;
};

export type CustomRoleTaxonomyLevel = {
  __typename?: 'CustomRoleTaxonomyLevel';
  id?: Maybe<Scalars['String']['output']>;
  label?: Maybe<Scalars['String']['output']>;
};

export type CustomRoleTaxonomyLevelMetadata = {
  __typename?: 'CustomRoleTaxonomyLevelMetadata';
  data?: Maybe<Array<Maybe<CustomRoleTaxonomyMetadata>>>;
  levelID?: Maybe<Scalars['String']['output']>;
};

export type CustomRoleTaxonomyMetadata = {
  __typename?: 'CustomRoleTaxonomyMetadata';
  id?: Maybe<Scalars['ID']['output']>;
  rawTitles: Array<Scalars['String']['output']>;
};

export type CustomRoleTaxonomyNode = {
  __typename?: 'CustomRoleTaxonomyNode';
  id?: Maybe<Scalars['ID']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  levelId?: Maybe<Scalars['String']['output']>;
};

export type CustomRoleTaxonomyPath = {
  __typename?: 'CustomRoleTaxonomyPath';
  id?: Maybe<Scalars['ID']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  levelId?: Maybe<Scalars['String']['output']>;
  parents?: Maybe<Array<Maybe<CustomRoleTaxonomyNode>>>;
};

export type CustomRoleTaxonomyRef = {
  __typename?: 'CustomRoleTaxonomyRef';
  entityMetadata?: Maybe<Array<Maybe<CustomRoleTaxonomyLevelMetadata>>>;
  leafLevelData?: Maybe<Array<Maybe<CustomRoleTaxonomyPath>>>;
  orderedLevels?: Maybe<Array<Maybe<CustomRoleTaxonomyLevel>>>;
};

export type CustomRoleTaxonomySelection = {
  default?: InputMaybe<Scalars['Boolean']['input']>;
  ricsK10Id?: InputMaybe<Scalars['String']['input']>;
  ricsK50Id?: InputMaybe<Scalars['String']['input']>;
};

export type DataPoint = {
  __typename?: 'DataPoint';
  count?: Maybe<Scalars['Float']['output']>;
  date?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  share?: Maybe<Scalars['Float']['output']>;
  shortName?: Maybe<Scalars['String']['output']>;
};

export enum Dataset {
  CompanyInfo = 'company_info',
  IndividualReviews = 'individual_reviews',
  PostingsIndividual = 'postings_individual',
}

export type Default = {
  __typename?: 'Default';
  filters?: Maybe<Scalars['String']['output']>;
  view?: Maybe<View>;
};

export type Deliverable = {
  company_reference?: InputMaybe<Scalars['String']['input']>;
  company_sets?: InputMaybe<Array<InputMaybe<CompanySet>>>;
  company_tickers?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dashboard_companies?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  model_versions: ModelVersions;
  pipeline: Pipeline;
  pipeline_input?: InputMaybe<Scalars['String']['input']>;
  s3_delivery?: InputMaybe<S3Delivery>;
  snowflake_delivery?: InputMaybe<SnowflakeDelivery>;
};

export type DeliverableConfig = {
  __typename?: 'DeliverableConfig';
  batchTime?: Maybe<Scalars['String']['output']>;
  cacheValue?: Maybe<Scalars['String']['output']>;
  imageName?: Maybe<Scalars['String']['output']>;
  isLocal?: Maybe<Scalars['Boolean']['output']>;
  jobUpdateInterval?: Maybe<Scalars['String']['output']>;
  namespace?: Maybe<Scalars['String']['output']>;
  reports_env?: Maybe<Scalars['String']['output']>;
  reports_image?: Maybe<Scalars['String']['output']>;
  reports_namespace?: Maybe<Scalars['String']['output']>;
  testDeliverable?: Maybe<Scalars['Boolean']['output']>;
};

export type DeliverableOutput = {
  __typename?: 'DeliverableOutput';
  company_reference?: Maybe<Scalars['String']['output']>;
  company_sets?: Maybe<Array<Maybe<CompanySet>>>;
  dashboard_companies?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  model_versions: ModelVersionsOutput;
  pipeline: PipelineOutput;
  pipeline_input?: Maybe<Scalars['String']['output']>;
  s3_delivery?: Maybe<S3DeliveryOutput>;
  snowflake_delivery?: Maybe<SnowflakeDeliveryOutput>;
};

export enum DeliveryStatus {
  Created = 'CREATED',
  Failed = 'FAILED',
  Finished = 'FINISHED',
  Running = 'RUNNING',
  Scheduled = 'SCHEDULED',
  Undefined = 'UNDEFINED',
}

export enum Dimension {
  Company = 'COMPANY',
  Country = 'COUNTRY',
  Industry = 'INDUSTRY',
  Msa = 'MSA',
  Region = 'REGION',
  RoleK7 = 'ROLE_K7',
  RoleK150 = 'ROLE_K150',
  RoleK1500 = 'ROLE_K1500',
}

export enum Dimension1 {
  Company = 'company',
  Geography = 'geography',
  Role = 'role',
}

export type EntityCompetitors = {
  __typename?: 'EntityCompetitors';
  closeness_score: Scalars['Float']['output'];
  metadata?: Maybe<Metadata>;
};

export type EntityFilters = {
  company?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  job_category?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  role_k150?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  role_k1500?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  top_competitors?: InputMaybe<Scalars['Int']['input']>;
};

export type EntityInput = {
  type: EntityTypeEnum;
  values: Array<Scalars['String']['input']>;
};

export type EntityOutput = {
  __typename?: 'EntityOutput';
  id: Scalars['String']['output'];
  type: EntityTypeEnum;
  values: Array<Scalars['String']['output']>;
};

export type EntityResponse = {
  __typename?: 'EntityResponse';
  competitors?: Maybe<Array<Maybe<EntityCompetitors>>>;
  summary?: Maybe<EntitySummary>;
};

export type EntitySummary = {
  __typename?: 'EntitySummary';
  description?: Maybe<Scalars['String']['output']>;
  founded?: Maybe<Scalars['String']['output']>;
  headquarters?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export enum EntityTypeEnum {
  Company = 'company',
  Country = 'country',
  Industry = 'industry',
  JobCategory = 'job_category',
  MetroArea = 'metro_area',
  RoleK50 = 'role_k50',
  RoleK150 = 'role_k150',
  Seniority = 'seniority',
}

export enum EthnicityVersion {
  V1 = 'V1',
  V2 = 'V2',
}

export type FilterSalary = {
  current: Scalars['Boolean']['input'];
  end_val: Scalars['Float']['input'];
  non_current: Scalars['Boolean']['input'];
  start_val: Scalars['Float']['input'];
};

export type FilterWithCurrent = {
  current: Scalars['Boolean']['input'];
  id: Scalars['Int']['input'];
};

export type FilterWithCurrentAndTag = {
  current: Scalars['Boolean']['input'];
  dim: TalentDiscoveryV2Tags;
  id: Scalars['Int']['input'];
};

export type FilterWithTag = {
  dim: TalentDiscoveryV2Tags;
  id: Scalars['Int']['input'];
};

export type Filters = {
  category_sub_filter?: InputMaybe<CategorySubfilter>;
  company?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  custom_role?: InputMaybe<CustomRoleFilter>;
  end_date?: InputMaybe<Scalars['String']['input']>;
  ethnicity?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  gender?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  highest_degree?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  industry?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  job_category?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  keywords?: InputMaybe<Array<Scalars['String']['input']>>;
  latest_snapshot?: InputMaybe<Scalars['Boolean']['input']>;
  msa?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  raw_metrics?: InputMaybe<Scalars['Boolean']['input']>;
  rcid?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  region?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  role_k150?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  role_k1500?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  seniority?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  skill_k75?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  skill_k700?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  skill_k3000?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  start_date?: InputMaybe<Scalars['String']['input']>;
  title_raw?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type GeoChildRefInfo = {
  __typename?: 'GeoChildRefInfo';
  id?: Maybe<Scalars['ID']['output']>;
  longName?: Maybe<Scalars['String']['output']>;
  parentId?: Maybe<Scalars['ID']['output']>;
  shortName?: Maybe<Scalars['String']['output']>;
};

export type GeoChildRefInfoInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  longName?: InputMaybe<Scalars['String']['input']>;
  parentId?: InputMaybe<Scalars['ID']['input']>;
  shortName?: InputMaybe<Scalars['String']['input']>;
  version?: InputMaybe<Scalars['String']['input']>;
};

export type GeoCompleteInput = {
  city?: InputMaybe<Scalars['String']['input']>;
  cityIndex?: InputMaybe<Scalars['ID']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  countryIndex?: InputMaybe<Scalars['ID']['input']>;
  metroArea?: InputMaybe<Scalars['String']['input']>;
  metroAreaIndex?: InputMaybe<Scalars['ID']['input']>;
  region?: InputMaybe<Scalars['String']['input']>;
  regionIndex?: InputMaybe<Scalars['ID']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  stateIndex?: InputMaybe<Scalars['ID']['input']>;
  version?: InputMaybe<Scalars['String']['input']>;
};

export type GeoRefInfo = {
  __typename?: 'GeoRefInfo';
  country?: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
  dataUnavailableIn?: Maybe<ManifestKind>;
  id?: Maybe<Scalars['ID']['output']>;
  longName?: Maybe<Scalars['String']['output']>;
  metro_area?: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
  parentId?: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
  region?: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
  shortName?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
};

export enum HeadcountVersion {
  V1 = 'V1',
  V2 = 'V2',
}

export type IndexValues = {
  __typename?: 'IndexValues';
  endMonth?: Maybe<Scalars['String']['output']>;
  endMonthIndex?: Maybe<Scalars['Int']['output']>;
  endWeek?: Maybe<Scalars['String']['output']>;
  endWeekIndex?: Maybe<Scalars['Int']['output']>;
  maxWeek?: Maybe<Scalars['String']['output']>;
  maxWeekIndex?: Maybe<Scalars['Int']['output']>;
  startMonth?: Maybe<Scalars['String']['output']>;
  startMonthIndex?: Maybe<Scalars['Int']['output']>;
  startWeek?: Maybe<Scalars['String']['output']>;
  startWeekIndex?: Maybe<Scalars['Int']['output']>;
};

export type IndividualUserConfiguration = {
  current?: InputMaybe<Scalars['Boolean']['input']>;
  full_history?: InputMaybe<Scalars['Boolean']['input']>;
};

export type IndividualUserConfigurationOutput = {
  __typename?: 'IndividualUserConfigurationOutput';
  current?: Maybe<Scalars['Boolean']['output']>;
  full_history?: Maybe<Scalars['Boolean']['output']>;
};

export type JobCategoryRefInfo = {
  __typename?: 'JobCategoryRefInfo';
  id?: Maybe<Scalars['ID']['output']>;
  longName?: Maybe<Scalars['String']['output']>;
  shortName?: Maybe<Scalars['String']['output']>;
  topCleanedTitles?: Maybe<Scalars['String']['output']>;
  topRawTitles?: Maybe<Scalars['String']['output']>;
};

export type JobCategoryRefInfoInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  longName?: InputMaybe<Scalars['String']['input']>;
  shortName?: InputMaybe<Scalars['String']['input']>;
  topCleanedTitles?: InputMaybe<Scalars['String']['input']>;
  topRawTitles?: InputMaybe<Scalars['String']['input']>;
  version?: InputMaybe<Scalars['String']['input']>;
};

export type JobEmbedding = {
  __typename?: 'JobEmbedding';
  confidence: Scalars['Float']['output'];
  cosine: Scalars['Float']['output'];
  expertise: Scalars['String']['output'];
  job_description: Scalars['String']['output'];
  mapped_role: Scalars['String']['output'];
  mapped_role_distance: Scalars['Float']['output'];
  mapped_role_id: Scalars['Int']['output'];
  norm: Scalars['Float']['output'];
  role_k7: Scalars['String']['output'];
  role_k45: Scalars['String']['output'];
  role_k50: Scalars['String']['output'];
  role_k55: Scalars['String']['output'];
  role_k60: Scalars['String']['output'];
  role_k65: Scalars['String']['output'];
  role_k70: Scalars['String']['output'];
  role_k75: Scalars['String']['output'];
  role_k80: Scalars['String']['output'];
  role_k85: Scalars['String']['output'];
  role_k90: Scalars['String']['output'];
  role_k95: Scalars['String']['output'];
  role_k100: Scalars['String']['output'];
  role_k150: Scalars['String']['output'];
  role_k150_id: Scalars['Int']['output'];
  role_k200: Scalars['String']['output'];
  role_k250: Scalars['String']['output'];
  role_k300: Scalars['String']['output'];
  role_k350: Scalars['String']['output'];
  role_k400: Scalars['String']['output'];
  role_k450: Scalars['String']['output'];
  role_k500: Scalars['String']['output'];
  role_k600: Scalars['String']['output'];
  role_k700: Scalars['String']['output'];
  role_k800: Scalars['String']['output'];
  role_k900: Scalars['String']['output'];
  role_k1000: Scalars['String']['output'];
  role_k1250: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type JobEmbeddingReq = {
  jobs: Array<JobEntry>;
};

export type JobEmbeddingResp = {
  __typename?: 'JobEmbeddingResp';
  jobs: Array<JobEmbedding>;
};

export type JobEntry = {
  expertise?: InputMaybe<Scalars['String']['input']>;
  job_description: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export enum JobTaxonomyVersion {
  V1 = 'V1',
  V2_1 = 'V2_1',
}

export type KeyValue = {
  key?: InputMaybe<MetadataKey>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type KeyValueResp = {
  __typename?: 'KeyValueResp';
  key?: Maybe<MetadataKey>;
  value?: Maybe<Scalars['String']['output']>;
};

export enum LocationVersion {
  V1 = 'V1',
  V2 = 'V2',
  V3 = 'V3',
}

export type MsaRefInfoInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type ManifestKind = {
  __typename?: 'ManifestKind';
  position?: Maybe<Scalars['Boolean']['output']>;
  posting?: Maybe<Scalars['Boolean']['output']>;
  sentiment?: Maybe<Scalars['Boolean']['output']>;
  sentimentReview?: Maybe<Scalars['Boolean']['output']>;
  transition?: Maybe<Scalars['Boolean']['output']>;
};

export type ManifestUsage = {
  __typename?: 'ManifestUsage';
  current?: Maybe<ManifestValues>;
  last?: Maybe<ManifestValues>;
  latest?: Maybe<ManifestValues>;
  must_keep?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  superset?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type ManifestValues = {
  __typename?: 'ManifestValues';
  competitorsCompany?: Maybe<Scalars['String']['output']>;
  positionCompany?: Maybe<Scalars['String']['output']>;
  positionCountry?: Maybe<Scalars['String']['output']>;
  positionIndustry?: Maybe<Scalars['String']['output']>;
  positionJobCategory?: Maybe<Scalars['String']['output']>;
  positionMSA?: Maybe<Scalars['String']['output']>;
  positionMappedRole?: Maybe<Scalars['String']['output']>;
  positionMappedRole7?: Maybe<Scalars['String']['output']>;
  positionMappedRole150?: Maybe<Scalars['String']['output']>;
  positionRegion?: Maybe<Scalars['String']['output']>;
  postingCompany?: Maybe<Scalars['String']['output']>;
  postingCountry?: Maybe<Scalars['String']['output']>;
  postingIndustry?: Maybe<Scalars['String']['output']>;
  postingMSA?: Maybe<Scalars['String']['output']>;
  postingMappedRole?: Maybe<Scalars['String']['output']>;
  postingMappedRoleK7?: Maybe<Scalars['String']['output']>;
  postingMappedRoleK150?: Maybe<Scalars['String']['output']>;
  postingRegion?: Maybe<Scalars['String']['output']>;
  screenerCompany?: Maybe<Scalars['String']['output']>;
  screenerMSA?: Maybe<Scalars['String']['output']>;
  screenerMappedRole?: Maybe<Scalars['String']['output']>;
  sentimentCompany?: Maybe<Scalars['String']['output']>;
  sentimentCountry?: Maybe<Scalars['String']['output']>;
  sentimentIndustry?: Maybe<Scalars['String']['output']>;
  sentimentMSA?: Maybe<Scalars['String']['output']>;
  sentimentMappedRole?: Maybe<Scalars['String']['output']>;
  sentimentMappedRoleK7?: Maybe<Scalars['String']['output']>;
  sentimentMappedRoleK150?: Maybe<Scalars['String']['output']>;
  sentimentRegion?: Maybe<Scalars['String']['output']>;
  sentimentReviewCompany?: Maybe<Scalars['String']['output']>;
  sentimentReviewCountry?: Maybe<Scalars['String']['output']>;
  sentimentReviewIndustry?: Maybe<Scalars['String']['output']>;
  sentimentReviewMappedRole?: Maybe<Scalars['String']['output']>;
  sentimentReviewMappedRoleK7?: Maybe<Scalars['String']['output']>;
  sentimentReviewMappedRoleK150?: Maybe<Scalars['String']['output']>;
  sentimentReviewMsa?: Maybe<Scalars['String']['output']>;
  sentimentReviewRegion?: Maybe<Scalars['String']['output']>;
  transitionCompany?: Maybe<Scalars['String']['output']>;
  transitionCountry?: Maybe<Scalars['String']['output']>;
  transitionIndustry?: Maybe<Scalars['String']['output']>;
  transitionMSA?: Maybe<Scalars['String']['output']>;
  transitionMappedRole?: Maybe<Scalars['String']['output']>;
  transitionMappedRoleK7?: Maybe<Scalars['String']['output']>;
  transitionMappedRoleK150?: Maybe<Scalars['String']['output']>;
  transitionRegion?: Maybe<Scalars['String']['output']>;
  usersSearch?: Maybe<Scalars['String']['output']>;
};

export type ManifestVersion = {
  __typename?: 'ManifestVersion';
  date?: Maybe<Scalars['String']['output']>;
  version?: Maybe<Scalars['String']['output']>;
};

export enum MapCoordsGranularity {
  Country = 'COUNTRY',
  Msa = 'MSA',
  Region = 'REGION',
}

export type MapCoordsItem = {
  __typename?: 'MapCoordsItem';
  id: Scalars['Int']['output'];
  lat: Scalars['Float']['output'];
  lon: Scalars['Float']['output'];
  name: Scalars['String']['output'];
};

export type MapCoordsReq = {
  country?: InputMaybe<Array<MapCoordsReqId>>;
  msa?: InputMaybe<Array<MapCoordsReqId>>;
  region?: InputMaybe<Array<MapCoordsReqId>>;
};

export type MapCoordsReqId = {
  granularity: MapCoordsGranularity;
  id: Scalars['Int']['input'];
};

export type MapCoordsResp = {
  __typename?: 'MapCoordsResp';
  country?: Maybe<Array<Array<MapCoordsItem>>>;
  msa?: Maybe<Array<Array<MapCoordsItem>>>;
  region?: Maybe<Array<Array<MapCoordsItem>>>;
};

export type MapData = {
  __typename?: 'MapData';
  count: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  lat?: Maybe<Scalars['Float']['output']>;
  location?: Maybe<Scalars['String']['output']>;
  lon?: Maybe<Scalars['Float']['output']>;
  share: Scalars['Float']['output'];
  type: Scalars['Int']['output'];
};

export type MappedRoleRefInfo = {
  __typename?: 'MappedRoleRefInfo';
  id?: Maybe<Scalars['ID']['output']>;
  longName?: Maybe<Scalars['String']['output']>;
  roleK7?: Maybe<Scalars['ID']['output']>;
  roleK150?: Maybe<Scalars['ID']['output']>;
  shortName?: Maybe<Scalars['String']['output']>;
};

export type MappedRoleRefInfoInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  longName?: InputMaybe<Scalars['String']['input']>;
  roleK7?: InputMaybe<Scalars['ID']['input']>;
  roleK150?: InputMaybe<Scalars['ID']['input']>;
  shortName?: InputMaybe<Scalars['String']['input']>;
  version?: InputMaybe<Scalars['String']['input']>;
};

export type Metadata = {
  __typename?: 'Metadata';
  id?: Maybe<Scalars['Int']['output']>;
  longName?: Maybe<Scalars['String']['output']>;
  metadata_type?: Maybe<Scalars['String']['output']>;
  shortName?: Maybe<Scalars['String']['output']>;
};

export enum MetadataKey {
  ExpirationDate = 'expiration_date',
  LimitedCompanies = 'limited_companies',
  StepsCompleted = 'steps_completed',
  TermsAccepted = 'terms_accepted',
  TourCompleted = 'tour_completed',
  TrialType = 'trial_type',
  TrialUserAgreementAccepted = 'trial_user_agreement_accepted',
}

export type ModelVersions = {
  company_mapper_version?: InputMaybe<CompanyMapperVersion>;
  cross_sectional_scaling_version?: InputMaybe<CrossSectionalScalingVersion>;
  ethnicity_version?: InputMaybe<EthnicityVersion>;
  headcount_version?: InputMaybe<HeadcountVersion>;
  job_taxonomy_version?: InputMaybe<JobTaxonomyVersion>;
  location_version?: InputMaybe<LocationVersion>;
  prestige_version?: InputMaybe<PrestigeVersion>;
  remove_bad_users?: InputMaybe<Scalars['Boolean']['input']>;
  remove_bad_users_version?: InputMaybe<BadUsersVersion>;
  salary_version?: InputMaybe<SalaryVersion>;
  seniority_version?: InputMaybe<SeniorityVersion>;
  timescaling_version?: InputMaybe<TimescalingVersion>;
  weight_table?: InputMaybe<WeightTable>;
};

export type ModelVersionsOutput = {
  __typename?: 'ModelVersionsOutput';
  company_mapper_version?: Maybe<CompanyMapperVersion>;
  cross_sectional_scaling_version?: Maybe<CrossSectionalScalingVersion>;
  ethnicity_version?: Maybe<EthnicityVersion>;
  headcount_version?: Maybe<HeadcountVersion>;
  job_taxonomy_version?: Maybe<JobTaxonomyVersion>;
  location_version?: Maybe<LocationVersion>;
  prestige_version?: Maybe<PrestigeVersion>;
  remove_bad_users?: Maybe<Scalars['Boolean']['output']>;
  remove_bad_users_version?: Maybe<BadUsersVersion>;
  salary_version?: Maybe<SalaryVersion>;
  seniority_version?: Maybe<SeniorityVersion>;
  timescaling_version?: Maybe<TimescalingVersion>;
  weight_table?: Maybe<WeightTable>;
};

export type ModelVersionsType = {
  __typename?: 'ModelVersionsType';
  company_mapper_version?: Maybe<CompanyMapperVersion>;
  cross_sectional_scaling_version?: Maybe<CrossSectionalScalingVersion>;
  ethnicity_version?: Maybe<EthnicityVersion>;
  headcount_version?: Maybe<HeadcountVersion>;
  job_taxonomy_version?: Maybe<JobTaxonomyVersion>;
  location_version?: Maybe<LocationVersion>;
  prestige_version?: Maybe<PrestigeVersion>;
  remove_bad_users?: Maybe<Scalars['Boolean']['output']>;
  remove_bad_users_version?: Maybe<BadUsersVersion>;
  salary_version?: Maybe<SalaryVersion>;
  seniority_version?: Maybe<SeniorityVersion>;
  timescaling_version?: Maybe<TimescalingVersion>;
  weight_table?: Maybe<WeightTable>;
};

export type Mutation = {
  __typename?: 'Mutation';
  createApiKey: ApiKey;
  createClientGroup?: Maybe<Client>;
  createDeliverables?: Maybe<ClientDeliverableResponse>;
  createPreset?: Maybe<Preset>;
  createReports?: Maybe<CreateReportResponse>;
  createUser: User;
  deleteApiKey: Scalars['Boolean']['output'];
  deletePipelineConfiguration?: Maybe<Scalars['Boolean']['output']>;
  deletePreset?: Maybe<Scalars['Boolean']['output']>;
  deleteRefValue?: Maybe<Scalars['Boolean']['output']>;
  deleteReport?: Maybe<Scalars['Boolean']['output']>;
  deleteResume: Scalars['Boolean']['output'];
  deleteUser?: Maybe<Scalars['Boolean']['output']>;
  insertRefValues?: Maybe<SelectionList>;
  refreshReport?: Maybe<Scalars['Boolean']['output']>;
  savePipelineClientConfiguration?: Maybe<PipelineClientConfig>;
  savePipelineConfiguration?: Maybe<PipelineConfig>;
  saveResume: Resume;
  selectionListReload?: Maybe<Scalars['Boolean']['output']>;
  setCompanyList?: Maybe<Scalars['Boolean']['output']>;
  setDefaults?: Maybe<Default>;
  setReportsValidation: ReportsValidationResponse;
  updateClientGroup?: Maybe<Client>;
  updateDeliverableConfig?: Maybe<DeliverableConfig>;
  updateIndex?: Maybe<IndexValues>;
  updateManifests?: Maybe<Scalars['Boolean']['output']>;
  updatePreset?: Maybe<Preset>;
  updateUser?: Maybe<User>;
  useManifests?: Maybe<ManifestValues>;
};

export type MutationCreateApiKeyArgs = {
  userId: Scalars['ID']['input'];
};

export type MutationCreateClientGroupArgs = {
  input?: InputMaybe<ClientRequest>;
};

export type MutationCreateDeliverablesArgs = {
  input?: InputMaybe<ClientDeliverableInput>;
};

export type MutationCreatePresetArgs = {
  input: CreatePresetRequest;
};

export type MutationCreateReportsArgs = {
  input?: InputMaybe<CreateReportRequest>;
};

export type MutationCreateUserArgs = {
  input: UserRequest;
};

export type MutationDeleteApiKeyArgs = {
  apiKeyId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type MutationDeletePipelineConfigurationArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export type MutationDeletePresetArgs = {
  id: Scalars['String']['input'];
};

export type MutationDeleteRefValueArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
  tableName: Scalars['String']['input'];
};

export type MutationDeleteReportArgs = {
  id: Scalars['String']['input'];
};

export type MutationDeleteResumeArgs = {
  id: Scalars['String']['input'];
};

export type MutationDeleteUserArgs = {
  input: Scalars['String']['input'];
};

export type MutationInsertRefValuesArgs = {
  values?: InputMaybe<RefValuesInput>;
};

export type MutationRefreshReportArgs = {
  id: Scalars['String']['input'];
};

export type MutationSavePipelineClientConfigurationArgs = {
  configuration?: InputMaybe<PipelineClientConfigInput>;
};

export type MutationSavePipelineConfigurationArgs = {
  configuration?: InputMaybe<PipelineConfigInput>;
};

export type MutationSaveResumeArgs = {
  input: ResumeRequest;
};

export type MutationSetCompanyListArgs = {
  input?: InputMaybe<SetCompanyListRequest>;
};

export type MutationSetDefaultsArgs = {
  values: Array<Scalars['Int']['input']>;
  view: View;
};

export type MutationSetReportsValidationArgs = {
  input: SetReportsValidationRequest;
};

export type MutationUpdateClientGroupArgs = {
  input?: InputMaybe<ClientRequest>;
};

export type MutationUpdateDeliverableConfigArgs = {
  input?: InputMaybe<UpdateDeliverableConfigRequest>;
};

export type MutationUpdateIndexArgs = {
  input?: InputMaybe<UpdateIndexRequest>;
};

export type MutationUpdateManifestsArgs = {
  input?: InputMaybe<UpdateManifestValues>;
};

export type MutationUpdatePresetArgs = {
  input: UpdatePresetRequest;
};

export type MutationUpdateUserArgs = {
  input: UserRequest;
};

export type MutationUseManifestsArgs = {
  version: Scalars['String']['input'];
};

export type NameRecommendation = {
  __typename?: 'NameRecommendation';
  user_name: Scalars['String']['output'];
};

export enum OperationType {
  ContainsAll = 'CONTAINS_ALL',
  ContainsAllCaseSensitive = 'CONTAINS_ALL_CASE_SENSITIVE',
  ContainsAnyOf = 'CONTAINS_ANY_OF',
  ContainsAnyOfCaseSensitive = 'CONTAINS_ANY_OF_CASE_SENSITIVE',
  ContainsAtLeastOne = 'CONTAINS_AT_LEAST_ONE',
  ContainsAtLeastOneCaseSensitive = 'CONTAINS_AT_LEAST_ONE_CASE_SENSITIVE',
  DoesNotContainAll = 'DOES_NOT_CONTAIN_ALL',
  DoesNotContainAllCaseSensitive = 'DOES_NOT_CONTAIN_ALL_CASE_SENSITIVE',
  DoesNotContainAnyOf = 'DOES_NOT_CONTAIN_ANY_OF',
  DoesNotContainAnyOfCaseSensitive = 'DOES_NOT_CONTAIN_ANY_OF_CASE_SENSITIVE',
  DoesNotContainAtLeastOne = 'DOES_NOT_CONTAIN_AT_LEAST_ONE',
  DoesNotContainAtLeastOneCaseSensitive = 'DOES_NOT_CONTAIN_AT_LEAST_ONE_CASE_SENSITIVE',
  DoesNotEqual = 'DOES_NOT_EQUAL',
  EqualTo = 'EQUAL_TO',
  GreaterThan = 'GREATER_THAN',
  GreaterThanOrEqual = 'GREATER_THAN_OR_EQUAL',
  InBetween = 'IN_BETWEEN',
  LessThan = 'LESS_THAN',
  LessThanOrEqual = 'LESS_THAN_OR_EQUAL',
}

export type Pipeline = {
  aggregations?: InputMaybe<Array<InputMaybe<PipelineAggregationInput>>>;
  columns?: InputMaybe<Array<InputMaybe<PipelineColumnType>>>;
  company_info_configuration?: InputMaybe<CompanyInfoConfiguration>;
  custom_columns?: InputMaybe<Array<InputMaybe<CustomColumnInput>>>;
  end_at_count_long?: InputMaybe<Scalars['Boolean']['input']>;
  filters?: InputMaybe<Array<InputMaybe<PipelineFilterInput>>>;
  groups?: InputMaybe<Array<InputMaybe<PipelineGroupInput>>>;
  individual_user_configuration?: InputMaybe<IndividualUserConfiguration>;
  pipeline_type: PipelineType;
  posting_configuration?: InputMaybe<PostingConfiguration>;
  sorts?: InputMaybe<Array<InputMaybe<PipelineSortInput>>>;
  source?: InputMaybe<PostingSource>;
};

export type PipelineAggregationInput = {
  column: PipelineColumnType;
  type: PipelineAggregationType;
};

export enum PipelineAggregationType {
  Average = 'average',
  Count = 'count',
  Max = 'max',
  Min = 'min',
  Sum = 'sum',
}

export type PipelineClientConfig = {
  __typename?: 'PipelineClientConfig';
  client_group?: Maybe<Scalars['String']['output']>;
  pipeline_type?: Maybe<Array<Maybe<PipelineType>>>;
  posting_source?: Maybe<Array<Maybe<PostingSource>>>;
  s3_location?: Maybe<S3DeliveryOutput>;
  snowflake_location?: Maybe<SnowflakeDeliveryOutput>;
};

export type PipelineClientConfigInput = {
  client_group?: InputMaybe<Scalars['String']['input']>;
  pipeline_type?: InputMaybe<Array<InputMaybe<PipelineType>>>;
  posting_source?: InputMaybe<Array<InputMaybe<PostingSource>>>;
  s3_location?: InputMaybe<S3Delivery>;
  snowflake_location?: InputMaybe<SnowflakeDelivery>;
};

export enum PipelineColumnType {
  ActivePosting = 'active_posting',
  ActiveSalaryAvg = 'active_salary_avg',
  ActiveSalaryCount = 'active_salary_count',
  ApiProb = 'api_prob',
  Attrition = 'attrition',
  BlackProb = 'black_prob',
  Campus = 'campus',
  CareerAdvancementSentiment = 'career_advancement_sentiment',
  Cik = 'cik',
  City = 'city',
  Company = 'company',
  CompanyAndDivisionSizeSentiment = 'company_and_division_size_sentiment',
  CompanyCleaned = 'company_cleaned',
  CompanyId = 'company_id',
  CompanyName = 'company_name',
  CompanyPriname = 'company_priname',
  Companyurl = 'companyurl',
  CompensationSentiment = 'compensation_sentiment',
  Count = 'count',
  Country = 'country',
  CoworkersSentiment = 'coworkers_sentiment',
  CultureSentiment = 'culture_sentiment',
  Cusip = 'cusip',
  Degree = 'degree',
  DegreeRaw = 'degree_raw',
  Description = 'description',
  DiversityAndInclusionSentiment = 'diversity_and_inclusion_sentiment',
  Duration = 'duration',
  EducationEnddate = 'education_enddate',
  EducationRn = 'education_rn',
  EducationStartdate = 'education_startdate',
  EndMeanSampledSalary = 'end_mean_sampled_salary',
  Enddate = 'enddate',
  Ethnicity = 'ethnicity',
  EthnicityPredicted = 'ethnicity_predicted',
  Exchange = 'exchange',
  ExpectedHires = 'expected_hires',
  ExternalInflow = 'external_inflow',
  ExternalOutflow = 'external_outflow',
  FProb = 'f_prob',
  FactsetEntityId = 'factset_entity_id',
  Field = 'field',
  FieldRaw = 'field_raw',
  FillingTimeAvg = 'filling_time_avg',
  FinalParentFactsetId = 'final_parent_factset_id',
  FinalParentFactsetName = 'final_parent_factset_name',
  Firstname = 'firstname',
  Fullname = 'fullname',
  Gender = 'gender',
  Gvkey = 'gvkey',
  HighestDegree = 'highest_degree',
  Hiring = 'hiring',
  HispanicProb = 'hispanic_prob',
  Inflation = 'inflation',
  Inflow = 'inflow',
  InnovativeTechnologySentiment = 'innovative_technology_sentiment',
  Isin = 'isin',
  JobCategory = 'job_category',
  JobId = 'job_id',
  JobTitleRaw = 'job_title_raw',
  Jobtitle = 'jobtitle',
  JobtitleRaw = 'jobtitle_raw',
  JobtitleTranslated = 'jobtitle_translated',
  Lastname = 'lastname',
  LayoffDate = 'layoff_date',
  Lei = 'lei',
  LinkedinUrl = 'linkedin_url',
  Location = 'location',
  LocationRaw = 'location_raw',
  MProb = 'm_prob',
  ManagementSentiment = 'management_sentiment',
  MappedRole = 'mapped_role',
  MentorshipSentiment = 'mentorship_sentiment',
  MetroArea = 'metro_area',
  Msa = 'msa',
  MultipleProb = 'multiple_prob',
  Naics = 'naics',
  Naics2d = 'naics2d',
  Naics3d = 'naics3d',
  NaicsDesc = 'naics_desc',
  NativeProb = 'native_prob',
  NewPosting = 'new_posting',
  NewSalaryAvg = 'new_salary_avg',
  NewSalaryCount = 'new_salary_count',
  NumEmployees = 'num_employees',
  NumReviews = 'num_reviews',
  Numconnections = 'numconnections',
  OnboardingSentiment = 'onboarding_sentiment',
  OnetCode = 'onet_code',
  OnetTitle = 'onet_title',
  Outflow = 'outflow',
  PerksAndBenefitsSentiment = 'perks_and_benefits_sentiment',
  PersonalEmail = 'personal_email',
  PhoneNumber = 'phone_number',
  PositionId = 'position_id',
  PostDate = 'post_date',
  Prestige = 'prestige',
  PrestigeWeight = 'prestige_weight',
  ProfileLinkedinUrl = 'profile_linkedin_url',
  ProfileSummary = 'profile_summary',
  ProfileTitle = 'profile_title',
  RatingBusinessOutlook = 'rating_business_outlook',
  RatingCareerOpportunities = 'rating_career_opportunities',
  RatingCeo = 'rating_ceo',
  RatingCompensationAndBenefits = 'rating_compensation_and_benefits',
  RatingCultureAndValues = 'rating_culture_and_values',
  RatingDiversityAndInclusion = 'rating_diversity_and_inclusion',
  RatingOverall = 'rating_overall',
  RatingRecommendToFriend = 'rating_recommend_to_friend',
  RatingSeniorLeadership = 'rating_senior_leadership',
  RatingWorkLifeBalance = 'rating_work_life_balance',
  RawCount = 'raw_count',
  RawInflow = 'raw_inflow',
  RawOutflow = 'raw_outflow',
  Rcid = 'rcid',
  Recommenders = 'recommenders',
  Region = 'region',
  RemoteSuitability = 'remote_suitability',
  RemoteWorkSentiment = 'remote_work_sentiment',
  RemoveDate = 'remove_date',
  RemovedPosting = 'removed_posting',
  RemovedSalaryAvg = 'removed_salary_avg',
  RemovedSalaryCount = 'removed_salary_count',
  ReviewAdvice = 'review_advice',
  ReviewCons = 'review_cons',
  ReviewCountHelpful = 'review_count_helpful',
  ReviewCountNotHelpful = 'review_count_not_helpful',
  ReviewDateTime = 'review_date_time',
  ReviewId = 'review_id',
  ReviewIscovid19 = 'review_iscovid19',
  ReviewLanguageId = 'review_language_id',
  ReviewPros = 'review_pros',
  ReviewSummary = 'review_summary',
  ReviewerCurrentJob = 'reviewer_current_job',
  ReviewerEmploymentStatus = 'reviewer_employment_status',
  ReviewerJobEndingYear = 'reviewer_job_ending_year',
  ReviewerLengthOfEmployment = 'reviewer_length_of_employment',
  RicsK50 = 'rics_k50',
  RicsK100 = 'rics_k100',
  RicsK150 = 'rics_k150',
  RicsK200 = 'rics_k200',
  RicsK400 = 'rics_k400',
  Rn = 'rn',
  RoleK7 = 'role_k7',
  RoleK50 = 'role_k50',
  RoleK150 = 'role_k150',
  RoleK300 = 'role_k300',
  RoleK500 = 'role_k500',
  RoleK1000 = 'role_k1000',
  RoleK1250 = 'role_k1250',
  Salary = 'salary',
  ScaledCount = 'scaled_count',
  ScaledInflow = 'scaled_inflow',
  ScaledOutflow = 'scaled_outflow',
  Sedol = 'sedol',
  Seniority = 'seniority',
  SexPredicted = 'sex_predicted',
  Sic = 'sic',
  SkillK25 = 'skill_k25',
  SkillK50 = 'skill_k50',
  SkillK75 = 'skill_k75',
  SkillMapped = 'skill_mapped',
  SkillRaw = 'skill_raw',
  Soc6d = 'soc6d',
  StartMeanSampledSalary = 'start_mean_sampled_salary',
  Startdate = 'startdate',
  State = 'state',
  Ticker = 'ticker',
  Title = 'title',
  TitleRaw = 'title_raw',
  TotalPrestige = 'total_prestige',
  Transition = 'transition',
  UltParPrimaryName = 'ult_par_primary_name',
  UltParRcid = 'ult_par_rcid',
  UltimateParentCompanyName = 'ultimate_parent_company_name',
  UltimateParentRcid = 'ultimate_parent_rcid',
  UniversityCountry = 'university_country',
  UniversityLocation = 'university_location',
  UniversityName = 'university_name',
  Universityurl = 'universityurl',
  UsLogSalary = 'us_log_salary',
  UsRank = 'us_rank',
  UserCountry = 'user_country',
  UserId = 'user_id',
  UserLocation = 'user_location',
  UserPrestige = 'user_prestige',
  Website = 'website',
  Weight = 'weight',
  WhiteProb = 'white_prob',
  WorkEmail = 'work_email',
  WorkLifeBalanceSentiment = 'work_life_balance_sentiment',
  WorldRank = 'world_rank',
  YearFounded = 'year_founded',
}

export type PipelineConfig = {
  __typename?: 'PipelineConfig';
  client_id?: Maybe<Scalars['String']['output']>;
  dashboard_global?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  model_versions?: Maybe<ModelVersionsType>;
  name?: Maybe<Scalars['String']['output']>;
  pipeline?: Maybe<PipelineOutput>;
  user_id?: Maybe<Scalars['String']['output']>;
};

export type PipelineConfigInput = {
  client_id?: InputMaybe<Scalars['String']['input']>;
  dashboard_global?: InputMaybe<Scalars['Boolean']['input']>;
  model_versions?: InputMaybe<ModelVersions>;
  name?: InputMaybe<Scalars['String']['input']>;
  pipeline?: InputMaybe<Pipeline>;
  user_id?: InputMaybe<Scalars['String']['input']>;
};

export type PipelineFilter = {
  __typename?: 'PipelineFilter';
  binary?: Maybe<BinaryOperation>;
  conditions?: Maybe<Array<CustomColumnCondition>>;
  name: Scalars['String']['output'];
};

export type PipelineFilterInput = {
  binary?: InputMaybe<BinaryOperation>;
  conditions?: InputMaybe<Array<InputMaybe<CustomColumnConditionInput>>>;
  name: Scalars['String']['input'];
};

export type PipelineGroupInput = {
  column: PipelineColumnType;
};

export enum PipelineOrderType {
  Asc = 'asc',
  Desc = 'desc',
}

export type PipelineOutput = {
  __typename?: 'PipelineOutput';
  columns?: Maybe<Array<Maybe<PipelineColumnType>>>;
  company_info_configuration?: Maybe<CompanyInfoConfigurationOutput>;
  custom_columns?: Maybe<Array<Maybe<CustomColumn>>>;
  end_at_count_long?: Maybe<Scalars['Boolean']['output']>;
  filters?: Maybe<Array<Maybe<PipelineFilter>>>;
  individual_user_configuration?: Maybe<IndividualUserConfigurationOutput>;
  pipeline_type: PipelineType;
  posting_configuration?: Maybe<PostingConfigurationOutput>;
  source?: Maybe<PostingSource>;
};

export type PipelineSortInput = {
  column: Scalars['String']['input'];
  order: PipelineOrderType;
};

export enum PipelineType {
  CompanyInfo = 'company_info',
  Individual = 'individual',
  IndividualReviews = 'individual_reviews',
  Layoffs = 'layoffs',
  Posting = 'posting',
  PostingsIndividual = 'postings_individual',
  ReviewTrends = 'review_trends',
  SentimentScores = 'sentiment_scores',
  SkillDynam = 'skill_dynam',
  Transition = 'transition',
  WfDynam = 'wf_dynam',
}

export type PositionsCountsReq = {
  dim: Dimension;
  ent_id: Scalars['Int']['input'];
  filters: PositionsCountsReqFilters;
  params: PositionsCountsReqParams;
};

export type PositionsCountsReqFilters = {
  company?: InputMaybe<Array<Scalars['Int']['input']>>;
  country?: InputMaybe<Array<Scalars['Int']['input']>>;
  custom_role?: InputMaybe<CustomRoleFilter>;
  end_date?: InputMaybe<Scalars['Int']['input']>;
  ethnicity?: InputMaybe<Array<Scalars['Int']['input']>>;
  gender?: InputMaybe<Array<Scalars['Int']['input']>>;
  highest_degree?: InputMaybe<Array<Scalars['Int']['input']>>;
  industry?: InputMaybe<Array<Scalars['Int']['input']>>;
  msa?: InputMaybe<Array<Scalars['Int']['input']>>;
  region?: InputMaybe<Array<Scalars['Int']['input']>>;
  roleK7?: InputMaybe<Array<Scalars['Int']['input']>>;
  roleK150?: InputMaybe<Array<Scalars['Int']['input']>>;
  roleK1500?: InputMaybe<Array<Scalars['Int']['input']>>;
  seniority?: InputMaybe<Array<Scalars['Int']['input']>>;
  start_date?: InputMaybe<Scalars['Int']['input']>;
  state?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type PositionsCountsReqParams = {
  companies_num: Scalars['Int']['input'];
  geo_num: Scalars['Int']['input'];
  role_num: Scalars['Int']['input'];
};

export type PositionsCountsResp = {
  __typename?: 'PositionsCountsResp';
  companies_tot: Scalars['Float']['output'];
  geo_tot: Scalars['Float']['output'];
  role_tot: Scalars['Float']['output'];
  top_companies?: Maybe<Array<PositionsCountsRespEntity>>;
  top_geos?: Maybe<Array<PositionsCountsRespEntity>>;
  top_roles?: Maybe<Array<PositionsCountsRespEntity>>;
};

export type PositionsCountsRespEntity = {
  __typename?: 'PositionsCountsRespEntity';
  count: Scalars['Float']['output'];
  id: Scalars['Int']['output'];
  long_name: Scalars['String']['output'];
  share: Scalars['Float']['output'];
  short_name: Scalars['String']['output'];
};

export type Posting1dResponse = {
  __typename?: 'Posting1dResponse';
  metadata?: Maybe<Metadata>;
  metrics?: Maybe<PostingMetric>;
};

export type Posting2dResponse = {
  __typename?: 'Posting2dResponse';
  category?: Maybe<Array<Maybe<Posting1dResponse>>>;
  metadata?: Maybe<Metadata>;
};

export type PostingConfiguration = {
  frequency?: InputMaybe<PostingFrequency>;
  source?: InputMaybe<PostingSource>;
};

export type PostingConfigurationOutput = {
  __typename?: 'PostingConfigurationOutput';
  frequency?: Maybe<PostingFrequency>;
  source?: Maybe<PostingSource>;
};

export enum PostingDimension {
  Company = 'company',
  Date = 'date',
  Geography = 'geography',
  Role = 'role',
  Top = 'top',
  TopRole = 'top_role',
}

export type PostingFilters = {
  company?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  custom_role?: InputMaybe<CustomRoleFilter>;
  end_date?: InputMaybe<Scalars['String']['input']>;
  industry?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  job_category?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  metric_mode?: InputMaybe<Scalars['String']['input']>;
  msa?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  provider?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  rcid?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  region?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  role_k50?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  role_k150?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  role_k1500?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  seniority?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  skill_k75?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  skill_k700?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  skill_k3000?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  start_date?: InputMaybe<Scalars['String']['input']>;
};

export enum PostingFrequency {
  Day = 'day',
  Month = 'month',
  Week = 'week',
}

export type PostingMetric = {
  __typename?: 'PostingMetric';
  active?: Maybe<Scalars['Int']['output']>;
  count?: Maybe<Scalars['Int']['output']>;
  expected_hires?: Maybe<Scalars['Float']['output']>;
  new?: Maybe<Scalars['Int']['output']>;
  removed?: Maybe<Scalars['Int']['output']>;
  salary?: Maybe<Scalars['Int']['output']>;
  share?: Maybe<Scalars['Float']['output']>;
  time_to_fill?: Maybe<Scalars['Float']['output']>;
};

export enum PostingSource {
  Ghr = 'ghr',
  Indeed = 'indeed',
  Linkup = 'linkup',
  Mixrank = 'mixrank',
  Revelio = 'revelio',
  Unified = 'unified',
}

export type Preset = {
  __typename?: 'Preset';
  default?: Maybe<Scalars['Boolean']['output']>;
  filters?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  shared?: Maybe<Scalars['Boolean']['output']>;
  view?: Maybe<View>;
};

export enum PrestigeVersion {
  V1 = 'V1',
  V2 = 'V2',
}

export type Query = {
  __typename?: 'Query';
  cityInMsaInfo?: Maybe<Array<Maybe<GeoRefInfo>>>;
  clients?: Maybe<Array<Maybe<Client>>>;
  clientsDeliConfig?: Maybe<DeliverableConfig>;
  clientsDeliverable?: Maybe<Array<ClientDeliverableOutput>>;
  company_lists?: Maybe<Array<Maybe<CompanyList>>>;
  compensation?: Maybe<Array<Maybe<CompensationResponse>>>;
  composition?: Maybe<Array<Maybe<CompositionResponse>>>;
  decryptString: Scalars['String']['output'];
  defaults?: Maybe<Default>;
  entity?: Maybe<EntityResponse>;
  getResume: Resume;
  index?: Maybe<IndexValues>;
  jobEmbedding: JobEmbeddingResp;
  legacy_users?: Maybe<Array<Maybe<User>>>;
  manifest?: Maybe<ManifestValues>;
  manifest_usage?: Maybe<ManifestUsage>;
  manifest_versions?: Maybe<Array<Maybe<ManifestVersion>>>;
  mapCoords: MapCoordsResp;
  pipelineConfiguration?: Maybe<Array<Maybe<PipelineConfig>>>;
  positions_counts?: Maybe<PositionsCountsResp>;
  posting?: Maybe<Array<Maybe<Posting2dResponse>>>;
  postingV2?: Maybe<Array<Maybe<Posting2dResponse>>>;
  presets?: Maybe<Array<Maybe<Preset>>>;
  reports_validation: Array<Maybe<ReportsValidationResponse>>;
  schoolInfo?: Maybe<Array<Maybe<SchoolRefInfo>>>;
  screener: ScreenerResp;
  selectionList?: Maybe<SelectionList>;
  sentiment2d?: Maybe<Array<Maybe<Sentiment2dResponse>>>;
  sentimentReviews?: Maybe<Array<Maybe<SentimentReviews2dResponse>>>;
  talent_discovery_name_recommendations_v1_5?: Maybe<
    Array<Maybe<NameRecommendation>>
  >;
  talent_discovery_name_recommendations_v2: TalentDiscoveryV2NameRecommendationResp;
  talent_discovery_search_v1_5: TalentDiscoveryResponse;
  talent_discovery_search_v2: TalentDiscoveryV2SearchResp;
  transitions2D?: Maybe<Array<Maybe<Transition2DResponse>>>;
  userApiKeys: Array<Maybe<Scalars['ID']['output']>>;
  userSearch?: Maybe<Array<Maybe<User>>>;
  users?: Maybe<Array<Maybe<User>>>;
};

export type QueryCityInMsaInfoArgs = {
  in: MsaRefInfoInput;
};

export type QueryClientsArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QueryClientsDeliverableArgs = {
  client_name?: InputMaybe<Scalars['String']['input']>;
};

export type QueryCompany_ListsArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QueryCompensationArgs = {
  dim1?: InputMaybe<Dimension1>;
  dim2?: InputMaybe<CompensationDimension>;
  filters?: InputMaybe<CompensationFilters>;
};

export type QueryCompositionArgs = {
  dim1?: InputMaybe<Dimension1>;
  dim2?: InputMaybe<CompositionDimension2>;
  filters?: InputMaybe<Filters>;
};

export type QueryDecryptStringArgs = {
  input: Scalars['String']['input'];
};

export type QueryDefaultsArgs = {
  view: View;
};

export type QueryEntityArgs = {
  dim1?: InputMaybe<Dimension1>;
  filters?: InputMaybe<EntityFilters>;
};

export type QueryGetResumeArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export type QueryJobEmbeddingArgs = {
  input: JobEmbeddingReq;
};

export type QueryLegacy_UsersArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QueryMapCoordsArgs = {
  req: MapCoordsReq;
};

export type QueryPipelineConfigurationArgs = {
  configuration?: InputMaybe<PipelineConfigInput>;
};

export type QueryPositions_CountsArgs = {
  req?: InputMaybe<PositionsCountsReq>;
};

export type QueryPostingArgs = {
  dim1?: InputMaybe<Dimension1>;
  dim2?: InputMaybe<PostingDimension>;
  filters?: InputMaybe<PostingFilters>;
};

export type QueryPostingV2Args = {
  dim1?: InputMaybe<Dimension1>;
  dim2?: InputMaybe<PostingDimension>;
  filters?: InputMaybe<PostingFilters>;
};

export type QueryPresetsArgs = {
  is_default?: InputMaybe<Scalars['Boolean']['input']>;
  view?: InputMaybe<View>;
};

export type QuerySchoolInfoArgs = {
  in: SchoolRefInfoInput;
};

export type QueryScreenerArgs = {
  filters: ScreenerReq;
};

export type QuerySentiment2dArgs = {
  dim1?: InputMaybe<Dimension1>;
  dim2?: InputMaybe<SentimentDimension>;
  filters?: InputMaybe<SentimentFilters>;
};

export type QuerySentimentReviewsArgs = {
  dim1?: InputMaybe<Dimension1>;
  dim2?: InputMaybe<SentimentReviewDimension>;
  filters?: InputMaybe<SentimentFilters>;
};

export type QueryTalent_Discovery_Name_Recommendations_V1_5Args = {
  query?: InputMaybe<TalentDiscoveryV1_5Filter>;
};

export type QueryTalent_Discovery_Name_Recommendations_V2Args = {
  query?: InputMaybe<TalentDiscoveryV2SearchReq>;
};

export type QueryTalent_Discovery_Search_V1_5Args = {
  query?: InputMaybe<TalentDiscoveryV1_5Filter>;
};

export type QueryTalent_Discovery_Search_V2Args = {
  query?: InputMaybe<TalentDiscoveryV2SearchReq>;
};

export type QueryTransitions2DArgs = {
  dim1?: InputMaybe<Dimension1>;
  dim2?: InputMaybe<TransitionDimension>;
  filters?: InputMaybe<TransitionFilters>;
};

export type QueryUserApiKeysArgs = {
  userId: Scalars['ID']['input'];
};

export type QueryUserSearchArgs = {
  input: Scalars['String']['input'];
};

export type QueryUsersArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Recommendation = {
  __typename?: 'Recommendation';
  original: Scalars['String']['output'];
  suggestions: Array<Scalars['String']['output']>;
  tokenized: Scalars['String']['output'];
};

export type RecommendationRequest = {
  prefix: Array<Scalars['String']['input']>;
};

export type RecommendationResponse = {
  __typename?: 'RecommendationResponse';
  recommendations: Array<Recommendation>;
};

export type RefInfo = {
  __typename?: 'RefInfo';
  dataUnavailableIn?: Maybe<ManifestKind>;
  id?: Maybe<Scalars['ID']['output']>;
  longName?: Maybe<Scalars['String']['output']>;
  shortName?: Maybe<Scalars['String']['output']>;
};

export type RefInfoInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  longName?: InputMaybe<Scalars['String']['input']>;
  shortName?: InputMaybe<Scalars['String']['input']>;
  version?: InputMaybe<Scalars['String']['input']>;
};

export type RefMetadataInput = {
  RefName?: InputMaybe<Scalars['String']['input']>;
  Rows?: InputMaybe<Scalars['Int']['input']>;
  UpdateTime?: InputMaybe<Scalars['String']['input']>;
  Version?: InputMaybe<Scalars['String']['input']>;
};

export type RefValuesInput = {
  company?: InputMaybe<Array<InputMaybe<CompanyRefInfoInput>>>;
  country?: InputMaybe<Array<InputMaybe<GeoChildRefInfoInput>>>;
  ethnicity?: InputMaybe<Array<InputMaybe<RefInfoInput>>>;
  gender?: InputMaybe<Array<InputMaybe<RefInfoInput>>>;
  geo_complete?: InputMaybe<Array<InputMaybe<GeoCompleteInput>>>;
  highest_degree?: InputMaybe<Array<InputMaybe<RefInfoInput>>>;
  industry?: InputMaybe<Array<InputMaybe<RefInfoInput>>>;
  job_category?: InputMaybe<Array<InputMaybe<JobCategoryRefInfoInput>>>;
  month?: InputMaybe<Array<InputMaybe<RefInfoInput>>>;
  msa?: InputMaybe<Array<InputMaybe<GeoChildRefInfoInput>>>;
  region?: InputMaybe<Array<InputMaybe<RegionRefInfoInput>>>;
  role_complete?: InputMaybe<Array<InputMaybe<RoleCompleteInput>>>;
  role_k50?: InputMaybe<Array<InputMaybe<RoleRefInfoInput>>>;
  role_k150?: InputMaybe<Array<InputMaybe<RoleRefInfoInput>>>;
  role_k300?: InputMaybe<Array<InputMaybe<RoleRefInfoInput>>>;
  role_k500?: InputMaybe<Array<InputMaybe<RoleRefInfoInput>>>;
  role_k1000?: InputMaybe<Array<InputMaybe<RoleRefInfoInput>>>;
  role_k1250?: InputMaybe<Array<InputMaybe<RoleRefInfoInput>>>;
  role_k1500?: InputMaybe<Array<InputMaybe<MappedRoleRefInfoInput>>>;
  seniority?: InputMaybe<Array<InputMaybe<RefInfoInput>>>;
  skill_k25?: InputMaybe<Array<InputMaybe<SkillRefInfoInput>>>;
  skill_k50?: InputMaybe<Array<InputMaybe<SkillRefInfoInput>>>;
  skill_k75?: InputMaybe<Array<InputMaybe<SkillRefInfoInput>>>;
  skill_k700?: InputMaybe<Array<InputMaybe<SkillRefInfoInput>>>;
  skill_k2500?: InputMaybe<Array<InputMaybe<SkillRefInfoInput>>>;
  skill_mapped?: InputMaybe<Array<InputMaybe<SkillRefInfoInput>>>;
  state?: InputMaybe<Array<InputMaybe<StateRefInfoInput>>>;
  week?: InputMaybe<Array<InputMaybe<WeekRefInfoInput>>>;
};

export type RegionRefInfo = {
  __typename?: 'RegionRefInfo';
  id?: Maybe<Scalars['ID']['output']>;
  longName?: Maybe<Scalars['String']['output']>;
  shortName?: Maybe<Scalars['String']['output']>;
};

export type RegionRefInfoInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  longName?: InputMaybe<Scalars['String']['input']>;
  shortName?: InputMaybe<Scalars['String']['input']>;
  version?: InputMaybe<Scalars['String']['input']>;
};

export type Report = {
  __typename?: 'Report';
  client?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['String']['output']>;
  job_id: Scalars['Int']['output'];
  job_name?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  report: ReportResponse;
  status: DeliveryStatus;
  updated_at?: Maybe<Scalars['String']['output']>;
  uuid?: Maybe<Scalars['String']['output']>;
};

export enum ReportFilterId {
  Geography = 'geography',
  Role = 'role',
  Skill = 'skill',
}

export type ReportFilterInput = {
  id: Scalars['String']['input'];
  type: ReportFilterType;
  values: Array<Scalars['String']['input']>;
};

export type ReportFilterOutput = {
  __typename?: 'ReportFilterOutput';
  id: Scalars['String']['output'];
  type: ReportFilterType;
  values: Array<Scalars['String']['output']>;
};

export enum ReportFilterType {
  Country = 'country',
  Industry = 'industry',
  JobCategory = 'job_category',
  RoleK50 = 'role_k50',
  RoleK150 = 'role_k150',
  RoleK300 = 'role_k300',
  RoleK500 = 'role_k500',
  RoleK700 = 'role_k700',
  RoleK1000 = 'role_k1000',
  RoleK1250 = 'role_k1250',
  RoleK1500 = 'role_k1500',
  Seniority = 'seniority',
  SkillK25 = 'skill_k25',
}

export type ReportRequest = {
  comparisonEntities?: InputMaybe<Array<EntityInput>>;
  filters?: InputMaybe<Array<ReportFilterInput>>;
  mainEntities: Array<EntityInput>;
  reportType: ReportTypeEnum;
};

export type ReportResponse = {
  __typename?: 'ReportResponse';
  comparisonEntities?: Maybe<Array<EntityOutput>>;
  filters?: Maybe<Array<ReportFilterOutput>>;
  mainEntities: Array<EntityOutput>;
  reportType: ReportTypeEnum;
};

export enum ReportTypeEnum {
  AiAdoptionExposure = 'AiAdoptionExposure',
  Compensation = 'Compensation',
  CompensationBenchmarking = 'CompensationBenchmarking',
  GenderDiversity = 'GenderDiversity',
  JobComparison = 'JobComparison',
  MarketDiscovery = 'MarketDiscovery',
  MarketIntelligence = 'MarketIntelligence',
  MarketTightness = 'MarketTightness',
  Prestige = 'Prestige',
}

export type ReportValidation = {
  __typename?: 'ReportValidation';
  comparison_entities?: Maybe<Array<Maybe<ReportValidationEntity>>>;
  description: Scalars['String']['output'];
  filters?: Maybe<Array<Maybe<ReportValidationEntity>>>;
  id: Scalars['Int']['output'];
  label: Scalars['String']['output'];
  main_entity: Array<Maybe<ReportValidationEntity>>;
  report_type: ReportTypeEnum;
  sample_link?: Maybe<Scalars['String']['output']>;
};

export type ReportValidationEntity = {
  __typename?: 'ReportValidationEntity';
  description: Scalars['String']['output'];
  label: Scalars['String']['output'];
  limit: Scalars['Int']['output'];
  required: Scalars['Boolean']['output'];
  selection_list: Array<Scalars['String']['output']>;
};

export type ReportValidationEntityInput = {
  description: Scalars['String']['input'];
  label: Scalars['String']['input'];
  limit: Scalars['Int']['input'];
  required: Scalars['Boolean']['input'];
  selection_list: Array<Scalars['String']['input']>;
};

export type ReportValidationInput = {
  comparison_entities?: InputMaybe<
    Array<InputMaybe<ReportValidationEntityInput>>
  >;
  description: Scalars['String']['input'];
  filters?: InputMaybe<Array<InputMaybe<ReportValidationEntityInput>>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  label: Scalars['String']['input'];
  main_entity: Array<InputMaybe<ReportValidationEntityInput>>;
  report_type: ReportTypeEnum;
  sample_link?: InputMaybe<Scalars['String']['input']>;
};

export type ReportsValidationRequest = {
  report_type: ReportTypeEnum;
};

export type ReportsValidationResponse = {
  __typename?: 'ReportsValidationResponse';
  report_validation?: Maybe<ReportValidation>;
};

export type Resume = {
  __typename?: 'Resume';
  download_link?: Maybe<Scalars['String']['output']>;
  enriched_data?: Maybe<Scalars['String']['output']>;
  excel_location?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  resume_id: Scalars['String']['output'];
  s3_location?: Maybe<Scalars['String']['output']>;
};

export type ResumeRequest = {
  download_link?: InputMaybe<Scalars['String']['input']>;
  enriched_data?: InputMaybe<Scalars['String']['input']>;
  excel_location?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  resume_id: Scalars['String']['input'];
  s3_location?: InputMaybe<Scalars['String']['input']>;
};

export enum Role {
  ClientAdmin = 'CLIENT_ADMIN',
  ClientUser = 'CLIENT_USER',
  RevelioAdmin = 'REVELIO_ADMIN',
  SuperAdmin = 'SUPER_ADMIN',
}

export type RoleCompleteInput = {
  mappedRole?: InputMaybe<Scalars['String']['input']>;
  mappedRoleIndex?: InputMaybe<Scalars['ID']['input']>;
  roleK7?: InputMaybe<Scalars['String']['input']>;
  roleK7Index?: InputMaybe<Scalars['ID']['input']>;
  roleK45?: InputMaybe<Scalars['String']['input']>;
  roleK45Index?: InputMaybe<Scalars['ID']['input']>;
  roleK50?: InputMaybe<Scalars['String']['input']>;
  roleK50Index?: InputMaybe<Scalars['ID']['input']>;
  roleK55?: InputMaybe<Scalars['String']['input']>;
  roleK55Index?: InputMaybe<Scalars['ID']['input']>;
  roleK60?: InputMaybe<Scalars['String']['input']>;
  roleK60Index?: InputMaybe<Scalars['ID']['input']>;
  roleK65?: InputMaybe<Scalars['String']['input']>;
  roleK65Index?: InputMaybe<Scalars['ID']['input']>;
  roleK70?: InputMaybe<Scalars['String']['input']>;
  roleK70Index?: InputMaybe<Scalars['ID']['input']>;
  roleK75?: InputMaybe<Scalars['String']['input']>;
  roleK75Index?: InputMaybe<Scalars['ID']['input']>;
  roleK80?: InputMaybe<Scalars['String']['input']>;
  roleK80Index?: InputMaybe<Scalars['ID']['input']>;
  roleK85?: InputMaybe<Scalars['String']['input']>;
  roleK85Index?: InputMaybe<Scalars['ID']['input']>;
  roleK90?: InputMaybe<Scalars['String']['input']>;
  roleK90Index?: InputMaybe<Scalars['ID']['input']>;
  roleK95?: InputMaybe<Scalars['String']['input']>;
  roleK95Index?: InputMaybe<Scalars['ID']['input']>;
  roleK100?: InputMaybe<Scalars['String']['input']>;
  roleK100Index?: InputMaybe<Scalars['ID']['input']>;
  roleK150?: InputMaybe<Scalars['String']['input']>;
  roleK150Index?: InputMaybe<Scalars['ID']['input']>;
  roleK200?: InputMaybe<Scalars['String']['input']>;
  roleK200Index?: InputMaybe<Scalars['ID']['input']>;
  roleK250?: InputMaybe<Scalars['String']['input']>;
  roleK250Index?: InputMaybe<Scalars['ID']['input']>;
  roleK300?: InputMaybe<Scalars['String']['input']>;
  roleK300Index?: InputMaybe<Scalars['ID']['input']>;
  roleK350?: InputMaybe<Scalars['String']['input']>;
  roleK350Index?: InputMaybe<Scalars['ID']['input']>;
  roleK400?: InputMaybe<Scalars['String']['input']>;
  roleK400Index?: InputMaybe<Scalars['ID']['input']>;
  roleK450?: InputMaybe<Scalars['String']['input']>;
  roleK450Index?: InputMaybe<Scalars['ID']['input']>;
  roleK500?: InputMaybe<Scalars['String']['input']>;
  roleK500Index?: InputMaybe<Scalars['ID']['input']>;
  roleK600?: InputMaybe<Scalars['String']['input']>;
  roleK600Index?: InputMaybe<Scalars['ID']['input']>;
  roleK700?: InputMaybe<Scalars['String']['input']>;
  roleK700Index?: InputMaybe<Scalars['ID']['input']>;
  roleK800?: InputMaybe<Scalars['String']['input']>;
  roleK800Index?: InputMaybe<Scalars['ID']['input']>;
  roleK900?: InputMaybe<Scalars['String']['input']>;
  roleK900Index?: InputMaybe<Scalars['ID']['input']>;
  roleK1000?: InputMaybe<Scalars['String']['input']>;
  roleK1000Index?: InputMaybe<Scalars['ID']['input']>;
  roleK1250?: InputMaybe<Scalars['String']['input']>;
  roleK1250Index?: InputMaybe<Scalars['ID']['input']>;
  version?: InputMaybe<Scalars['String']['input']>;
};

export type RoleRefInfo = {
  __typename?: 'RoleRefInfo';
  dataUnavailableIn?: Maybe<ManifestKind>;
  id?: Maybe<Scalars['ID']['output']>;
  longName?: Maybe<Scalars['String']['output']>;
  roleK7?: Maybe<Scalars['ID']['output']>;
  roleK50?: Maybe<Scalars['ID']['output']>;
  roleK150?: Maybe<Scalars['ID']['output']>;
  roleK300?: Maybe<Scalars['ID']['output']>;
  roleK500?: Maybe<Scalars['ID']['output']>;
  roleK1000?: Maybe<Scalars['ID']['output']>;
  roleK1250?: Maybe<Scalars['ID']['output']>;
  shortName?: Maybe<Scalars['String']['output']>;
  topCleanedTitles?: Maybe<Scalars['String']['output']>;
  topRawTitles?: Maybe<Scalars['String']['output']>;
};

export type RoleRefInfoInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  longName?: InputMaybe<Scalars['String']['input']>;
  roleK7?: InputMaybe<Scalars['ID']['input']>;
  roleK150?: InputMaybe<Scalars['ID']['input']>;
  roleK300?: InputMaybe<Scalars['ID']['input']>;
  roleK500?: InputMaybe<Scalars['ID']['input']>;
  roleK1000?: InputMaybe<Scalars['ID']['input']>;
  roleK1250?: InputMaybe<Scalars['ID']['input']>;
  shortName?: InputMaybe<Scalars['String']['input']>;
  topCleanedTitles?: InputMaybe<Scalars['String']['input']>;
  topRawTitles?: InputMaybe<Scalars['String']['input']>;
  version?: InputMaybe<Scalars['String']['input']>;
};

export type S3Delivery = {
  s3_bucket: Scalars['String']['input'];
  s3_file_prefix: Scalars['String']['input'];
  s3_format: S3Format;
};

export type S3DeliveryOutput = {
  __typename?: 'S3DeliveryOutput';
  s3_bucket: Scalars['String']['output'];
  s3_file_prefix: Scalars['String']['output'];
  s3_format: S3Format;
};

export enum S3Format {
  Csv = 'csv',
  Parquet = 'parquet',
  Zip = 'zip',
}

export enum SalaryVersion {
  V1 = 'V1',
  V2 = 'V2',
}

export type SchoolRefInfo = {
  __typename?: 'SchoolRefInfo';
  dataUnavailableIn?: Maybe<ManifestKind>;
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  rsid?: Maybe<Scalars['ID']['output']>;
};

export type SchoolRefInfoInput = {
  name: Scalars['String']['input'];
  page: Scalars['Int']['input'];
};

export enum ScreenerDimension {
  Company = 'COMPANY',
  Geography = 'GEOGRAPHY',
  Role = 'ROLE',
}

export type ScreenerReq = {
  dim: ScreenerDimension;
  pagination: ScreenerReqPagination;
  primary_selector: ScreenerReqFilters;
  segments: ScreenerReqSegments;
  sorting?: InputMaybe<ScreenerReqSorting>;
};

export type ScreenerReqFilters = {
  country?: InputMaybe<Array<Scalars['Int']['input']>>;
  ethnicity?: InputMaybe<Array<Scalars['Int']['input']>>;
  gender?: InputMaybe<Array<Scalars['Int']['input']>>;
  highest_degree?: InputMaybe<Array<Scalars['Int']['input']>>;
  industry?: InputMaybe<Array<Scalars['Int']['input']>>;
  industry_k50?: InputMaybe<Array<Scalars['Int']['input']>>;
  industry_k400?: InputMaybe<Array<Scalars['Int']['input']>>;
  job_category?: InputMaybe<Array<Scalars['Int']['input']>>;
  metro_area?: InputMaybe<Array<Scalars['Int']['input']>>;
  region?: InputMaybe<Array<Scalars['Int']['input']>>;
  role_k150?: InputMaybe<Array<Scalars['Int']['input']>>;
  role_k1500?: InputMaybe<Array<Scalars['Int']['input']>>;
  seniority?: InputMaybe<Array<Scalars['Int']['input']>>;
  state?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type ScreenerReqPagination = {
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
};

export type ScreenerReqSegmentSelector = {
  end_val?: InputMaybe<Scalars['Float']['input']>;
  filter?: InputMaybe<ScreenerReqFilters>;
  start_val?: InputMaybe<Scalars['Float']['input']>;
};

export type ScreenerReqSegments = {
  attrition?: InputMaybe<Array<ScreenerReqSegmentSelector>>;
  growth?: InputMaybe<Array<ScreenerReqSegmentSelector>>;
  growth_yoy?: InputMaybe<Array<ScreenerReqSegmentSelector>>;
  headcount?: InputMaybe<Array<ScreenerReqSegmentSelector>>;
  hiring?: InputMaybe<Array<ScreenerReqSegmentSelector>>;
  inflow?: InputMaybe<Array<ScreenerReqSegmentSelector>>;
  outflow?: InputMaybe<Array<ScreenerReqSegmentSelector>>;
  salary?: InputMaybe<Array<ScreenerReqSegmentSelector>>;
  tenure?: InputMaybe<Array<ScreenerReqSegmentSelector>>;
};

export type ScreenerReqSorting = {
  ascending: Scalars['Boolean']['input'];
  num_col: Scalars['Int']['input'];
  sort_by: ScreenerSortCol;
};

export type ScreenerResp = {
  __typename?: 'ScreenerResp';
  entities: Array<ScreenerRespEntity>;
  pagination: ScreenerRespPagination;
};

export type ScreenerRespEntity = {
  __typename?: 'ScreenerRespEntity';
  attrition: Array<Scalars['Float']['output']>;
  attrition_rate: Array<Scalars['Float']['output']>;
  entity_id: Scalars['Int']['output'];
  growth: Array<Scalars['Float']['output']>;
  growth_yoy: Array<Scalars['Float']['output']>;
  headcount: Array<Scalars['Float']['output']>;
  hiring: Array<Scalars['Float']['output']>;
  inflow: Array<Scalars['Float']['output']>;
  long_name: Scalars['String']['output'];
  outflow: Array<Scalars['Float']['output']>;
  salary: Array<Scalars['Float']['output']>;
  short_name: Scalars['String']['output'];
  tenure: Array<Scalars['Float']['output']>;
};

export type ScreenerRespPagination = {
  __typename?: 'ScreenerRespPagination';
  total_results: Scalars['Int']['output'];
};

export enum ScreenerSortCol {
  SortByAttrition = 'SORT_BY_ATTRITION',
  SortByGrowth = 'SORT_BY_GROWTH',
  SortByGrowthYoy = 'SORT_BY_GROWTH_YOY',
  SortByHeadcount = 'SORT_BY_HEADCOUNT',
  SortByHiring = 'SORT_BY_HIRING',
  SortByInflow = 'SORT_BY_INFLOW',
  SortByOutflow = 'SORT_BY_OUTFLOW',
  SortBySalary = 'SORT_BY_SALARY',
  SortByTenure = 'SORT_BY_TENURE',
}

export type SelectionList = {
  __typename?: 'SelectionList';
  city?: Maybe<Array<Maybe<GeoRefInfo>>>;
  cityParent?: Maybe<Scalars['String']['output']>;
  company?: Maybe<Array<Maybe<CompanyRefInfo>>>;
  companyParent?: Maybe<Scalars['String']['output']>;
  company_report?: Maybe<Array<Maybe<CompanyRefInfo>>>;
  country?: Maybe<Array<Maybe<GeoRefInfo>>>;
  countryParent?: Maybe<Scalars['String']['output']>;
  custom_role_taxonomy?: Maybe<CustomRoleTaxonomyRef>;
  ethnicity?: Maybe<Array<Maybe<RefInfo>>>;
  gender?: Maybe<Array<Maybe<RefInfo>>>;
  highest_degree?: Maybe<Array<Maybe<RefInfo>>>;
  industry?: Maybe<Array<Maybe<RefInfo>>>;
  job_category?: Maybe<Array<Maybe<RoleRefInfo>>>;
  month?: Maybe<Array<Maybe<RefInfo>>>;
  msa?: Maybe<Array<Maybe<GeoRefInfo>>>;
  msaParent?: Maybe<Scalars['String']['output']>;
  region?: Maybe<Array<Maybe<GeoRefInfo>>>;
  roleK50Parent?: Maybe<Scalars['String']['output']>;
  roleK150Parent?: Maybe<Scalars['String']['output']>;
  roleK300Parent?: Maybe<Scalars['String']['output']>;
  roleK500Parent?: Maybe<Scalars['String']['output']>;
  roleK1000Parent?: Maybe<Scalars['String']['output']>;
  roleK1250Parent?: Maybe<Scalars['String']['output']>;
  roleK1500Parent?: Maybe<Scalars['String']['output']>;
  role_k50?: Maybe<Array<Maybe<RoleRefInfo>>>;
  role_k150?: Maybe<Array<Maybe<RoleRefInfo>>>;
  role_k300?: Maybe<Array<Maybe<RoleRefInfo>>>;
  role_k500?: Maybe<Array<Maybe<RoleRefInfo>>>;
  role_k1000?: Maybe<Array<Maybe<RoleRefInfo>>>;
  role_k1250?: Maybe<Array<Maybe<RoleRefInfo>>>;
  role_k1500?: Maybe<Array<Maybe<RoleRefInfo>>>;
  seniority?: Maybe<Array<Maybe<RefInfo>>>;
  skillK25Parent?: Maybe<Scalars['String']['output']>;
  skillK50Parent?: Maybe<Scalars['String']['output']>;
  skillK75Parent?: Maybe<Scalars['String']['output']>;
  skillK700Parent?: Maybe<Scalars['String']['output']>;
  skillK2500Parent?: Maybe<Scalars['String']['output']>;
  skillK3000Parent?: Maybe<Scalars['String']['output']>;
  skillMappedParent?: Maybe<Scalars['String']['output']>;
  skill_k25?: Maybe<Array<Maybe<SkillRefInfo>>>;
  skill_k50?: Maybe<Array<Maybe<SkillRefInfo>>>;
  skill_k75?: Maybe<Array<Maybe<SkillRefInfo>>>;
  skill_k700?: Maybe<Array<Maybe<SkillRefInfo>>>;
  skill_k2500?: Maybe<Array<Maybe<SkillRefInfo>>>;
  skill_k3000?: Maybe<Array<Maybe<SkillRefInfo>>>;
  skill_mapped?: Maybe<Array<Maybe<SkillRefInfo>>>;
  state?: Maybe<Array<Maybe<GeoRefInfo>>>;
  stateParent?: Maybe<Scalars['String']['output']>;
  week?: Maybe<Array<Maybe<WeekRefInfo>>>;
};

export type SelectionListCustom_Role_TaxonomyArgs = {
  taxonomy_id?: InputMaybe<CustomRoleTaxonomySelection>;
};

export enum SelectionType {
  City = 'city',
  Company = 'company',
  Countries = 'countries',
  CustomRoleTaxonomy = 'custom_role_taxonomy',
  Education = 'education',
  Ethnicity = 'ethnicity',
  Gender = 'gender',
  Industry = 'industry',
  JobCategory = 'job_category',
  Msa = 'msa',
  Regions = 'regions',
  Rolek50 = 'rolek50',
  Rolek500 = 'rolek500',
  Rolek1500 = 'rolek1500',
  Seniority = 'seniority',
  State = 'state',
}

export enum SeniorityVersion {
  V1 = 'V1',
  V2_1 = 'V2_1',
}

export type Sentiment1dResponse = {
  __typename?: 'Sentiment1dResponse';
  metadata?: Maybe<Metadata>;
  metrics?: Maybe<SentimentMetric>;
};

export type Sentiment2dResponse = {
  __typename?: 'Sentiment2dResponse';
  category?: Maybe<Array<Maybe<Sentiment1dResponse>>>;
  metadata?: Maybe<Metadata>;
  metrics?: Maybe<SentimentMetric>;
};

export enum SentimentDimension {
  Month = 'month',
}

export type SentimentFilter = {
  companies?: InputMaybe<Array<Scalars['Int']['input']>>;
  end_date?: InputMaybe<Scalars['String']['input']>;
  job_categories?: InputMaybe<Array<Scalars['Int']['input']>>;
  regions?: InputMaybe<Array<Scalars['Int']['input']>>;
  seniorities?: InputMaybe<Array<Scalars['Int']['input']>>;
  start_date?: InputMaybe<Scalars['String']['input']>;
};

export type SentimentFilters = {
  company?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  custom_role?: InputMaybe<CustomRoleFilter>;
  end_date?: InputMaybe<Scalars['String']['input']>;
  industry?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  job_category?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  msa?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  region?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  role_k50?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  role_k150?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  role_k1500?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  seniority?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  start_date?: InputMaybe<Scalars['String']['input']>;
};

export type SentimentMetric = {
  __typename?: 'SentimentMetric';
  businessOutlookRating?: Maybe<Scalars['Float']['output']>;
  businessOutlookRatingCount?: Maybe<Scalars['Int']['output']>;
  careerAdvancementScore?: Maybe<Scalars['Float']['output']>;
  careerAdvancementScoreCount?: Maybe<Scalars['Int']['output']>;
  careerOpportunitiesRating?: Maybe<Scalars['Float']['output']>;
  careerOpportunitiesRatingCount?: Maybe<Scalars['Int']['output']>;
  companySizeScore?: Maybe<Scalars['Float']['output']>;
  companySizeScoreCount?: Maybe<Scalars['Int']['output']>;
  compensationScore?: Maybe<Scalars['Float']['output']>;
  compensationScoreCount?: Maybe<Scalars['Int']['output']>;
  compensationsBenefitsRating?: Maybe<Scalars['Float']['output']>;
  compensationsBenefitsRatingCount?: Maybe<Scalars['Int']['output']>;
  count?: Maybe<Scalars['Int']['output']>;
  coworkersScore?: Maybe<Scalars['Float']['output']>;
  coworkersScoreCount?: Maybe<Scalars['Int']['output']>;
  cultureScore?: Maybe<Scalars['Float']['output']>;
  cultureScoreCount?: Maybe<Scalars['Int']['output']>;
  cultureValuesRating?: Maybe<Scalars['Float']['output']>;
  cultureValuesRatingCount?: Maybe<Scalars['Int']['output']>;
  diversityAndInclusionScore?: Maybe<Scalars['Float']['output']>;
  diversityAndInclusionScoreCount?: Maybe<Scalars['Int']['output']>;
  diversityInclusionRating?: Maybe<Scalars['Float']['output']>;
  diversityInclusionRatingCount?: Maybe<Scalars['Int']['output']>;
  innovativeTechnologyScore?: Maybe<Scalars['Float']['output']>;
  innovativeTechnologyScoreCount?: Maybe<Scalars['Int']['output']>;
  managementScore?: Maybe<Scalars['Float']['output']>;
  managementScoreCount?: Maybe<Scalars['Int']['output']>;
  mentorshipScore?: Maybe<Scalars['Float']['output']>;
  mentorshipScoreCount?: Maybe<Scalars['Int']['output']>;
  onboardingScore?: Maybe<Scalars['Float']['output']>;
  onboardingScoreCount?: Maybe<Scalars['Int']['output']>;
  overallRating?: Maybe<Scalars['Float']['output']>;
  overallRatingCount?: Maybe<Scalars['Int']['output']>;
  perksScore?: Maybe<Scalars['Float']['output']>;
  perksScoreCount?: Maybe<Scalars['Int']['output']>;
  recommendToAFriendRating?: Maybe<Scalars['Float']['output']>;
  recommendToAFriendRatingCount?: Maybe<Scalars['Int']['output']>;
  remoteWorkScore?: Maybe<Scalars['Float']['output']>;
  remoteWorkScoreCount?: Maybe<Scalars['Int']['output']>;
  seniorLeadershipRating?: Maybe<Scalars['Float']['output']>;
  seniorLeadershipRatingCount?: Maybe<Scalars['Int']['output']>;
  workLifeBalanceRating?: Maybe<Scalars['Float']['output']>;
  workLifeBalanceRatingCount?: Maybe<Scalars['Int']['output']>;
  workLifeBalanceScore?: Maybe<Scalars['Float']['output']>;
  workLifeBalanceScoreCount?: Maybe<Scalars['Int']['output']>;
};

export enum SentimentReviewDimension {
  Negative = 'negative',
  Positive = 'positive',
}

export type SentimentReviewFilters = {
  company?: InputMaybe<Array<Scalars['ID']['input']>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  end_date?: InputMaybe<Scalars['String']['input']>;
  msa?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  region?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  seniority?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  start_date?: InputMaybe<Scalars['String']['input']>;
};

export type SentimentReviewmetric = {
  __typename?: 'SentimentReviewmetric';
  count?: Maybe<Scalars['Float']['output']>;
};

export type SentimentReviews1dResponse = {
  __typename?: 'SentimentReviews1dResponse';
  metadata?: Maybe<Metadata>;
  metrics?: Maybe<SentimentReviewmetric>;
};

export type SentimentReviews2dResponse = {
  __typename?: 'SentimentReviews2dResponse';
  category?: Maybe<Array<Maybe<SentimentReviews1dResponse>>>;
  metadata?: Maybe<Metadata>;
  metrics?: Maybe<SentimentReviewmetric>;
};

export type SetCompanyListRequest = {
  client_name: Scalars['String']['input'];
  company_lists: Array<Scalars['String']['input']>;
};

export type SetReportsValidationRequest = {
  report_validation?: InputMaybe<ReportValidationInput>;
};

export type SingleValueMetric = {
  __typename?: 'SingleValueMetric';
  timeseries?: Maybe<Array<Maybe<TimeDataPoint>>>;
};

export type SkillRefInfo = {
  __typename?: 'SkillRefInfo';
  dataUnavailableIn?: Maybe<ManifestKind>;
  id?: Maybe<Scalars['ID']['output']>;
  longName?: Maybe<Scalars['String']['output']>;
  parentID?: Maybe<Scalars['ID']['output']>;
  shortName?: Maybe<Scalars['String']['output']>;
  skillMappedIndex?: Maybe<Scalars['ID']['output']>;
  skill_k25?: Maybe<Scalars['ID']['output']>;
  skill_k50?: Maybe<Scalars['ID']['output']>;
  skill_k75?: Maybe<Scalars['ID']['output']>;
  skill_k700?: Maybe<Scalars['ID']['output']>;
  skill_k2500?: Maybe<Scalars['ID']['output']>;
  skill_k3000?: Maybe<Scalars['ID']['output']>;
  skill_mapped?: Maybe<Scalars['ID']['output']>;
  topSkills?: Maybe<Scalars['String']['output']>;
};

export type SkillRefInfoInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  longName?: InputMaybe<Scalars['String']['input']>;
  parentID?: InputMaybe<Scalars['ID']['input']>;
  shortName?: InputMaybe<Scalars['String']['input']>;
  skillMappedIndex?: InputMaybe<Scalars['ID']['input']>;
  version?: InputMaybe<Scalars['String']['input']>;
};

export type SnowflakeDelivery = {
  snowflake_db: Scalars['String']['input'];
  snowflake_file_prefix: Scalars['String']['input'];
  snowflake_schema: Scalars['String']['input'];
};

export type SnowflakeDeliveryOutput = {
  __typename?: 'SnowflakeDeliveryOutput';
  snowflake_db: Scalars['String']['output'];
  snowflake_file_prefix: Scalars['String']['output'];
  snowflake_schema: Scalars['String']['output'];
};

export type StateRefInfo = {
  __typename?: 'StateRefInfo';
  id?: Maybe<Scalars['ID']['output']>;
  longName?: Maybe<Scalars['String']['output']>;
  shortName?: Maybe<Scalars['String']['output']>;
};

export type StateRefInfoInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  longName?: InputMaybe<Scalars['String']['input']>;
  shortName?: InputMaybe<Scalars['String']['input']>;
  version?: InputMaybe<Scalars['String']['input']>;
};

export enum Tab {
  Compensation = 'compensation',
  DataDictionary = 'data_dictionary',
  DataEnrichment = 'data_enrichment',
  Deliverables = 'deliverables',
  EntitySummary = 'entity_summary',
  JobAnalysis = 'job_analysis',
  JobAnalysisOverview = 'job_analysis_overview',
  JobEmbedding = 'job_embedding',
  Layoff = 'layoff',
  Overview = 'overview',
  Posting = 'posting',
  Reports = 'reports',
  ResumeParsing = 'resume_parsing',
  Screener = 'screener',
  Sentiment = 'sentiment',
  SentimentV1 = 'sentiment_v1',
  TalentDiscovery = 'talent_discovery',
  TalentDiscoveryV2 = 'talent_discovery_v2',
  TaxonomyVisualizer = 'taxonomy_visualizer',
  Transition = 'transition',
}

export type TalentDiscoveryEducation = {
  __typename?: 'TalentDiscoveryEducation';
  end_date?: Maybe<Scalars['String']['output']>;
  rsid?: Maybe<Scalars['Int']['output']>;
  start_date?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  university_name?: Maybe<Scalars['String']['output']>;
};

export type TalentDiscoveryEntity = {
  __typename?: 'TalentDiscoveryEntity';
  RCID: Scalars['Int']['output'];
  current_company: Scalars['String']['output'];
  current_title: Scalars['String']['output'];
  email: Scalars['String']['output'];
  end_date: Scalars['String']['output'];
  ethnicity: Scalars['String']['output'];
  first_name: Scalars['String']['output'];
  flight_risk: Scalars['String']['output'];
  gender: Scalars['String']['output'];
  highest_degree: Scalars['String']['output'];
  last_name: Scalars['String']['output'];
  latitude: Scalars['Float']['output'];
  linkedin_url: Scalars['String']['output'];
  location: Scalars['String']['output'];
  longitude: Scalars['Float']['output'];
  personal_email: Scalars['String']['output'];
  phone_number?: Maybe<Scalars['String']['output']>;
  prestige: Scalars['String']['output'];
  remote_suitability: Scalars['String']['output'];
  salary_base: Scalars['Float']['output'];
  salary_total: Scalars['Float']['output'];
  start_date: Scalars['String']['output'];
  years_of_experience: Scalars['Float']['output'];
};

export type TalentDiscoveryMetadata = {
  __typename?: 'TalentDiscoveryMetadata';
  entity_columns: Array<Scalars['String']['output']>;
  total_pages: Scalars['Int']['output'];
};

export type TalentDiscoveryRequestData = {
  current?: InputMaybe<Scalars['Boolean']['input']>;
  ids?: InputMaybe<Array<Scalars['Int']['input']>>;
  non_current?: InputMaybe<Scalars['Boolean']['input']>;
};

export type TalentDiscoveryResponse = {
  __typename?: 'TalentDiscoveryResponse';
  columns: Array<Scalars['String']['output']>;
  map_data?: Maybe<Array<Maybe<MapData>>>;
  metadata?: Maybe<TalentDiscoveryMetadata>;
  users?: Maybe<Array<Maybe<TalentDiscoveryUser>>>;
};

export type TalentDiscoverySalaryRange = {
  current: Scalars['Boolean']['input'];
  end_value: Scalars['Float']['input'];
  non_current: Scalars['Boolean']['input'];
  start_value: Scalars['Float']['input'];
};

export type TalentDiscoveryUser = {
  __typename?: 'TalentDiscoveryUser';
  RCID: Scalars['Int']['output'];
  current_company: Scalars['String']['output'];
  current_title: Scalars['String']['output'];
  education: Scalars['String']['output'];
  education_history?: Maybe<Array<Maybe<TalentDiscoveryEducation>>>;
  email: Scalars['String']['output'];
  end_date: Scalars['String']['output'];
  ethnicity: Scalars['String']['output'];
  first_name: Scalars['String']['output'];
  flight_risk: Scalars['String']['output'];
  gender: Scalars['String']['output'];
  highest_degree: Scalars['String']['output'];
  history: Array<TalentDiscoveryEntity>;
  last_name: Scalars['String']['output'];
  latitude: Scalars['Float']['output'];
  linkedin_url: Scalars['String']['output'];
  location: Scalars['String']['output'];
  longitude: Scalars['Float']['output'];
  personal_email: Scalars['String']['output'];
  phone_number?: Maybe<Scalars['String']['output']>;
  prestige: Scalars['String']['output'];
  remote_suitability: Scalars['String']['output'];
  salary_base?: Maybe<Scalars['Float']['output']>;
  salary_total?: Maybe<Scalars['Float']['output']>;
  start_date: Scalars['String']['output'];
  user_id: Scalars['Int']['output'];
  years_of_experience: Scalars['Float']['output'];
};

export type TalentDiscoveryV1_5Filter = {
  company?: InputMaybe<TalentDiscoveryRequestData>;
  country?: InputMaybe<TalentDiscoveryRequestData>;
  end_date?: InputMaybe<Scalars['String']['input']>;
  ethnicity?: InputMaybe<Array<Scalars['Int']['input']>>;
  flight_risk?: InputMaybe<TalentDiscoveryRequestData>;
  free_texts?: InputMaybe<Array<Array<Scalars['String']['input']>>>;
  gender?: InputMaybe<Array<Scalars['Int']['input']>>;
  highest_degree?: InputMaybe<Array<Scalars['Int']['input']>>;
  job_category?: InputMaybe<TalentDiscoveryRequestData>;
  msa?: InputMaybe<TalentDiscoveryRequestData>;
  name?: InputMaybe<Scalars['String']['input']>;
  num_results?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  prestige?: InputMaybe<TalentDiscoveryRequestData>;
  region?: InputMaybe<TalentDiscoveryRequestData>;
  remote_suitability?: InputMaybe<TalentDiscoveryRequestData>;
  role_k150?: InputMaybe<TalentDiscoveryRequestData>;
  role_k1500?: InputMaybe<TalentDiscoveryRequestData>;
  rsid?: InputMaybe<Array<Scalars['Int']['input']>>;
  salary_base?: InputMaybe<TalentDiscoverySalaryRange>;
  salary_total?: InputMaybe<TalentDiscoverySalaryRange>;
  seniority?: InputMaybe<TalentDiscoveryRequestData>;
  skills?: InputMaybe<Array<Array<TalentDiscoveryV1_5FilterWithTag>>>;
  start_date?: InputMaybe<Scalars['String']['input']>;
};

export type TalentDiscoveryV1_5FilterWithTag = {
  dim: TalentDiscoveryV1_5Tags;
  id: Scalars['Int']['input'];
};

export enum TalentDiscoveryV1_5Tags {
  SkillsK75 = 'SKILLS_K75',
  SkillsK700 = 'SKILLS_K700',
  SkillsK3000 = 'SKILLS_K3000',
}

export type TalentDiscoveryV2EduHistoryItem = {
  __typename?: 'TalentDiscoveryV2EduHistoryItem';
  end_date?: Maybe<Scalars['String']['output']>;
  rsid?: Maybe<Scalars['Int']['output']>;
  start_date?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  university_name?: Maybe<Scalars['String']['output']>;
};

export type TalentDiscoveryV2NameRecommendationResp = {
  __typename?: 'TalentDiscoveryV2NameRecommendationResp';
  names?: Maybe<Array<Scalars['String']['output']>>;
  num_results: Scalars['Int']['output'];
};

export type TalentDiscoveryV2ReqFilters = {
  ethnicity?: InputMaybe<Array<Scalars['Int']['input']>>;
  flight_risk?: InputMaybe<Array<FilterWithCurrent>>;
  free_texts?: InputMaybe<Array<Array<Scalars['String']['input']>>>;
  gender?: InputMaybe<Array<Scalars['Int']['input']>>;
  geo_group?: InputMaybe<Array<FilterWithCurrentAndTag>>;
  highest_degree?: InputMaybe<Array<Scalars['Int']['input']>>;
  industry?: InputMaybe<Array<FilterWithCurrent>>;
  name?: InputMaybe<Scalars['String']['input']>;
  prestige?: InputMaybe<Array<FilterWithCurrent>>;
  rcid?: InputMaybe<Array<FilterWithCurrent>>;
  remote_suitability?: InputMaybe<Array<FilterWithCurrent>>;
  role_group?: InputMaybe<Array<FilterWithCurrentAndTag>>;
  rsid?: InputMaybe<Array<Scalars['Int']['input']>>;
  salary_base?: InputMaybe<FilterSalary>;
  salary_total?: InputMaybe<FilterSalary>;
  seniority?: InputMaybe<Array<FilterWithCurrent>>;
  skills_group?: InputMaybe<Array<Array<FilterWithTag>>>;
};

export type TalentDiscoveryV2ReqPagination = {
  page_num: Scalars['Int']['input'];
  page_size: Scalars['Int']['input'];
};

export type TalentDiscoveryV2RespGeoData = {
  __typename?: 'TalentDiscoveryV2RespGeoData';
  count: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  lat?: Maybe<Scalars['Float']['output']>;
  location?: Maybe<Scalars['String']['output']>;
  lon?: Maybe<Scalars['Float']['output']>;
  share: Scalars['Float']['output'];
  type: Scalars['Int']['output'];
};

export type TalentDiscoveryV2RespPagination = {
  __typename?: 'TalentDiscoveryV2RespPagination';
  tot_pages_num: Scalars['Int']['output'];
};

export type TalentDiscoveryV2RespUser = {
  __typename?: 'TalentDiscoveryV2RespUser';
  RCID: Scalars['Int']['output'];
  current_company: Scalars['String']['output'];
  current_title: Scalars['String']['output'];
  education: Scalars['String']['output'];
  education_history: Array<TalentDiscoveryV2EduHistoryItem>;
  email: Scalars['String']['output'];
  end_date: Scalars['String']['output'];
  ethnicity: Scalars['String']['output'];
  first_name: Scalars['String']['output'];
  flight_risk: Scalars['String']['output'];
  gender: Scalars['String']['output'];
  highest_degree: Scalars['String']['output'];
  last_name: Scalars['String']['output'];
  latitude: Scalars['Float']['output'];
  linkedin_url: Scalars['String']['output'];
  location: Scalars['String']['output'];
  longitude: Scalars['Float']['output'];
  personal_email: Scalars['String']['output'];
  phone_number: Scalars['String']['output'];
  prestige: Scalars['String']['output'];
  remote_suitability: Scalars['String']['output'];
  salary_base: Scalars['Float']['output'];
  salary_total: Scalars['Float']['output'];
  start_date: Scalars['String']['output'];
  user_id: Scalars['Int']['output'];
  work_history: Array<TalentDiscoveryV2WorkHistoryItem>;
  years_of_experience: Scalars['Float']['output'];
};

export type TalentDiscoveryV2SearchReq = {
  filters: TalentDiscoveryV2ReqFilters;
  pagination: TalentDiscoveryV2ReqPagination;
};

export type TalentDiscoveryV2SearchResp = {
  __typename?: 'TalentDiscoveryV2SearchResp';
  columns: Array<Scalars['String']['output']>;
  map_data?: Maybe<Array<Maybe<TalentDiscoveryV2RespGeoData>>>;
  pagination?: Maybe<TalentDiscoveryV2RespPagination>;
  users?: Maybe<Array<Maybe<TalentDiscoveryV2RespUser>>>;
};

export enum TalentDiscoveryV2Tags {
  Country = 'COUNTRY',
  Msa = 'MSA',
  Region = 'REGION',
  RoleK7 = 'ROLE_K7',
  RoleK150 = 'ROLE_K150',
  RoleK1500 = 'ROLE_K1500',
  SkillsK75 = 'SKILLS_K75',
  SkillsK700 = 'SKILLS_K700',
  SkillsK3000 = 'SKILLS_K3000',
}

export type TalentDiscoveryV2WorkHistoryItem = {
  __typename?: 'TalentDiscoveryV2WorkHistoryItem';
  RCID: Scalars['Int']['output'];
  current_company: Scalars['String']['output'];
  current_title: Scalars['String']['output'];
  email: Scalars['String']['output'];
  end_date: Scalars['String']['output'];
  ethnicity: Scalars['String']['output'];
  first_name: Scalars['String']['output'];
  flight_risk: Scalars['String']['output'];
  gender: Scalars['String']['output'];
  highest_degree: Scalars['String']['output'];
  last_name: Scalars['String']['output'];
  latitude: Scalars['Float']['output'];
  linkedin_url: Scalars['String']['output'];
  location: Scalars['String']['output'];
  longitude: Scalars['Float']['output'];
  personal_email: Scalars['String']['output'];
  phone_number: Scalars['String']['output'];
  prestige: Scalars['String']['output'];
  remote_suitability: Scalars['String']['output'];
  salary_base: Scalars['Float']['output'];
  salary_total: Scalars['Float']['output'];
  start_date: Scalars['String']['output'];
  years_of_experience: Scalars['Float']['output'];
};

export type TimeDataPoint = {
  __typename?: 'TimeDataPoint';
  count?: Maybe<Scalars['Float']['output']>;
  date?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  share?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

export enum TimescalingVersion {
  V2 = 'V2',
  V3 = 'V3',
  V3_1 = 'V3_1',
}

export type Transition2DResponse = {
  __typename?: 'Transition2DResponse';
  category?: Maybe<Array<Maybe<TransitionCategory>>>;
  metadata?: Maybe<Metadata>;
  metrics?: Maybe<TransitionMetrics>;
};

export type TransitionCategory = {
  __typename?: 'TransitionCategory';
  metadata?: Maybe<Metadata>;
  metrics?: Maybe<TransitionCategoryVal>;
};

export type TransitionCategoryVal = {
  __typename?: 'TransitionCategoryVal';
  count?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

export enum TransitionDimension {
  Inflow = 'inflow',
  Outflow = 'outflow',
}

export type TransitionFilters = {
  company?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  custom_role?: InputMaybe<CustomRoleFilter>;
  end_date?: InputMaybe<Scalars['String']['input']>;
  ethnicity?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  gender?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  highest_degree?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  industry?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  job_category?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  msa?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  node_limit?: InputMaybe<Scalars['Int']['input']>;
  region?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  role_k150?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  role_k1500?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  seniority?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  skill_k75?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  skill_k700?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  skill_k3000?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  start_date?: InputMaybe<Scalars['String']['input']>;
};

export type TransitionInfo = {
  __typename?: 'TransitionInfo';
  current?: Maybe<Scalars['Float']['output']>;
  currentShare?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  inflow?: Maybe<Scalars['Float']['output']>;
  inflowShare?: Maybe<Scalars['Float']['output']>;
  longName?: Maybe<Scalars['String']['output']>;
  outflow?: Maybe<Scalars['Float']['output']>;
  outflowShare?: Maybe<Scalars['Float']['output']>;
  shortName?: Maybe<Scalars['String']['output']>;
};

export type TransitionMetrics = {
  __typename?: 'TransitionMetrics';
  count?: Maybe<Scalars['Float']['output']>;
  education?: Maybe<Array<Maybe<TransitionInfo>>>;
  ethnicity?: Maybe<Array<Maybe<TransitionInfo>>>;
  gender?: Maybe<Array<Maybe<TransitionInfo>>>;
  geography?: Maybe<Array<Maybe<TransitionInfo>>>;
  industry?: Maybe<Array<Maybe<TransitionInfo>>>;
  role?: Maybe<Array<Maybe<TransitionInfo>>>;
  seniority?: Maybe<Array<Maybe<TransitionInfo>>>;
};

export type UpdateDeliverableConfigRequest = {
  batchTime?: InputMaybe<Scalars['String']['input']>;
  cacheValue?: InputMaybe<Scalars['String']['input']>;
  imageName?: InputMaybe<Scalars['String']['input']>;
  isLocal?: InputMaybe<Scalars['Boolean']['input']>;
  jobUpdateInterval?: InputMaybe<Scalars['String']['input']>;
  namespace?: InputMaybe<Scalars['String']['input']>;
  reports_env?: InputMaybe<Scalars['String']['input']>;
  reports_image?: InputMaybe<Scalars['String']['input']>;
  reports_namespace?: InputMaybe<Scalars['String']['input']>;
  testDeliverable?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdateIndexRequest = {
  endMonthIndex?: InputMaybe<Scalars['Int']['input']>;
  endWeekIndex?: InputMaybe<Scalars['Int']['input']>;
  maxWeekIndex?: InputMaybe<Scalars['Int']['input']>;
  startMonthIndex?: InputMaybe<Scalars['Int']['input']>;
  startWeekIndex?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateManifestValues = {
  competitorsCompany?: InputMaybe<Scalars['String']['input']>;
  delay?: InputMaybe<Scalars['Int']['input']>;
  positionCompany?: InputMaybe<Scalars['String']['input']>;
  positionCountry?: InputMaybe<Scalars['String']['input']>;
  positionIndustry?: InputMaybe<Scalars['String']['input']>;
  positionJobCategory?: InputMaybe<Scalars['String']['input']>;
  positionMSA?: InputMaybe<Scalars['String']['input']>;
  positionMappedRole?: InputMaybe<Scalars['String']['input']>;
  positionMappedRole7?: InputMaybe<Scalars['String']['input']>;
  positionMappedRole150?: InputMaybe<Scalars['String']['input']>;
  positionRegion?: InputMaybe<Scalars['String']['input']>;
  postingCompany?: InputMaybe<Scalars['String']['input']>;
  postingCountry?: InputMaybe<Scalars['String']['input']>;
  postingIndustry?: InputMaybe<Scalars['String']['input']>;
  postingMSA?: InputMaybe<Scalars['String']['input']>;
  postingMappedRole?: InputMaybe<Scalars['String']['input']>;
  postingMappedRoleK7?: InputMaybe<Scalars['String']['input']>;
  postingMappedRoleK150?: InputMaybe<Scalars['String']['input']>;
  postingRegion?: InputMaybe<Scalars['String']['input']>;
  screenerCompany?: InputMaybe<Scalars['String']['input']>;
  screenerMSA?: InputMaybe<Scalars['String']['input']>;
  screenerMappedRole?: InputMaybe<Scalars['String']['input']>;
  sentimentCompany?: InputMaybe<Scalars['String']['input']>;
  sentimentCountry?: InputMaybe<Scalars['String']['input']>;
  sentimentIndustry?: InputMaybe<Scalars['String']['input']>;
  sentimentMSA?: InputMaybe<Scalars['String']['input']>;
  sentimentMappedRole?: InputMaybe<Scalars['String']['input']>;
  sentimentMappedRoleK7?: InputMaybe<Scalars['String']['input']>;
  sentimentMappedRoleK150?: InputMaybe<Scalars['String']['input']>;
  sentimentRegion?: InputMaybe<Scalars['String']['input']>;
  sentimentReviewCompany?: InputMaybe<Scalars['String']['input']>;
  sentimentReviewCountry?: InputMaybe<Scalars['String']['input']>;
  sentimentReviewIndustry?: InputMaybe<Scalars['String']['input']>;
  sentimentReviewMappedRole?: InputMaybe<Scalars['String']['input']>;
  sentimentReviewMappedRoleK7?: InputMaybe<Scalars['String']['input']>;
  sentimentReviewMappedRoleK150?: InputMaybe<Scalars['String']['input']>;
  sentimentReviewMsa?: InputMaybe<Scalars['String']['input']>;
  sentimentReviewRegion?: InputMaybe<Scalars['String']['input']>;
  transitionCompany?: InputMaybe<Scalars['String']['input']>;
  transitionCountry?: InputMaybe<Scalars['String']['input']>;
  transitionIndustry?: InputMaybe<Scalars['String']['input']>;
  transitionMSA?: InputMaybe<Scalars['String']['input']>;
  transitionMappedRole?: InputMaybe<Scalars['String']['input']>;
  transitionMappedRoleK7?: InputMaybe<Scalars['String']['input']>;
  transitionMappedRoleK150?: InputMaybe<Scalars['String']['input']>;
  transitionRegion?: InputMaybe<Scalars['String']['input']>;
  usersSearch?: InputMaybe<Scalars['String']['input']>;
};

export type UpdatePresetRequest = {
  default?: InputMaybe<Scalars['Boolean']['input']>;
  filters?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  shared?: InputMaybe<Scalars['Boolean']['input']>;
  view?: InputMaybe<View>;
};

export type User = {
  __typename?: 'User';
  active?: Maybe<Scalars['Boolean']['output']>;
  client_name?: Maybe<Scalars['String']['output']>;
  company_lists?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  deliverables?: Maybe<Array<ClientDeliverableOutput>>;
  email?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  legacy_id?: Maybe<Scalars['String']['output']>;
  linkup_postings?: Maybe<Scalars['Boolean']['output']>;
  live?: Maybe<Scalars['Boolean']['output']>;
  metadata?: Maybe<Array<Maybe<KeyValueResp>>>;
  name?: Maybe<Scalars['String']['output']>;
  num_seats?: Maybe<Scalars['String']['output']>;
  reports?: Maybe<Array<Report>>;
  resumes?: Maybe<Array<Resume>>;
  role?: Maybe<Role>;
  tabs?: Maybe<Array<Tab>>;
  unified_postings?: Maybe<Scalars['Boolean']['output']>;
  username?: Maybe<Scalars['String']['output']>;
};

export type UserRequest = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  client_name?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  legacy_id?: InputMaybe<Scalars['String']['input']>;
  metadata?: InputMaybe<Array<InputMaybe<KeyValue>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Role>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export enum View {
  CompanyComposition = 'company_composition',
  CompanyPostings = 'company_postings',
  CompanyRatings = 'company_ratings',
  CompanySentiment = 'company_sentiment',
  CompanySentimentEffects = 'company_sentiment_effects',
  CompanySentimentRatings = 'company_sentiment_ratings',
  CompanySentimentReviews = 'company_sentiment_reviews',
  CompanySkill = 'company_skill',
  CompanyTransitions = 'company_transitions',
  GeographyComposition = 'geography_composition',
  GeographyPostings = 'geography_postings',
  GeographySentiment = 'geography_sentiment',
  GeographySentimentEffects = 'geography_sentiment_effects',
  GeographySentimentRatings = 'geography_sentiment_ratings',
  GeographySentimentReviews = 'geography_sentiment_reviews',
  GeographySkills = 'geography_skills',
  GeographyTransitions = 'geography_transitions',
  RoleComposition = 'role_composition',
  RolePostings = 'role_postings',
  RoleSentiment = 'role_sentiment',
  RoleSentimentEffects = 'role_sentiment_effects',
  RoleSentimentRatings = 'role_sentiment_ratings',
  RoleSentimentReviews = 'role_sentiment_reviews',
  RoleSkills = 'role_skills',
  RoleTransitions = 'role_transitions',
  SkillsPostings = 'skills_postings',
}

export type WeekRefInfo = {
  __typename?: 'WeekRefInfo';
  dataUnavailableIn?: Maybe<ManifestKind>;
  id?: Maybe<Scalars['ID']['output']>;
  longName?: Maybe<Scalars['String']['output']>;
  monthId?: Maybe<Scalars['ID']['output']>;
  shortName?: Maybe<Scalars['String']['output']>;
};

export type WeekRefInfoInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  longName?: InputMaybe<Scalars['String']['input']>;
  monthId?: InputMaybe<Scalars['ID']['input']>;
  shortName?: InputMaybe<Scalars['String']['input']>;
};

export enum WeightTable {
  V1 = 'V1',
  V2 = 'V2',
}

export type GetBasicUserDetailsQueryVariables = Exact<{
  ids?: InputMaybe<
    | Array<InputMaybe<Scalars['String']['input']>>
    | InputMaybe<Scalars['String']['input']>
  >;
}>;

export type GetBasicUserDetailsQuery = {
  __typename?: 'Query';
  users?: Array<{
    __typename?: 'User';
    id?: string | null;
    client_name?: string | null;
    active?: boolean | null;
  } | null> | null;
};

export type GetAllUsersQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllUsersQuery = {
  __typename?: 'Query';
  users?: Array<{
    __typename?: 'User';
    id?: string | null;
    name?: string | null;
    username?: string | null;
    email?: string | null;
    live?: boolean | null;
    legacy_id?: string | null;
  } | null> | null;
};

export type AllClientsQueryVariables = Exact<{ [key: string]: never }>;

export type AllClientsQuery = {
  __typename?: 'Query';
  clients?: Array<{
    __typename?: 'Client';
    client_name?: string | null;
  } | null> | null;
};

export type AllClientsTableQueryVariables = Exact<{ [key: string]: never }>;

export type AllClientsTableQuery = {
  __typename?: 'Query';
  clients?: Array<{
    __typename?: 'Client';
    client_name?: string | null;
    active: boolean;
    num_seats: string;
    users?: Array<{ __typename?: 'User'; id?: string | null } | null> | null;
  } | null> | null;
};

export type CreateClientRewriteMutationVariables = Exact<{
  client_name: Scalars['String']['input'];
  tabs: Array<Tab> | Tab;
  live: Scalars['Boolean']['input'];
  active: Scalars['Boolean']['input'];
  linkup_postings: Scalars['Boolean']['input'];
  unified_postings: Scalars['Boolean']['input'];
  num_seats: Scalars['String']['input'];
  company_lists?: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >;
  data_builder_configuration: PipelineClientConfigInput;
  reports_configuration?: InputMaybe<ClientReportsConfigInput>;
}>;

export type CreateClientRewriteMutation = {
  __typename?: 'Mutation';
  createClientGroup?: {
    __typename?: 'Client';
    client_name?: string | null;
  } | null;
};

export type GetAllClientUsersQueryVariables = Exact<{
  clientName: Scalars['String']['input'];
}>;

export type GetAllClientUsersQuery = {
  __typename?: 'Query';
  clients?: Array<{
    __typename?: 'Client';
    users?: Array<{
      __typename?: 'User';
      id?: string | null;
      name?: string | null;
      username?: string | null;
      email?: string | null;
      live?: boolean | null;
      active?: boolean | null;
      legacy_id?: string | null;
    } | null> | null;
  } | null> | null;
};

export type GetAllClientsQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllClientsQuery = {
  __typename?: 'Query';
  clients?: Array<{
    __typename?: 'Client';
    client_name?: string | null;
  } | null> | null;
};

export type GetClientDetailsQueryVariables = Exact<{
  ids:
    | Array<InputMaybe<Scalars['String']['input']>>
    | InputMaybe<Scalars['String']['input']>;
}>;

export type GetClientDetailsQuery = {
  __typename?: 'Query';
  clients?: Array<{
    __typename?: 'Client';
    client_name?: string | null;
    company_lists?: Array<string> | null;
    active: boolean;
    tabs?: Array<Tab> | null;
    live: boolean;
    linkup_postings: boolean;
    unified_postings: boolean;
    num_seats: string;
    data_builder_configuration?: {
      __typename?: 'PipelineClientConfig';
      client_group?: string | null;
      pipeline_type?: Array<PipelineType | null> | null;
      posting_source?: Array<PostingSource | null> | null;
      s3_location?: {
        __typename?: 'S3DeliveryOutput';
        s3_bucket: string;
        s3_file_prefix: string;
        s3_format: S3Format;
      } | null;
      snowflake_location?: {
        __typename?: 'SnowflakeDeliveryOutput';
        snowflake_db: string;
        snowflake_schema: string;
        snowflake_file_prefix: string;
      } | null;
    } | null;
    reports_configuration?: {
      __typename?: 'ClientReportsConfig';
      report_types: Array<ReportTypeEnum>;
    } | null;
  } | null> | null;
};

export type UpdateUserMutationVariables = Exact<{
  id: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Role>;
  client_name?: InputMaybe<Scalars['String']['input']>;
  active?: InputMaybe<Scalars['Boolean']['input']>;
  metadata?: InputMaybe<Array<InputMaybe<KeyValue>> | InputMaybe<KeyValue>>;
}>;

export type UpdateUserMutation = {
  __typename?: 'Mutation';
  updateUser?: {
    __typename?: 'User';
    id?: string | null;
    name?: string | null;
    username?: string | null;
    email?: string | null;
    role?: Role | null;
    client_name?: string | null;
    live?: boolean | null;
    active?: boolean | null;
    tabs?: Array<Tab> | null;
    company_lists?: Array<string | null> | null;
    linkup_postings?: boolean | null;
    unified_postings?: boolean | null;
    num_seats?: string | null;
    metadata?: Array<{
      __typename?: 'KeyValueResp';
      key?: MetadataKey | null;
      value?: string | null;
    } | null> | null;
  } | null;
};

export type UpdateUserMetaDataMutationVariables = Exact<{
  id: Scalars['String']['input'];
  metadata?: InputMaybe<Array<InputMaybe<KeyValue>> | InputMaybe<KeyValue>>;
}>;

export type UpdateUserMetaDataMutation = {
  __typename?: 'Mutation';
  updateUser?: {
    __typename?: 'User';
    id?: string | null;
    name?: string | null;
    username?: string | null;
    email?: string | null;
    role?: Role | null;
    client_name?: string | null;
    live?: boolean | null;
    active?: boolean | null;
    tabs?: Array<Tab> | null;
    company_lists?: Array<string | null> | null;
    linkup_postings?: boolean | null;
    unified_postings?: boolean | null;
    num_seats?: string | null;
    metadata?: Array<{
      __typename?: 'KeyValueResp';
      key?: MetadataKey | null;
      value?: string | null;
    } | null> | null;
  } | null;
};

export type AddUserMutationVariables = Exact<{
  name: Scalars['String']['input'];
  email: Scalars['String']['input'];
  password?: InputMaybe<Scalars['String']['input']>;
  role: Role;
  client_name: Scalars['String']['input'];
  active: Scalars['Boolean']['input'];
  metadata?: InputMaybe<Array<InputMaybe<KeyValue>> | InputMaybe<KeyValue>>;
}>;

export type AddUserMutation = {
  __typename?: 'Mutation';
  createUser: {
    __typename?: 'User';
    id?: string | null;
    name?: string | null;
    username?: string | null;
    email?: string | null;
    role?: Role | null;
    client_name?: string | null;
    live?: boolean | null;
    active?: boolean | null;
    tabs?: Array<Tab> | null;
    company_lists?: Array<string | null> | null;
    linkup_postings?: boolean | null;
    unified_postings?: boolean | null;
    num_seats?: string | null;
    metadata?: Array<{
      __typename?: 'KeyValueResp';
      key?: MetadataKey | null;
      value?: string | null;
    } | null> | null;
  };
};

export type UpdateClientGroupRewriteMutationVariables = Exact<{
  client_name: Scalars['String']['input'];
  tabs: Array<Tab> | Tab;
  live: Scalars['Boolean']['input'];
  active: Scalars['Boolean']['input'];
  linkup_postings: Scalars['Boolean']['input'];
  unified_postings: Scalars['Boolean']['input'];
  num_seats: Scalars['String']['input'];
  data_builder_configuration: PipelineClientConfigInput;
  reports_configuration?: InputMaybe<ClientReportsConfigInput>;
}>;

export type UpdateClientGroupRewriteMutation = {
  __typename?: 'Mutation';
  updateClientGroup?: {
    __typename?: 'Client';
    client_name?: string | null;
    company_lists?: Array<string> | null;
    active: boolean;
    tabs?: Array<Tab> | null;
    live: boolean;
    linkup_postings: boolean;
    unified_postings: boolean;
    num_seats: string;
  } | null;
};

export type DeleteUserMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type DeleteUserMutation = {
  __typename?: 'Mutation';
  deleteUser?: boolean | null;
};

export type GetPipelineConfigsQueryVariables = Exact<{ [key: string]: never }>;

export type GetPipelineConfigsQuery = {
  __typename?: 'Query';
  pipelineConfiguration?: Array<{
    __typename?: 'PipelineConfig';
    id?: string | null;
    name?: string | null;
    dashboard_global?: boolean | null;
    user_id?: string | null;
    client_id?: string | null;
    model_versions?: {
      __typename?: 'ModelVersionsType';
      location_version?: LocationVersion | null;
      timescaling_version?: TimescalingVersion | null;
      ethnicity_version?: EthnicityVersion | null;
      job_taxonomy_version?: JobTaxonomyVersion | null;
      company_mapper_version?: CompanyMapperVersion | null;
      salary_version?: SalaryVersion | null;
      seniority_version?: SeniorityVersion | null;
      weight_table?: WeightTable | null;
      remove_bad_users?: boolean | null;
    } | null;
    pipeline?: {
      __typename?: 'PipelineOutput';
      pipeline_type: PipelineType;
      columns?: Array<PipelineColumnType | null> | null;
      custom_columns?: Array<{
        __typename?: 'CustomColumn';
        name: string;
        step?: Array<{
          __typename?: 'CustomColumnStep';
          then?: string | null;
          case?: {
            __typename?: 'CustomColumnCase';
            binary?: BinaryOperation | null;
            conditions?: Array<{
              __typename?: 'CustomColumnCondition';
              variable: CustomColumnConditionVariable;
              value: Array<string | null>;
              operation: OperationType;
            } | null> | null;
          } | null;
        } | null> | null;
      } | null> | null;
      filters?: Array<{
        __typename?: 'PipelineFilter';
        name: string;
        binary?: BinaryOperation | null;
        conditions?: Array<{
          __typename?: 'CustomColumnCondition';
          variable: CustomColumnConditionVariable;
          value: Array<string | null>;
          operation: OperationType;
        }> | null;
      } | null> | null;
    } | null;
  } | null> | null;
};

export type SavePipelineConfigurationMutationVariables = Exact<{
  configuration: PipelineConfigInput;
}>;

export type SavePipelineConfigurationMutation = {
  __typename?: 'Mutation';
  savePipelineConfiguration?: {
    __typename?: 'PipelineConfig';
    name?: string | null;
    dashboard_global?: boolean | null;
    user_id?: string | null;
    client_id?: string | null;
    model_versions?: {
      __typename?: 'ModelVersionsType';
      location_version?: LocationVersion | null;
      timescaling_version?: TimescalingVersion | null;
      ethnicity_version?: EthnicityVersion | null;
      job_taxonomy_version?: JobTaxonomyVersion | null;
      company_mapper_version?: CompanyMapperVersion | null;
      salary_version?: SalaryVersion | null;
      seniority_version?: SeniorityVersion | null;
      weight_table?: WeightTable | null;
      remove_bad_users?: boolean | null;
    } | null;
    pipeline?: {
      __typename?: 'PipelineOutput';
      pipeline_type: PipelineType;
      columns?: Array<PipelineColumnType | null> | null;
      custom_columns?: Array<{
        __typename?: 'CustomColumn';
        name: string;
        step?: Array<{
          __typename?: 'CustomColumnStep';
          then?: string | null;
          case?: {
            __typename?: 'CustomColumnCase';
            binary?: BinaryOperation | null;
            conditions?: Array<{
              __typename?: 'CustomColumnCondition';
              variable: CustomColumnConditionVariable;
              value: Array<string | null>;
              operation: OperationType;
            } | null> | null;
          } | null;
        } | null> | null;
      } | null> | null;
      filters?: Array<{
        __typename?: 'PipelineFilter';
        name: string;
        binary?: BinaryOperation | null;
        conditions?: Array<{
          __typename?: 'CustomColumnCondition';
          variable: CustomColumnConditionVariable;
          value: Array<string | null>;
          operation: OperationType;
        }> | null;
      } | null> | null;
    } | null;
  } | null;
};

export type DeletePipelineConfigurationMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type DeletePipelineConfigurationMutation = {
  __typename?: 'Mutation';
  deletePipelineConfiguration?: boolean | null;
};

export type SubmitDeliverableMutationVariables = Exact<{
  deliverable?: InputMaybe<ClientDeliverableInput>;
}>;

export type SubmitDeliverableMutation = {
  __typename?: 'Mutation';
  createDeliverables?: {
    __typename?: 'ClientDeliverableResponse';
    user_id?: string | null;
    delivery_id?: number | null;
  } | null;
};

export type CreateReportsMutationVariables = Exact<{
  input: CreateReportRequest;
}>;

export type CreateReportsMutation = {
  __typename?: 'Mutation';
  createReports?: {
    __typename?: 'CreateReportResponse';
    user_id?: string | null;
    report_id?: number | null;
  } | null;
};

export type DeleteReportMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type DeleteReportMutation = {
  __typename?: 'Mutation';
  deleteReport?: boolean | null;
};

export type RefreshReportMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type RefreshReportMutation = {
  __typename?: 'Mutation';
  refreshReport?: boolean | null;
};

export type GetReportConfigQueryVariables = Exact<{ [key: string]: never }>;

export type GetReportConfigQuery = {
  __typename?: 'Query';
  reports_validation: Array<{
    __typename?: 'ReportsValidationResponse';
    report_validation?: {
      __typename?: 'ReportValidation';
      id: number;
      report_type: ReportTypeEnum;
      label: string;
      description: string;
      sample_link?: string | null;
      main_entity: Array<{
        __typename?: 'ReportValidationEntity';
        selection_list: Array<string>;
        limit: number;
        required: boolean;
        label: string;
        description: string;
      } | null>;
      comparison_entities?: Array<{
        __typename?: 'ReportValidationEntity';
        selection_list: Array<string>;
        limit: number;
        required: boolean;
        label: string;
        description: string;
      } | null> | null;
      filters?: Array<{
        __typename?: 'ReportValidationEntity';
        selection_list: Array<string>;
        limit: number;
        required: boolean;
        label: string;
        description: string;
      } | null> | null;
    } | null;
  } | null>;
};

export type GetAllUsersNameAndIdQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetAllUsersNameAndIdQuery = {
  __typename?: 'Query';
  users?: Array<{
    __typename?: 'User';
    id?: string | null;
    name?: string | null;
    email?: string | null;
    client_name?: string | null;
  } | null> | null;
};

export type GetUserDeliverablesQueryVariables = Exact<{
  ids: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;

export type GetUserDeliverablesQuery = {
  __typename?: 'Query';
  users?: Array<{
    __typename?: 'User';
    deliverables?: Array<{
      __typename?: 'ClientDeliverableOutput';
      job_id: number;
      status: DeliveryStatus;
      updated_at?: string | null;
      created_at?: string | null;
      deliverable: {
        __typename?: 'ClientDeliverable';
        name: string;
        client: string;
        lag_data?: boolean | null;
        company_sets?: Array<CompanySet | null> | null;
        company_reference?: string | null;
        pipeline_input?: string | null;
        deliverables: Array<{
          __typename?: 'DeliverableOutput';
          company_sets?: Array<CompanySet | null> | null;
          company_reference?: string | null;
          pipeline_input?: string | null;
          model_versions: {
            __typename?: 'ModelVersionsOutput';
            location_version?: LocationVersion | null;
            timescaling_version?: TimescalingVersion | null;
            ethnicity_version?: EthnicityVersion | null;
            job_taxonomy_version?: JobTaxonomyVersion | null;
            company_mapper_version?: CompanyMapperVersion | null;
            salary_version?: SalaryVersion | null;
            seniority_version?: SeniorityVersion | null;
            weight_table?: WeightTable | null;
            remove_bad_users?: boolean | null;
          };
          pipeline: {
            __typename?: 'PipelineOutput';
            pipeline_type: PipelineType;
            columns?: Array<PipelineColumnType | null> | null;
            source?: PostingSource | null;
            individual_user_configuration?: {
              __typename?: 'IndividualUserConfigurationOutput';
              current?: boolean | null;
              full_history?: boolean | null;
            } | null;
            posting_configuration?: {
              __typename?: 'PostingConfigurationOutput';
              frequency?: PostingFrequency | null;
              source?: PostingSource | null;
            } | null;
            company_info_configuration?: {
              __typename?: 'CompanyInfoConfigurationOutput';
              include_subsidiaries?: boolean | null;
            } | null;
            custom_columns?: Array<{
              __typename?: 'CustomColumn';
              name: string;
              step?: Array<{
                __typename?: 'CustomColumnStep';
                then?: string | null;
                case?: {
                  __typename?: 'CustomColumnCase';
                  binary?: BinaryOperation | null;
                  conditions?: Array<{
                    __typename?: 'CustomColumnCondition';
                    variable: CustomColumnConditionVariable;
                    value: Array<string | null>;
                    operation: OperationType;
                  } | null> | null;
                } | null;
              } | null> | null;
            } | null> | null;
            filters?: Array<{
              __typename?: 'PipelineFilter';
              name: string;
              binary?: BinaryOperation | null;
              conditions?: Array<{
                __typename?: 'CustomColumnCondition';
                variable: CustomColumnConditionVariable;
                value: Array<string | null>;
                operation: OperationType;
              }> | null;
            } | null> | null;
          };
          s3_delivery?: {
            __typename?: 'S3DeliveryOutput';
            s3_bucket: string;
            s3_file_prefix: string;
            s3_format: S3Format;
          } | null;
          snowflake_delivery?: {
            __typename?: 'SnowflakeDeliveryOutput';
            snowflake_db: string;
            snowflake_schema: string;
            snowflake_file_prefix: string;
          } | null;
        } | null>;
      };
    }> | null;
  } | null> | null;
};

export type GetUserReportsQueryVariables = Exact<{
  ids: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;

export type GetUserReportsQuery = {
  __typename?: 'Query';
  users?: Array<{
    __typename?: 'User';
    reports?: Array<{
      __typename?: 'Report';
      uuid?: string | null;
      job_id: number;
      job_name?: string | null;
      name?: string | null;
      client?: string | null;
      status: DeliveryStatus;
      created_at?: string | null;
      updated_at?: string | null;
      report: {
        __typename?: 'ReportResponse';
        reportType: ReportTypeEnum;
        mainEntities: Array<{
          __typename?: 'EntityOutput';
          type: EntityTypeEnum;
          values: Array<string>;
        }>;
        comparisonEntities?: Array<{
          __typename?: 'EntityOutput';
          type: EntityTypeEnum;
          values: Array<string>;
        }> | null;
        filters?: Array<{
          __typename?: 'ReportFilterOutput';
          type: ReportFilterType;
          id: string;
          values: Array<string>;
        }> | null;
      };
    }> | null;
  } | null> | null;
};

export type GetClientDeliverablesQueryVariables = Exact<{
  name: Scalars['String']['input'];
}>;

export type GetClientDeliverablesQuery = {
  __typename?: 'Query';
  clientsDeliverable?: Array<{
    __typename?: 'ClientDeliverableOutput';
    job_id: number;
    status: DeliveryStatus;
    updated_at?: string | null;
    created_at?: string | null;
    deliverable: {
      __typename?: 'ClientDeliverable';
      name: string;
      client: string;
      lag_data?: boolean | null;
      company_sets?: Array<CompanySet | null> | null;
      company_reference?: string | null;
      pipeline_input?: string | null;
      deliverables: Array<{
        __typename?: 'DeliverableOutput';
        company_sets?: Array<CompanySet | null> | null;
        company_reference?: string | null;
        pipeline_input?: string | null;
        model_versions: {
          __typename?: 'ModelVersionsOutput';
          location_version?: LocationVersion | null;
          timescaling_version?: TimescalingVersion | null;
          ethnicity_version?: EthnicityVersion | null;
          job_taxonomy_version?: JobTaxonomyVersion | null;
          company_mapper_version?: CompanyMapperVersion | null;
          salary_version?: SalaryVersion | null;
          seniority_version?: SeniorityVersion | null;
          weight_table?: WeightTable | null;
          remove_bad_users?: boolean | null;
        };
        pipeline: {
          __typename?: 'PipelineOutput';
          pipeline_type: PipelineType;
          columns?: Array<PipelineColumnType | null> | null;
          source?: PostingSource | null;
          posting_configuration?: {
            __typename?: 'PostingConfigurationOutput';
            frequency?: PostingFrequency | null;
            source?: PostingSource | null;
          } | null;
          individual_user_configuration?: {
            __typename?: 'IndividualUserConfigurationOutput';
            current?: boolean | null;
            full_history?: boolean | null;
          } | null;
          company_info_configuration?: {
            __typename?: 'CompanyInfoConfigurationOutput';
            include_subsidiaries?: boolean | null;
          } | null;
          custom_columns?: Array<{
            __typename?: 'CustomColumn';
            name: string;
            step?: Array<{
              __typename?: 'CustomColumnStep';
              then?: string | null;
              case?: {
                __typename?: 'CustomColumnCase';
                binary?: BinaryOperation | null;
                conditions?: Array<{
                  __typename?: 'CustomColumnCondition';
                  variable: CustomColumnConditionVariable;
                  value: Array<string | null>;
                  operation: OperationType;
                } | null> | null;
              } | null;
            } | null> | null;
          } | null> | null;
          filters?: Array<{
            __typename?: 'PipelineFilter';
            name: string;
            binary?: BinaryOperation | null;
            conditions?: Array<{
              __typename?: 'CustomColumnCondition';
              variable: CustomColumnConditionVariable;
              value: Array<string | null>;
              operation: OperationType;
            }> | null;
          } | null> | null;
        };
        s3_delivery?: {
          __typename?: 'S3DeliveryOutput';
          s3_bucket: string;
          s3_file_prefix: string;
          s3_format: S3Format;
        } | null;
        snowflake_delivery?: {
          __typename?: 'SnowflakeDeliveryOutput';
          snowflake_db: string;
          snowflake_schema: string;
          snowflake_file_prefix: string;
        } | null;
      } | null>;
    };
    user?: {
      __typename?: 'User';
      email?: string | null;
      name?: string | null;
    } | null;
  }> | null;
};

export type GetGameResultsStatsQueryVariables = Exact<{
  msa?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
  role_k150?: InputMaybe<
    Array<Scalars['ID']['input']> | Scalars['ID']['input']
  >;
  seniority?: InputMaybe<
    Array<Scalars['ID']['input']> | Scalars['ID']['input']
  >;
  endDate?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetGameResultsStatsQuery = {
  __typename?: 'Query';
  rawComposition?: Array<{
    __typename?: 'CompositionResponse';
    metadata?: {
      __typename?: 'CompositionMetadata';
      id?: number | null;
      shortName?: string | null;
      longName?: string | null;
    } | null;
    metrics?: {
      __typename?: 'CompositionMetrics';
      headcount?: {
        __typename?: 'SingleValueMetric';
        timeseries?: Array<{
          __typename?: 'TimeDataPoint';
          id?: string | null;
          date?: string | null;
          value?: number | null;
        } | null> | null;
      } | null;
      growth_rate?: {
        __typename?: 'SingleValueMetric';
        timeseries?: Array<{
          __typename?: 'TimeDataPoint';
          id?: string | null;
          date?: string | null;
          value?: number | null;
        } | null> | null;
      } | null;
      hiring_rate?: {
        __typename?: 'SingleValueMetric';
        timeseries?: Array<{
          __typename?: 'TimeDataPoint';
          id?: string | null;
          date?: string | null;
          value?: number | null;
        } | null> | null;
      } | null;
      attrition_rate?: {
        __typename?: 'SingleValueMetric';
        timeseries?: Array<{
          __typename?: 'TimeDataPoint';
          id?: string | null;
          date?: string | null;
          value?: number | null;
        } | null> | null;
      } | null;
      tenure?: {
        __typename?: 'SingleValueMetric';
        timeseries?: Array<{
          __typename?: 'TimeDataPoint';
          id?: string | null;
          date?: string | null;
          value?: number | null;
        } | null> | null;
      } | null;
      salary?: {
        __typename?: 'SingleValueMetric';
        timeseries?: Array<{
          __typename?: 'TimeDataPoint';
          id?: string | null;
          date?: string | null;
          value?: number | null;
        } | null> | null;
      } | null;
    } | null;
  } | null> | null;
  posting?: Array<{
    __typename?: 'Posting2dResponse';
    metadata?: {
      __typename?: 'Metadata';
      id?: number | null;
      shortName?: string | null;
      longName?: string | null;
    } | null;
    category?: Array<{
      __typename?: 'Posting1dResponse';
      metadata?: {
        __typename?: 'Metadata';
        id?: number | null;
        shortName?: string | null;
        longName?: string | null;
      } | null;
      metrics?: {
        __typename?: 'PostingMetric';
        time_to_fill?: number | null;
        active?: number | null;
      } | null;
    } | null> | null;
  } | null> | null;
  currentYearSentiment?: Array<{
    __typename?: 'Sentiment2dResponse';
    metrics?: {
      __typename?: 'SentimentMetric';
      overallRating?: number | null;
    } | null;
  } | null> | null;
  previousYearSentiment?: Array<{
    __typename?: 'Sentiment2dResponse';
    metrics?: {
      __typename?: 'SentimentMetric';
      overallRating?: number | null;
    } | null;
  } | null> | null;
};

export type GetResumeQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type GetResumeQuery = {
  __typename?: 'Query';
  getResume: {
    __typename?: 'Resume';
    resume_id: string;
    name: string;
    download_link?: string | null;
    enriched_data?: string | null;
  };
};

export type SaveResumeMutationVariables = Exact<{
  input: ResumeRequest;
}>;

export type SaveResumeMutation = {
  __typename?: 'Mutation';
  saveResume: {
    __typename?: 'Resume';
    resume_id: string;
    name: string;
    download_link?: string | null;
    excel_location?: string | null;
  };
};

export type DeleteResumeMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type DeleteResumeMutation = {
  __typename?: 'Mutation';
  deleteResume: boolean;
};

export type DecryptTalentDiscoveryDataQueryVariables = Exact<{
  input: Scalars['String']['input'];
}>;

export type DecryptTalentDiscoveryDataQuery = {
  __typename?: 'Query';
  decryptString: string;
};

export type TalentDiscoveryDataQueryVariables = Exact<{
  query?: InputMaybe<TalentDiscoveryV1_5Filter>;
}>;

export type TalentDiscoveryDataQuery = {
  __typename?: 'Query';
  talent_discovery_search_v1_5: {
    __typename?: 'TalentDiscoveryResponse';
    columns: Array<string>;
    users?: Array<{
      __typename?: 'TalentDiscoveryUser';
      user_id: number;
      first_name: string;
      last_name: string;
      email: string;
      personal_email: string;
      current_title: string;
      current_company: string;
      start_date: string;
      end_date: string;
      longitude: number;
      latitude: number;
      RCID: number;
      location: string;
      gender: string;
      ethnicity: string;
      highest_degree: string;
      salary_base?: number | null;
      salary_total?: number | null;
      prestige: string;
      remote_suitability: string;
      years_of_experience: number;
      flight_risk: string;
      linkedin_url: string;
      phone_number?: string | null;
      history: Array<{
        __typename?: 'TalentDiscoveryEntity';
        first_name: string;
        last_name: string;
        email: string;
        current_title: string;
        current_company: string;
        start_date: string;
        end_date: string;
        RCID: number;
        location: string;
        highest_degree: string;
        salary_base: number;
        salary_total: number;
        prestige: string;
        remote_suitability: string;
        years_of_experience: number;
        flight_risk: string;
        linkedin_url: string;
      }>;
    } | null> | null;
    map_data?: Array<{
      __typename?: 'MapData';
      id: number;
      type: number;
      count: number;
      share: number;
      lat?: number | null;
      lon?: number | null;
      location?: string | null;
    } | null> | null;
    metadata?: {
      __typename?: 'TalentDiscoveryMetadata';
      total_pages: number;
    } | null;
  };
};

export type CreateUserServerMutationVariables = Exact<{
  input: UserRequest;
}>;

export type CreateUserServerMutation = {
  __typename?: 'Mutation';
  createUser: { __typename?: 'User'; id?: string | null };
};

export type GetClientDetailsServerQueryVariables = Exact<{
  input?: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >;
}>;

export type GetClientDetailsServerQuery = {
  __typename?: 'Query';
  clients?: Array<{
    __typename?: 'Client';
    client_name?: string | null;
    users?: Array<{
      __typename?: 'User';
      id?: string | null;
      email?: string | null;
    } | null> | null;
  } | null> | null;
};

export type UpdateUserServerMutationVariables = Exact<{
  input: UserRequest;
}>;

export type UpdateUserServerMutation = {
  __typename?: 'Mutation';
  updateUser?: { __typename?: 'User'; id?: string | null } | null;
};

export type GetTalentDiscoveryMapStatsQueryVariables = Exact<{
  company?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
  msa?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
  country?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
  job_category?: InputMaybe<
    Array<Scalars['ID']['input']> | Scalars['ID']['input']
  >;
  role_k150?: InputMaybe<
    Array<Scalars['ID']['input']> | Scalars['ID']['input']
  >;
  role_k1500?: InputMaybe<
    Array<Scalars['ID']['input']> | Scalars['ID']['input']
  >;
  seniority?: InputMaybe<
    Array<Scalars['ID']['input']> | Scalars['ID']['input']
  >;
  ethnicity?: InputMaybe<
    Array<Scalars['ID']['input']> | Scalars['ID']['input']
  >;
  start_date?: InputMaybe<Scalars['String']['input']>;
  end_date?: InputMaybe<Scalars['String']['input']>;
  dim1?: InputMaybe<Dimension1>;
}>;

export type GetTalentDiscoveryMapStatsQuery = {
  __typename?: 'Query';
  composition?: Array<{
    __typename?: 'CompositionResponse';
    metadata?: {
      __typename?: 'CompositionMetadata';
      id?: number | null;
      shortName?: string | null;
      longName?: string | null;
    } | null;
    metrics?: {
      __typename?: 'CompositionMetrics';
      growth_rate?: {
        __typename?: 'SingleValueMetric';
        timeseries?: Array<{
          __typename?: 'TimeDataPoint';
          id?: string | null;
          date?: string | null;
          value?: number | null;
        } | null> | null;
      } | null;
    } | null;
  } | null> | null;
  rawComposition?: Array<{
    __typename?: 'CompositionResponse';
    metadata?: {
      __typename?: 'CompositionMetadata';
      id?: number | null;
      shortName?: string | null;
      longName?: string | null;
    } | null;
    metrics?: {
      __typename?: 'CompositionMetrics';
      growth_rate?: {
        __typename?: 'SingleValueMetric';
        timeseries?: Array<{
          __typename?: 'TimeDataPoint';
          id?: string | null;
          date?: string | null;
          value?: number | null;
        } | null> | null;
      } | null;
      headcount?: {
        __typename?: 'SingleValueMetric';
        timeseries?: Array<{
          __typename?: 'TimeDataPoint';
          id?: string | null;
          date?: string | null;
          value?: number | null;
        } | null> | null;
      } | null;
    } | null;
  } | null> | null;
  posting?: Array<{
    __typename?: 'Posting2dResponse';
    metadata?: {
      __typename?: 'Metadata';
      id?: number | null;
      shortName?: string | null;
      longName?: string | null;
    } | null;
    category?: Array<{
      __typename?: 'Posting1dResponse';
      metrics?: {
        __typename?: 'PostingMetric';
        time_to_fill?: number | null;
        active?: number | null;
      } | null;
    } | null> | null;
  } | null> | null;
};

export type GetUserSimpleDetailsQueryVariables = Exact<{
  ids?: InputMaybe<
    | Array<InputMaybe<Scalars['String']['input']>>
    | InputMaybe<Scalars['String']['input']>
  >;
}>;

export type GetUserSimpleDetailsQuery = {
  __typename?: 'Query';
  users?: Array<{
    __typename?: 'User';
    id?: string | null;
    active?: boolean | null;
    name?: string | null;
    username?: string | null;
    role?: Role | null;
  } | null> | null;
};

export type GetUserResumesQueryVariables = Exact<{
  ids?: InputMaybe<
    | Array<InputMaybe<Scalars['String']['input']>>
    | InputMaybe<Scalars['String']['input']>
  >;
}>;

export type GetUserResumesQuery = {
  __typename?: 'Query';
  users?: Array<{
    __typename?: 'User';
    id?: string | null;
    resumes?: Array<{
      __typename?: 'Resume';
      resume_id: string;
      name: string;
      enriched_data?: string | null;
      download_link?: string | null;
    }> | null;
  } | null> | null;
};

export type GetUserDetailsQueryVariables = Exact<{
  ids?: InputMaybe<
    | Array<InputMaybe<Scalars['String']['input']>>
    | InputMaybe<Scalars['String']['input']>
  >;
}>;

export type GetUserDetailsQuery = {
  __typename?: 'Query';
  users?: Array<{
    __typename?: 'User';
    id?: string | null;
    name?: string | null;
    username?: string | null;
    email?: string | null;
    role?: Role | null;
    client_name?: string | null;
    live?: boolean | null;
    active?: boolean | null;
    tabs?: Array<Tab> | null;
    company_lists?: Array<string | null> | null;
    linkup_postings?: boolean | null;
    unified_postings?: boolean | null;
    num_seats?: string | null;
    metadata?: Array<{
      __typename?: 'KeyValueResp';
      key?: MetadataKey | null;
      value?: string | null;
    } | null> | null;
  } | null> | null;
};

export type MonthWeekIndexQueryQueryVariables = Exact<{ [key: string]: never }>;

export type MonthWeekIndexQueryQuery = {
  __typename?: 'Query';
  index?: {
    __typename?: 'IndexValues';
    startMonthIndex?: number | null;
    endMonthIndex?: number | null;
    startWeekIndex?: number | null;
    endWeekIndex?: number | null;
    maxWeekIndex?: number | null;
    endMonth?: string | null;
    maxWeek?: string | null;
  } | null;
};

export type GetCompensationDataQueryVariables = Exact<{
  filters?: InputMaybe<CompensationFilters>;
  dim1?: InputMaybe<Dimension1>;
  dim2?: InputMaybe<CompensationDimension>;
}>;

export type GetCompensationDataQuery = {
  __typename?: 'Query';
  compensation?: Array<{
    __typename?: 'CompensationResponse';
    metadata?: {
      __typename?: 'Metadata';
      id?: number | null;
      shortName?: string | null;
    } | null;
    category?: Array<{
      __typename?: 'CompensationCategory';
      metadata?: {
        __typename?: 'Metadata';
        id?: number | null;
        shortName?: string | null;
      } | null;
      metrics?: {
        __typename?: 'CompensationMetrics';
        count?: number | null;
        share?: number | null;
      } | null;
    } | null> | null;
  } | null> | null;
};

export type CompositionDataQueryVariables = Exact<{
  filters?: InputMaybe<Filters>;
  dim1?: InputMaybe<Dimension1>;
}>;

export type CompositionDataQuery = {
  __typename?: 'Query';
  composition?: Array<{
    __typename?: 'CompositionResponse';
    metadata?: {
      __typename: 'CompositionMetadata';
      id?: number | null;
      shortName?: string | null;
      longName?: string | null;
      type?: string | null;
    } | null;
    metrics?: {
      __typename: 'CompositionMetrics';
      headcount?: {
        __typename?: 'SingleValueMetric';
        timeseries?: Array<{
          __typename?: 'TimeDataPoint';
          id?: string | null;
          date?: string | null;
          value?: number | null;
        } | null> | null;
      } | null;
      growth_rate?: {
        __typename?: 'SingleValueMetric';
        timeseries?: Array<{
          __typename?: 'TimeDataPoint';
          id?: string | null;
          date?: string | null;
          value?: number | null;
        } | null> | null;
      } | null;
      hiring_rate?: {
        __typename?: 'SingleValueMetric';
        timeseries?: Array<{
          __typename?: 'TimeDataPoint';
          id?: string | null;
          date?: string | null;
          value?: number | null;
        } | null> | null;
      } | null;
      attrition_rate?: {
        __typename?: 'SingleValueMetric';
        timeseries?: Array<{
          __typename?: 'TimeDataPoint';
          id?: string | null;
          date?: string | null;
          value?: number | null;
        } | null> | null;
      } | null;
      tenure?: {
        __typename?: 'SingleValueMetric';
        timeseries?: Array<{
          __typename?: 'TimeDataPoint';
          id?: string | null;
          date?: string | null;
          value?: number | null;
        } | null> | null;
      } | null;
      salary?: {
        __typename?: 'SingleValueMetric';
        timeseries?: Array<{
          __typename?: 'TimeDataPoint';
          id?: string | null;
          date?: string | null;
          value?: number | null;
        } | null> | null;
      } | null;
      skills?: {
        __typename?: 'CategoryMetric';
        category?: Array<{
          __typename?: 'CategoryTimeseries';
          metadata?: {
            __typename: 'CompositionMetadata';
            id?: number | null;
            shortName?: string | null;
            longName?: string | null;
            type?: string | null;
          } | null;
          timeseries?: Array<{
            __typename?: 'TimeDataPoint';
            id?: string | null;
            date?: string | null;
            label?: string | null;
            value?: number | null;
            count?: number | null;
            share?: number | null;
          } | null> | null;
        } | null> | null;
      } | null;
      genders?: {
        __typename?: 'CategoryMetric';
        category?: Array<{
          __typename?: 'CategoryTimeseries';
          metadata?: {
            __typename: 'CompositionMetadata';
            id?: number | null;
            shortName?: string | null;
            longName?: string | null;
            type?: string | null;
          } | null;
          timeseries?: Array<{
            __typename?: 'TimeDataPoint';
            id?: string | null;
            date?: string | null;
            count?: number | null;
            share?: number | null;
          } | null> | null;
        } | null> | null;
      } | null;
      ethnicities?: {
        __typename?: 'CategoryMetric';
        category?: Array<{
          __typename?: 'CategoryTimeseries';
          metadata?: {
            __typename: 'CompositionMetadata';
            id?: number | null;
            shortName?: string | null;
            longName?: string | null;
            type?: string | null;
          } | null;
          timeseries?: Array<{
            __typename?: 'TimeDataPoint';
            id?: string | null;
            date?: string | null;
            count?: number | null;
            share?: number | null;
          } | null> | null;
        } | null> | null;
      } | null;
      educations?: {
        __typename?: 'CategoryMetric';
        category?: Array<{
          __typename?: 'CategoryTimeseries';
          metadata?: {
            __typename: 'CompositionMetadata';
            id?: number | null;
            shortName?: string | null;
            longName?: string | null;
            type?: string | null;
          } | null;
          timeseries?: Array<{
            __typename?: 'TimeDataPoint';
            id?: string | null;
            date?: string | null;
            count?: number | null;
            share?: number | null;
          } | null> | null;
        } | null> | null;
      } | null;
      geographies?: {
        __typename?: 'CategoryMetric';
        category?: Array<{
          __typename?: 'CategoryTimeseries';
          metadata?: {
            __typename: 'CompositionMetadata';
            id?: number | null;
            shortName?: string | null;
            longName?: string | null;
            type?: string | null;
          } | null;
          timeseries?: Array<{
            __typename?: 'TimeDataPoint';
            id?: string | null;
            date?: string | null;
            count?: number | null;
            share?: number | null;
          } | null> | null;
        } | null> | null;
      } | null;
      job_categories?: {
        __typename?: 'CategoryMetric';
        category?: Array<{
          __typename?: 'CategoryTimeseries';
          metadata?: {
            __typename: 'CompositionMetadata';
            id?: number | null;
            shortName?: string | null;
            longName?: string | null;
            type?: string | null;
          } | null;
          timeseries?: Array<{
            __typename?: 'TimeDataPoint';
            id?: string | null;
            date?: string | null;
            count?: number | null;
            share?: number | null;
          } | null> | null;
        } | null> | null;
      } | null;
      industries?: {
        __typename?: 'CategoryMetric';
        category?: Array<{
          __typename?: 'CategoryTimeseries';
          metadata?: {
            __typename: 'CompositionMetadata';
            id?: number | null;
            shortName?: string | null;
            longName?: string | null;
            type?: string | null;
          } | null;
          timeseries?: Array<{
            __typename?: 'TimeDataPoint';
            id?: string | null;
            date?: string | null;
            count?: number | null;
            share?: number | null;
          } | null> | null;
        } | null> | null;
      } | null;
    } | null;
  } | null> | null;
};

export type CompositionSummaryDataQueryVariables = Exact<{
  filters?: InputMaybe<Filters>;
  dim1?: InputMaybe<Dimension1>;
}>;

export type CompositionSummaryDataQuery = {
  __typename?: 'Query';
  composition?: Array<{
    __typename?: 'CompositionResponse';
    metadata?: {
      __typename: 'CompositionMetadata';
      id?: number | null;
      shortName?: string | null;
      longName?: string | null;
      type?: string | null;
    } | null;
    metrics?: {
      __typename: 'CompositionMetrics';
      headcount?: {
        __typename?: 'SingleValueMetric';
        timeseries?: Array<{
          __typename?: 'TimeDataPoint';
          id?: string | null;
          date?: string | null;
          value?: number | null;
        } | null> | null;
      } | null;
      growth_rate?: {
        __typename?: 'SingleValueMetric';
        timeseries?: Array<{
          __typename?: 'TimeDataPoint';
          id?: string | null;
          date?: string | null;
          value?: number | null;
        } | null> | null;
      } | null;
      hiring_rate?: {
        __typename?: 'SingleValueMetric';
        timeseries?: Array<{
          __typename?: 'TimeDataPoint';
          id?: string | null;
          date?: string | null;
          value?: number | null;
        } | null> | null;
      } | null;
      attrition_rate?: {
        __typename?: 'SingleValueMetric';
        timeseries?: Array<{
          __typename?: 'TimeDataPoint';
          id?: string | null;
          date?: string | null;
          value?: number | null;
        } | null> | null;
      } | null;
    } | null;
  } | null> | null;
};

export type GetEntityDataQueryVariables = Exact<{
  filters?: InputMaybe<EntityFilters>;
}>;

export type GetEntityDataQuery = {
  __typename?: 'Query';
  entity?: {
    __typename?: 'EntityResponse';
    summary?: {
      __typename?: 'EntitySummary';
      name?: string | null;
      description?: string | null;
      founded?: string | null;
      headquarters?: string | null;
    } | null;
    competitors?: Array<{
      __typename?: 'EntityCompetitors';
      closeness_score: number;
      metadata?: {
        __typename?: 'Metadata';
        id?: number | null;
        shortName?: string | null;
        longName?: string | null;
      } | null;
    } | null> | null;
  } | null;
};

export type CompanySummaryMapDataQueryVariables = Exact<{
  query?: InputMaybe<TalentDiscoveryV1_5Filter>;
}>;

export type CompanySummaryMapDataQuery = {
  __typename?: 'Query';
  talent_discovery_search_v1_5: {
    __typename?: 'TalentDiscoveryResponse';
    map_data?: Array<{
      __typename?: 'MapData';
      id: number;
      type: number;
      count: number;
      share: number;
      lat?: number | null;
      lon?: number | null;
      location?: string | null;
    } | null> | null;
  };
};

export type GeographySummaryMapDataQueryVariables = Exact<{
  query: MapCoordsReq;
}>;

export type GeographySummaryMapDataQuery = {
  __typename?: 'Query';
  mapCoords: {
    __typename?: 'MapCoordsResp';
    region?: Array<
      Array<{
        __typename?: 'MapCoordsItem';
        id: number;
        name: string;
        lat: number;
        lon: number;
      }>
    > | null;
    country?: Array<
      Array<{
        __typename?: 'MapCoordsItem';
        id: number;
        name: string;
        lat: number;
        lon: number;
      }>
    > | null;
    msa?: Array<
      Array<{
        __typename?: 'MapCoordsItem';
        id: number;
        name: string;
        lat: number;
        lon: number;
      }>
    > | null;
  };
};

export type GetNameRecommendationsQueryVariables = Exact<{
  filters?: InputMaybe<TalentDiscoveryV1_5Filter>;
}>;

export type GetNameRecommendationsQuery = {
  __typename?: 'Query';
  talent_discovery_name_recommendations_v1_5?: Array<{
    __typename?: 'NameRecommendation';
    user_name: string;
  } | null> | null;
};

export type PostingsDataQueryVariables = Exact<{
  filters?: InputMaybe<PostingFilters>;
  dim1?: InputMaybe<Dimension1>;
}>;

export type PostingsDataQuery = {
  __typename?: 'Query';
  posting?: Array<{
    __typename?: 'Posting2dResponse';
    metadata?: {
      __typename?: 'Metadata';
      id?: number | null;
      shortName?: string | null;
      longName?: string | null;
    } | null;
    category?: Array<{
      __typename?: 'Posting1dResponse';
      metadata?: {
        __typename?: 'Metadata';
        id?: number | null;
        shortName?: string | null;
        longName?: string | null;
      } | null;
      metrics?: {
        __typename?: 'PostingMetric';
        salary?: number | null;
        time_to_fill?: number | null;
        active?: number | null;
        new?: number | null;
        removed?: number | null;
        expected_hires?: number | null;
      } | null;
    } | null> | null;
  } | null> | null;
};

export type GeoSummaryPostingsDataQueryVariables = Exact<{
  filters?: InputMaybe<PostingFilters>;
  dim1?: InputMaybe<Dimension1>;
}>;

export type GeoSummaryPostingsDataQuery = {
  __typename?: 'Query';
  posting?: Array<{
    __typename?: 'Posting2dResponse';
    metadata?: {
      __typename?: 'Metadata';
      id?: number | null;
      shortName?: string | null;
      longName?: string | null;
    } | null;
    category?: Array<{
      __typename?: 'Posting1dResponse';
      metadata?: {
        __typename?: 'Metadata';
        id?: number | null;
        shortName?: string | null;
        longName?: string | null;
      } | null;
      metrics?: {
        __typename?: 'PostingMetric';
        salary?: number | null;
        time_to_fill?: number | null;
        active?: number | null;
      } | null;
    } | null> | null;
  } | null> | null;
};

export type PostingsGetActiveQueryVariables = Exact<{
  filters?: InputMaybe<PostingFilters>;
  dim1?: InputMaybe<Dimension1>;
}>;

export type PostingsGetActiveQuery = {
  __typename?: 'Query';
  posting?: Array<{
    __typename?: 'Posting2dResponse';
    metadata?: {
      __typename?: 'Metadata';
      id?: number | null;
      shortName?: string | null;
      longName?: string | null;
    } | null;
    category?: Array<{
      __typename?: 'Posting1dResponse';
      metadata?: {
        __typename?: 'Metadata';
        id?: number | null;
        shortName?: string | null;
        longName?: string | null;
      } | null;
      metrics?: { __typename?: 'PostingMetric'; active?: number | null } | null;
    } | null> | null;
  } | null> | null;
};

export type PostingsRolesDataQueryVariables = Exact<{
  filters?: InputMaybe<PostingFilters>;
  dim1?: InputMaybe<Dimension1>;
}>;

export type PostingsRolesDataQuery = {
  __typename?: 'Query';
  posting?: Array<{
    __typename?: 'Posting2dResponse';
    metadata?: {
      __typename?: 'Metadata';
      id?: number | null;
      shortName?: string | null;
      longName?: string | null;
    } | null;
    category?: Array<{
      __typename?: 'Posting1dResponse';
      metadata?: {
        __typename?: 'Metadata';
        id?: number | null;
        shortName?: string | null;
        longName?: string | null;
      } | null;
      metrics?: {
        __typename?: 'PostingMetric';
        count?: number | null;
        share?: number | null;
      } | null;
    } | null> | null;
  } | null> | null;
};

export type GetSchoolInfoQueryVariables = Exact<{
  name: Scalars['String']['input'];
  page: Scalars['Int']['input'];
}>;

export type GetSchoolInfoQuery = {
  __typename?: 'Query';
  schoolInfo?: Array<{
    __typename?: 'SchoolRefInfo';
    id?: string | null;
    rsid?: string | null;
    name?: string | null;
  } | null> | null;
};

export type GetScreenerDataQueryVariables = Exact<{
  filters: ScreenerReq;
}>;

export type GetScreenerDataQuery = {
  __typename?: 'Query';
  screener: {
    __typename?: 'ScreenerResp';
    pagination: {
      __typename?: 'ScreenerRespPagination';
      total_results: number;
    };
    entities: Array<{
      __typename?: 'ScreenerRespEntity';
      short_name: string;
      long_name: string;
      entity_id: number;
      headcount: Array<number>;
      inflow: Array<number>;
      outflow: Array<number>;
      hiring: Array<number>;
      attrition: Array<number>;
      growth: Array<number>;
      growth_yoy: Array<number>;
      salary: Array<number>;
      tenure: Array<number>;
    }>;
  };
};

export type SentimentSnapshotDataQueryVariables = Exact<{
  filters?: InputMaybe<SentimentFilters>;
  dim1?: InputMaybe<Dimension1>;
}>;

export type SentimentSnapshotDataQuery = {
  __typename?: 'Query';
  sentiment1d?: Array<{
    __typename?: 'Sentiment2dResponse';
    metadata?: {
      __typename?: 'Metadata';
      id?: number | null;
      shortName?: string | null;
      longName?: string | null;
    } | null;
    metrics?: {
      __typename?: 'SentimentMetric';
      overallRating?: number | null;
      overallRatingCount?: number | null;
      businessOutlookRating?: number | null;
      businessOutlookRatingCount?: number | null;
      careerOpportunitiesRating?: number | null;
      careerOpportunitiesRatingCount?: number | null;
      compensationsBenefitsRating?: number | null;
      compensationsBenefitsRatingCount?: number | null;
      workLifeBalanceRating?: number | null;
      workLifeBalanceRatingCount?: number | null;
      cultureValuesRating?: number | null;
      cultureValuesRatingCount?: number | null;
      seniorLeadershipRating?: number | null;
      seniorLeadershipRatingCount?: number | null;
      diversityInclusionRating?: number | null;
      diversityInclusionRatingCount?: number | null;
      recommendToAFriendRating?: number | null;
      recommendToAFriendRatingCount?: number | null;
      careerAdvancementScore?: number | null;
      careerAdvancementScoreCount?: number | null;
      companySizeScore?: number | null;
      companySizeScoreCount?: number | null;
      compensationScore?: number | null;
      compensationScoreCount?: number | null;
      cultureScore?: number | null;
      cultureScoreCount?: number | null;
      coworkersScore?: number | null;
      coworkersScoreCount?: number | null;
      diversityAndInclusionScore?: number | null;
      diversityAndInclusionScoreCount?: number | null;
      innovativeTechnologyScore?: number | null;
      innovativeTechnologyScoreCount?: number | null;
      managementScore?: number | null;
      managementScoreCount?: number | null;
      mentorshipScore?: number | null;
      mentorshipScoreCount?: number | null;
      onboardingScore?: number | null;
      onboardingScoreCount?: number | null;
      perksScore?: number | null;
      perksScoreCount?: number | null;
      remoteWorkScore?: number | null;
      remoteWorkScoreCount?: number | null;
      workLifeBalanceScore?: number | null;
      workLifeBalanceScoreCount?: number | null;
    } | null;
  } | null> | null;
};

export type SentimentOvertimeDataQueryVariables = Exact<{
  filters?: InputMaybe<SentimentFilters>;
  dim1?: InputMaybe<Dimension1>;
}>;

export type SentimentOvertimeDataQuery = {
  __typename?: 'Query';
  sentiment2d?: Array<{
    __typename?: 'Sentiment2dResponse';
    metadata?: {
      __typename?: 'Metadata';
      id?: number | null;
      shortName?: string | null;
      longName?: string | null;
    } | null;
    category?: Array<{
      __typename?: 'Sentiment1dResponse';
      metadata?: {
        __typename?: 'Metadata';
        id?: number | null;
        shortName?: string | null;
        longName?: string | null;
      } | null;
      metrics?: {
        __typename?: 'SentimentMetric';
        overallRating?: number | null;
        overallRatingCount?: number | null;
        businessOutlookRating?: number | null;
        businessOutlookRatingCount?: number | null;
        careerOpportunitiesRating?: number | null;
        careerOpportunitiesRatingCount?: number | null;
        compensationsBenefitsRating?: number | null;
        compensationsBenefitsRatingCount?: number | null;
        workLifeBalanceRating?: number | null;
        workLifeBalanceRatingCount?: number | null;
        cultureValuesRating?: number | null;
        cultureValuesRatingCount?: number | null;
        seniorLeadershipRating?: number | null;
        seniorLeadershipRatingCount?: number | null;
        diversityInclusionRating?: number | null;
        diversityInclusionRatingCount?: number | null;
        recommendToAFriendRating?: number | null;
        recommendToAFriendRatingCount?: number | null;
        careerAdvancementScore?: number | null;
        careerAdvancementScoreCount?: number | null;
        companySizeScore?: number | null;
        companySizeScoreCount?: number | null;
        compensationScore?: number | null;
        compensationScoreCount?: number | null;
        cultureScore?: number | null;
        cultureScoreCount?: number | null;
        coworkersScore?: number | null;
        coworkersScoreCount?: number | null;
        diversityAndInclusionScore?: number | null;
        diversityAndInclusionScoreCount?: number | null;
        innovativeTechnologyScore?: number | null;
        innovativeTechnologyScoreCount?: number | null;
        managementScore?: number | null;
        managementScoreCount?: number | null;
        mentorshipScore?: number | null;
        mentorshipScoreCount?: number | null;
        onboardingScore?: number | null;
        onboardingScoreCount?: number | null;
        perksScore?: number | null;
        perksScoreCount?: number | null;
        remoteWorkScore?: number | null;
        remoteWorkScoreCount?: number | null;
        workLifeBalanceScore?: number | null;
        workLifeBalanceScoreCount?: number | null;
      } | null;
    } | null> | null;
  } | null> | null;
};

export type SentimentGetCompanySummaryDataQueryVariables = Exact<{
  filters?: InputMaybe<SentimentFilters>;
  dim1?: InputMaybe<Dimension1>;
}>;

export type SentimentGetCompanySummaryDataQuery = {
  __typename?: 'Query';
  sentiment2d?: Array<{
    __typename?: 'Sentiment2dResponse';
    metadata?: {
      __typename?: 'Metadata';
      id?: number | null;
      shortName?: string | null;
      longName?: string | null;
    } | null;
    category?: Array<{
      __typename?: 'Sentiment1dResponse';
      metadata?: {
        __typename?: 'Metadata';
        id?: number | null;
        shortName?: string | null;
        longName?: string | null;
      } | null;
      metrics?: {
        __typename?: 'SentimentMetric';
        overallRating?: number | null;
        overallRatingCount?: number | null;
        businessOutlookRating?: number | null;
        businessOutlookRatingCount?: number | null;
        compensationsBenefitsRating?: number | null;
        compensationsBenefitsRatingCount?: number | null;
      } | null;
    } | null> | null;
  } | null> | null;
};

export type SentimentReviewsPositiveQueryVariables = Exact<{
  filters?: InputMaybe<SentimentFilters>;
  dim1?: InputMaybe<Dimension1>;
}>;

export type SentimentReviewsPositiveQuery = {
  __typename?: 'Query';
  sentimentReviews?: Array<{
    __typename?: 'SentimentReviews2dResponse';
    metadata?: {
      __typename?: 'Metadata';
      id?: number | null;
      shortName?: string | null;
      longName?: string | null;
    } | null;
    category?: Array<{
      __typename?: 'SentimentReviews1dResponse';
      metadata?: { __typename?: 'Metadata'; shortName?: string | null } | null;
      metrics?: {
        __typename?: 'SentimentReviewmetric';
        count?: number | null;
      } | null;
    } | null> | null;
  } | null> | null;
};

export type SentimentReviewsNegativeQueryVariables = Exact<{
  filters?: InputMaybe<SentimentFilters>;
  dim1?: InputMaybe<Dimension1>;
}>;

export type SentimentReviewsNegativeQuery = {
  __typename?: 'Query';
  sentimentReviews?: Array<{
    __typename?: 'SentimentReviews2dResponse';
    metadata?: {
      __typename?: 'Metadata';
      id?: number | null;
      shortName?: string | null;
      longName?: string | null;
    } | null;
    category?: Array<{
      __typename?: 'SentimentReviews1dResponse';
      metadata?: { __typename?: 'Metadata'; shortName?: string | null } | null;
      metrics?: {
        __typename?: 'SentimentReviewmetric';
        count?: number | null;
      } | null;
    } | null> | null;
  } | null> | null;
};

export type TalentDiscoveryDataOldQueryVariables = Exact<{
  query?: InputMaybe<TalentDiscoveryV1_5Filter>;
}>;

export type TalentDiscoveryDataOldQuery = {
  __typename?: 'Query';
  talent_discovery_search_v1_5: {
    __typename?: 'TalentDiscoveryResponse';
    columns: Array<string>;
    users?: Array<{
      __typename?: 'TalentDiscoveryUser';
      user_id: number;
      first_name: string;
      last_name: string;
      email: string;
      personal_email: string;
      current_title: string;
      current_company: string;
      start_date: string;
      end_date: string;
      longitude: number;
      latitude: number;
      RCID: number;
      location: string;
      gender: string;
      ethnicity: string;
      highest_degree: string;
      salary_base?: number | null;
      salary_total?: number | null;
      prestige: string;
      remote_suitability: string;
      years_of_experience: number;
      flight_risk: string;
      linkedin_url: string;
      phone_number?: string | null;
      history: Array<{
        __typename?: 'TalentDiscoveryEntity';
        first_name: string;
        last_name: string;
        email: string;
        current_title: string;
        current_company: string;
        start_date: string;
        end_date: string;
        RCID: number;
        location: string;
        highest_degree: string;
        salary_base: number;
        salary_total: number;
        prestige: string;
        remote_suitability: string;
        years_of_experience: number;
        flight_risk: string;
        linkedin_url: string;
      }>;
    } | null> | null;
    map_data?: Array<{
      __typename?: 'MapData';
      id: number;
      type: number;
      count: number;
      share: number;
      lat?: number | null;
      lon?: number | null;
      location?: string | null;
    } | null> | null;
    metadata?: {
      __typename?: 'TalentDiscoveryMetadata';
      total_pages: number;
    } | null;
  };
};

export type TransitionDataQueryVariables = Exact<{
  filters?: InputMaybe<TransitionFilters>;
  dim1?: InputMaybe<Dimension1>;
  dim2?: InputMaybe<TransitionDimension>;
}>;

export type TransitionDataQuery = {
  __typename?: 'Query';
  transitions2D?: Array<{
    __typename?: 'Transition2DResponse';
    metadata?: {
      __typename?: 'Metadata';
      id?: number | null;
      shortName?: string | null;
      longName?: string | null;
    } | null;
    metrics?: {
      __typename?: 'TransitionMetrics';
      count?: number | null;
      role?: Array<{
        __typename?: 'TransitionInfo';
        id?: number | null;
        shortName?: string | null;
        longName?: string | null;
        current?: number | null;
        currentShare?: number | null;
        inflow?: number | null;
        inflowShare?: number | null;
        outflow?: number | null;
        outflowShare?: number | null;
      } | null> | null;
      industry?: Array<{
        __typename?: 'TransitionInfo';
        id?: number | null;
        shortName?: string | null;
        longName?: string | null;
        current?: number | null;
        currentShare?: number | null;
        inflow?: number | null;
        inflowShare?: number | null;
        outflow?: number | null;
        outflowShare?: number | null;
      } | null> | null;
      geography?: Array<{
        __typename?: 'TransitionInfo';
        id?: number | null;
        shortName?: string | null;
        longName?: string | null;
        current?: number | null;
        currentShare?: number | null;
        inflow?: number | null;
        inflowShare?: number | null;
        outflow?: number | null;
        outflowShare?: number | null;
      } | null> | null;
      seniority?: Array<{
        __typename?: 'TransitionInfo';
        id?: number | null;
        shortName?: string | null;
        longName?: string | null;
        current?: number | null;
        currentShare?: number | null;
        inflow?: number | null;
        inflowShare?: number | null;
        outflow?: number | null;
        outflowShare?: number | null;
      } | null> | null;
      gender?: Array<{
        __typename?: 'TransitionInfo';
        id?: number | null;
        shortName?: string | null;
        longName?: string | null;
        current?: number | null;
        currentShare?: number | null;
        inflow?: number | null;
        inflowShare?: number | null;
        outflow?: number | null;
        outflowShare?: number | null;
      } | null> | null;
      ethnicity?: Array<{
        __typename?: 'TransitionInfo';
        id?: number | null;
        shortName?: string | null;
        longName?: string | null;
        current?: number | null;
        currentShare?: number | null;
        inflow?: number | null;
        inflowShare?: number | null;
        outflow?: number | null;
        outflowShare?: number | null;
      } | null> | null;
      education?: Array<{
        __typename?: 'TransitionInfo';
        id?: number | null;
        shortName?: string | null;
        longName?: string | null;
        current?: number | null;
        currentShare?: number | null;
        inflow?: number | null;
        inflowShare?: number | null;
        outflow?: number | null;
        outflowShare?: number | null;
      } | null> | null;
    } | null;
    category?: Array<{
      __typename?: 'TransitionCategory';
      metadata?: {
        __typename?: 'Metadata';
        id?: number | null;
        shortName?: string | null;
        longName?: string | null;
      } | null;
      metrics?: {
        __typename?: 'TransitionCategoryVal';
        value?: number | null;
        count?: number | null;
      } | null;
    } | null> | null;
  } | null> | null;
};

export type SelectionListQueryVariables = Exact<{ [key: string]: never }>;

export type SelectionListQuery = {
  __typename?: 'Query';
  selectionList?: {
    __typename?: 'SelectionList';
    region?: Array<{
      __typename?: 'GeoRefInfo';
      id?: string | null;
      shortName?: string | null;
      longName?: string | null;
      label?: string | null;
    } | null> | null;
    country?: Array<{
      __typename?: 'GeoRefInfo';
      id?: string | null;
      shortName?: string | null;
      longName?: string | null;
      region?: Array<string | null> | null;
      label?: string | null;
    } | null> | null;
    state?: Array<{
      __typename?: 'GeoRefInfo';
      id?: string | null;
      shortName?: string | null;
      longName?: string | null;
      region?: Array<string | null> | null;
      country?: Array<string | null> | null;
      label?: string | null;
    } | null> | null;
    metro_area?: Array<{
      __typename?: 'GeoRefInfo';
      id?: string | null;
      shortName?: string | null;
      longName?: string | null;
      region?: Array<string | null> | null;
      country?: Array<string | null> | null;
      state?: Array<string | null> | null;
      label?: string | null;
    } | null> | null;
    job_category?: Array<{
      __typename?: 'RoleRefInfo';
      id?: string | null;
      shortName?: string | null;
      longName?: string | null;
      topCleanedTitles?: string | null;
      topRawTitles?: string | null;
      label?: string | null;
    } | null> | null;
    role_k50?: Array<{
      __typename?: 'RoleRefInfo';
      id?: string | null;
      shortName?: string | null;
      longName?: string | null;
      topCleanedTitles?: string | null;
      topRawTitles?: string | null;
      label?: string | null;
      job_category?: string | null;
    } | null> | null;
    role_k150?: Array<{
      __typename?: 'RoleRefInfo';
      id?: string | null;
      shortName?: string | null;
      longName?: string | null;
      topCleanedTitles?: string | null;
      topRawTitles?: string | null;
      label?: string | null;
      job_category?: string | null;
      role_k50?: string | null;
    } | null> | null;
    role_k300?: Array<{
      __typename?: 'RoleRefInfo';
      id?: string | null;
      shortName?: string | null;
      longName?: string | null;
      topCleanedTitles?: string | null;
      topRawTitles?: string | null;
      label?: string | null;
      job_category?: string | null;
      role_k50?: string | null;
      role_k150?: string | null;
    } | null> | null;
    role_k500?: Array<{
      __typename?: 'RoleRefInfo';
      id?: string | null;
      shortName?: string | null;
      topCleanedTitles?: string | null;
      topRawTitles?: string | null;
      longName?: string | null;
      label?: string | null;
      job_category?: string | null;
      role_k50?: string | null;
      role_k150?: string | null;
      role_k300?: string | null;
    } | null> | null;
    role_k1000?: Array<{
      __typename?: 'RoleRefInfo';
      id?: string | null;
      shortName?: string | null;
      longName?: string | null;
      topCleanedTitles?: string | null;
      topRawTitles?: string | null;
      label?: string | null;
      job_category?: string | null;
      role_k50?: string | null;
      role_k150?: string | null;
      role_k300?: string | null;
      role_k500?: string | null;
    } | null> | null;
    role_k1250?: Array<{
      __typename?: 'RoleRefInfo';
      id?: string | null;
      shortName?: string | null;
      longName?: string | null;
      topCleanedTitles?: string | null;
      topRawTitles?: string | null;
      label?: string | null;
      job_category?: string | null;
      role_k50?: string | null;
      role_k150?: string | null;
      role_k300?: string | null;
      role_k500?: string | null;
      role_k1000?: string | null;
    } | null> | null;
    role_k1500?: Array<{
      __typename?: 'RoleRefInfo';
      id?: string | null;
      shortName?: string | null;
      longName?: string | null;
      topCleanedTitles?: string | null;
      topRawTitles?: string | null;
      label?: string | null;
      job_category?: string | null;
      role_k50?: string | null;
      role_k150?: string | null;
      role_k300?: string | null;
      role_k500?: string | null;
      role_k1000?: string | null;
      role_k1250?: string | null;
    } | null> | null;
    seniority?: Array<{
      __typename?: 'RefInfo';
      id?: string | null;
      shortName?: string | null;
      longName?: string | null;
      label?: string | null;
    } | null> | null;
    ethnicity?: Array<{
      __typename?: 'RefInfo';
      id?: string | null;
      shortName?: string | null;
      longName?: string | null;
      label?: string | null;
    } | null> | null;
    gender?: Array<{
      __typename?: 'RefInfo';
      id?: string | null;
      shortName?: string | null;
      longName?: string | null;
      label?: string | null;
    } | null> | null;
    highest_degree?: Array<{
      __typename?: 'RefInfo';
      id?: string | null;
      shortName?: string | null;
      longName?: string | null;
      label?: string | null;
    } | null> | null;
    education?: Array<{
      __typename?: 'RefInfo';
      id?: string | null;
      shortName?: string | null;
      longName?: string | null;
      label?: string | null;
    } | null> | null;
    skill_k25?: Array<{
      __typename?: 'SkillRefInfo';
      id?: string | null;
      shortName?: string | null;
      longName?: string | null;
      label?: string | null;
    } | null> | null;
    skill_k50?: Array<{
      __typename?: 'SkillRefInfo';
      id?: string | null;
      shortName?: string | null;
      skill_k25?: string | null;
      longName?: string | null;
      label?: string | null;
    } | null> | null;
    skill_k75?: Array<{
      __typename?: 'SkillRefInfo';
      id?: string | null;
      shortName?: string | null;
      skill_k25?: string | null;
      skill_k50?: string | null;
      topSkills?: string | null;
      longName?: string | null;
      label?: string | null;
    } | null> | null;
    skill_k700?: Array<{
      __typename?: 'SkillRefInfo';
      id?: string | null;
      shortName?: string | null;
      skill_k25?: string | null;
      skill_k50?: string | null;
      skill_k75?: string | null;
      topSkills?: string | null;
      longName?: string | null;
      label?: string | null;
    } | null> | null;
    skill_k2500?: Array<{
      __typename?: 'SkillRefInfo';
      id?: string | null;
      shortName?: string | null;
      skill_k25?: string | null;
      skill_k50?: string | null;
      skill_k75?: string | null;
      skill_k700?: string | null;
      longName?: string | null;
      label?: string | null;
    } | null> | null;
    skill_mapped?: Array<{
      __typename?: 'SkillRefInfo';
      id?: string | null;
      shortName?: string | null;
      skill_k25?: string | null;
      skill_k50?: string | null;
      skill_k75?: string | null;
      skill_k700?: string | null;
      skill_k2500?: string | null;
      longName?: string | null;
      label?: string | null;
    } | null> | null;
    skill_k3000?: Array<{
      __typename?: 'SkillRefInfo';
      id?: string | null;
      shortName?: string | null;
      skill_k25?: string | null;
      skill_k50?: string | null;
      skill_k75?: string | null;
      skill_k700?: string | null;
      skill_k2500?: string | null;
      topSkills?: string | null;
      longName?: string | null;
      label?: string | null;
    } | null> | null;
  } | null;
};

export type CompanyReportSelectionListQueryVariables = Exact<{
  [key: string]: never;
}>;

export type CompanyReportSelectionListQuery = {
  __typename?: 'Query';
  selectionList?: {
    __typename?: 'SelectionList';
    company_report?: Array<{
      __typename?: 'CompanyRefInfo';
      id?: string | null;
      shortName?: string | null;
      longName?: string | null;
      rank?: number | null;
      isin?: string | null;
      sedol?: string | null;
      ticker?: string | null;
      rcid?: string | null;
      version?: string | null;
      label?: string | null;
      industry?: string | null;
      dataUnavailableIn?: {
        __typename?: 'ManifestKind';
        position?: boolean | null;
        posting?: boolean | null;
        sentiment?: boolean | null;
        sentimentReview?: boolean | null;
        transition?: boolean | null;
      } | null;
    } | null> | null;
  } | null;
};

export type CompanyIndustySelectionListQueryVariables = Exact<{
  [key: string]: never;
}>;

export type CompanyIndustySelectionListQuery = {
  __typename?: 'Query';
  selectionList?: {
    __typename?: 'SelectionList';
    company?: Array<{
      __typename?: 'CompanyRefInfo';
      id?: string | null;
      shortName?: string | null;
      rank?: number | null;
      ticker?: string | null;
      longName?: string | null;
      ricsK50Id?: string | null;
      sedol?: string | null;
      isin?: string | null;
      rcid?: string | null;
      label?: string | null;
      industry?: string | null;
    } | null> | null;
    industry?: Array<{
      __typename?: 'RefInfo';
      id?: string | null;
      shortName?: string | null;
      longName?: string | null;
      label?: string | null;
    } | null> | null;
  } | null;
};

export type CustomRoleTaxonomyQueryVariables = Exact<{
  taxonomyId: CustomRoleTaxonomySelection;
}>;

export type CustomRoleTaxonomyQuery = {
  __typename?: 'Query';
  selectionList?: {
    __typename?: 'SelectionList';
    custom_role_taxonomy?: {
      __typename?: 'CustomRoleTaxonomyRef';
      orderedLevels?: Array<{
        __typename?: 'CustomRoleTaxonomyLevel';
        id?: string | null;
        label?: string | null;
      } | null> | null;
      leafLevelData?: Array<{
        __typename?: 'CustomRoleTaxonomyPath';
        id?: string | null;
        label?: string | null;
        levelId?: string | null;
        parents?: Array<{
          __typename?: 'CustomRoleTaxonomyNode';
          id?: string | null;
          levelId?: string | null;
          label?: string | null;
        } | null> | null;
      } | null> | null;
    } | null;
  } | null;
};

export const GetBasicUserDetailsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetBasicUserDetails' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ids' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'ids' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'client_name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'active' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetBasicUserDetailsQuery,
  GetBasicUserDetailsQueryVariables
>;
export const GetAllUsersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAllUsers' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'username' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'live' } },
                { kind: 'Field', name: { kind: 'Name', value: 'legacy_id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetAllUsersQuery, GetAllUsersQueryVariables>;
export const AllClientsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AllClients' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'clients' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'client_name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AllClientsQuery, AllClientsQueryVariables>;
export const AllClientsTableDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AllClientsTable' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'clients' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'client_name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'active' } },
                { kind: 'Field', name: { kind: 'Name', value: 'num_seats' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'users' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AllClientsTableQuery,
  AllClientsTableQueryVariables
>;
export const CreateClientRewriteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateClientRewrite' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'client_name' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'tabs' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'Tab' },
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'live' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Boolean' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'active' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Boolean' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'linkup_postings' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Boolean' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'unified_postings' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Boolean' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'num_seats' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'company_lists' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'data_builder_configuration' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'PipelineClientConfigInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'reports_configuration' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'ClientReportsConfigInput' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createClientGroup' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'client_name' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'client_name' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'tabs' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'tabs' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'live' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'live' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'active' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'active' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'linkup_postings' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'linkup_postings' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'unified_postings' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'unified_postings' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'num_seats' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'num_seats' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'company_lists' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'company_lists' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: {
                        kind: 'Name',
                        value: 'data_builder_configuration',
                      },
                      value: {
                        kind: 'Variable',
                        name: {
                          kind: 'Name',
                          value: 'data_builder_configuration',
                        },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'reports_configuration' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'reports_configuration' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'client_name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateClientRewriteMutation,
  CreateClientRewriteMutationVariables
>;
export const GetAllClientUsersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAllClientUsers' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientName' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'clients' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ids' },
                value: {
                  kind: 'ListValue',
                  values: [
                    {
                      kind: 'Variable',
                      name: { kind: 'Name', value: 'clientName' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'users' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'username' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'live' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'active' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'legacy_id' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetAllClientUsersQuery,
  GetAllClientUsersQueryVariables
>;
export const GetAllClientsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAllClients' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'clients' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'client_name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetAllClientsQuery, GetAllClientsQueryVariables>;
export const GetClientDetailsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetClientDetails' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'clients' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ids' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'ids' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'client_name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'company_lists' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'active' } },
                { kind: 'Field', name: { kind: 'Name', value: 'tabs' } },
                { kind: 'Field', name: { kind: 'Name', value: 'live' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'linkup_postings' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'unified_postings' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'num_seats' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data_builder_configuration' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'client_group' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pipeline_type' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'posting_source' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 's3_location' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 's3_bucket' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 's3_file_prefix' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 's3_format' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'snowflake_location' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'snowflake_db' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'snowflake_schema' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'snowflake_file_prefix',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'reports_configuration' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'report_types' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetClientDetailsQuery,
  GetClientDetailsQueryVariables
>;
export const UpdateUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'email' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'password' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'role' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Role' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'client_name' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'active' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'metadata' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'KeyValue' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'name' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'name' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'email' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'email' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'username' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'email' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'role' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'role' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'client_name' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'client_name' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'password' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'password' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'active' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'active' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'metadata' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'metadata' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'username' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                { kind: 'Field', name: { kind: 'Name', value: 'client_name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'live' } },
                { kind: 'Field', name: { kind: 'Name', value: 'active' } },
                { kind: 'Field', name: { kind: 'Name', value: 'tabs' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'company_lists' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'linkup_postings' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'unified_postings' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'num_seats' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'metadata' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateUserMutation, UpdateUserMutationVariables>;
export const UpdateUserMetaDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateUserMetaData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'metadata' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'KeyValue' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'metadata' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'metadata' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'username' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                { kind: 'Field', name: { kind: 'Name', value: 'client_name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'live' } },
                { kind: 'Field', name: { kind: 'Name', value: 'active' } },
                { kind: 'Field', name: { kind: 'Name', value: 'tabs' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'company_lists' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'linkup_postings' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'unified_postings' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'num_seats' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'metadata' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateUserMetaDataMutation,
  UpdateUserMetaDataMutationVariables
>;
export const AddUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'email' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'password' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'role' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Role' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'client_name' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'active' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Boolean' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'metadata' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'KeyValue' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'name' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'name' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'username' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'email' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'email' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'email' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'password' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'password' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'role' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'role' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'client_name' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'client_name' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'active' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'active' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'metadata' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'metadata' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'username' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                { kind: 'Field', name: { kind: 'Name', value: 'client_name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'live' } },
                { kind: 'Field', name: { kind: 'Name', value: 'active' } },
                { kind: 'Field', name: { kind: 'Name', value: 'tabs' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'company_lists' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'linkup_postings' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'unified_postings' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'num_seats' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'metadata' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AddUserMutation, AddUserMutationVariables>;
export const UpdateClientGroupRewriteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateClientGroupRewrite' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'client_name' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'tabs' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'Tab' },
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'live' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Boolean' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'active' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Boolean' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'linkup_postings' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Boolean' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'unified_postings' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Boolean' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'num_seats' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'data_builder_configuration' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'PipelineClientConfigInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'reports_configuration' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'ClientReportsConfigInput' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateClientGroup' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'client_name' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'client_name' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'tabs' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'tabs' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'live' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'live' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'active' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'active' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'linkup_postings' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'linkup_postings' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'unified_postings' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'unified_postings' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'num_seats' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'num_seats' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: {
                        kind: 'Name',
                        value: 'data_builder_configuration',
                      },
                      value: {
                        kind: 'Variable',
                        name: {
                          kind: 'Name',
                          value: 'data_builder_configuration',
                        },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'reports_configuration' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'reports_configuration' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'client_name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'company_lists' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'active' } },
                { kind: 'Field', name: { kind: 'Name', value: 'tabs' } },
                { kind: 'Field', name: { kind: 'Name', value: 'live' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'linkup_postings' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'unified_postings' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'num_seats' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateClientGroupRewriteMutation,
  UpdateClientGroupRewriteMutationVariables
>;
export const DeleteUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteUserMutation, DeleteUserMutationVariables>;
export const GetPipelineConfigsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getPipelineConfigs' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pipelineConfiguration' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'dashboard_global' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'user_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'client_id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'model_versions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'location_version' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'timescaling_version' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'ethnicity_version' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'job_taxonomy_version' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'company_mapper_version' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'salary_version' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'seniority_version' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'weight_table' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'remove_bad_users' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pipeline' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pipeline_type' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'columns' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'custom_columns' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'step' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'case' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'conditions',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'variable',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'value',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'operation',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'binary',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'then' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'filters' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'conditions' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'variable' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'value' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'operation' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'binary' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetPipelineConfigsQuery,
  GetPipelineConfigsQueryVariables
>;
export const SavePipelineConfigurationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SavePipelineConfiguration' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'configuration' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'PipelineConfigInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'savePipelineConfiguration' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'configuration' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'configuration' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'dashboard_global' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'user_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'client_id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'model_versions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'location_version' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'timescaling_version' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'ethnicity_version' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'job_taxonomy_version' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'company_mapper_version' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'salary_version' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'seniority_version' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'weight_table' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'remove_bad_users' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pipeline' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pipeline_type' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'columns' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'custom_columns' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'step' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'case' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'conditions',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'variable',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'value',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'operation',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'binary',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'then' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'filters' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'conditions' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'variable' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'value' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'operation' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'binary' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SavePipelineConfigurationMutation,
  SavePipelineConfigurationMutationVariables
>;
export const DeletePipelineConfigurationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeletePipelineConfiguration' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deletePipelineConfiguration' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeletePipelineConfigurationMutation,
  DeletePipelineConfigurationMutationVariables
>;
export const SubmitDeliverableDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SubmitDeliverable' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'deliverable' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'ClientDeliverableInput' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createDeliverables' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'deliverable' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'user_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'delivery_id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SubmitDeliverableMutation,
  SubmitDeliverableMutationVariables
>;
export const CreateReportsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createReports' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateReportRequest' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createReports' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'user_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'report_id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateReportsMutation,
  CreateReportsMutationVariables
>;
export const DeleteReportDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteReport' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteReport' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteReportMutation,
  DeleteReportMutationVariables
>;
export const RefreshReportDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RefreshReport' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'refreshReport' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RefreshReportMutation,
  RefreshReportMutationVariables
>;
export const GetReportConfigDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetReportConfig' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reports_validation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'report_validation' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'report_type' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'description' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sample_link' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'main_entity' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'selection_list' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'limit' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'required' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'label' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'description' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'comparison_entities' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'selection_list' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'limit' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'required' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'label' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'description' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'filters' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'selection_list' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'limit' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'required' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'label' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'description' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetReportConfigQuery,
  GetReportConfigQueryVariables
>;
export const GetAllUsersNameAndIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAllUsersNameAndId' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'client_name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetAllUsersNameAndIdQuery,
  GetAllUsersNameAndIdQueryVariables
>;
export const GetUserDeliverablesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetUserDeliverables' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'String' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ids' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'ids' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'deliverables' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'job_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'deliverable' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'client' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'lag_data' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'company_sets' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'company_reference',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'pipeline_input' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'deliverables' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'company_sets',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'company_reference',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'pipeline_input',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'model_versions',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'location_version',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'timescaling_version',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'ethnicity_version',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'job_taxonomy_version',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'company_mapper_version',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'salary_version',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'seniority_version',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'weight_table',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'remove_bad_users',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'pipeline' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'pipeline_type',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'columns',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'source',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value:
                                              'individual_user_configuration',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'current',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'full_history',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'posting_configuration',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'frequency',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'source',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'company_info_configuration',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'include_subsidiaries',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'custom_columns',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'name',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'step',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'case',
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value:
                                                                'conditions',
                                                            },
                                                            selectionSet: {
                                                              kind: 'SelectionSet',
                                                              selections: [
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'variable',
                                                                  },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'value',
                                                                  },
                                                                },
                                                                {
                                                                  kind: 'Field',
                                                                  name: {
                                                                    kind: 'Name',
                                                                    value:
                                                                      'operation',
                                                                  },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'binary',
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'then',
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'filters',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'name',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'conditions',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'variable',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'value',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'operation',
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'binary',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 's3_delivery',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 's3_bucket',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 's3_file_prefix',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 's3_format',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'snowflake_delivery',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'snowflake_db',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'snowflake_schema',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'snowflake_file_prefix',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updated_at' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created_at' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetUserDeliverablesQuery,
  GetUserDeliverablesQueryVariables
>;
export const GetUserReportsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetUserReports' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'String' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ids' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'ids' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'reports' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'job_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'job_name' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'client' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'report' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'reportType' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'mainEntities' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'type' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'values' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'comparisonEntities',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'type' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'values' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'filters' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'type' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'values' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created_at' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updated_at' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetUserReportsQuery, GetUserReportsQueryVariables>;
export const GetClientDeliverablesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetClientDeliverables' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'clientsDeliverable' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'client_name' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'name' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'job_id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'deliverable' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'client' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lag_data' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'company_sets' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'company_reference' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pipeline_input' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'deliverables' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'company_sets' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'company_reference',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'pipeline_input' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'model_versions' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'location_version',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'timescaling_version',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'ethnicity_version',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'job_taxonomy_version',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'company_mapper_version',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'salary_version',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'seniority_version',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'weight_table',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'remove_bad_users',
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'pipeline' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'pipeline_type',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'columns' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'source' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'posting_configuration',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'frequency',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'source',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'individual_user_configuration',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'current',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'full_history',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'company_info_configuration',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'include_subsidiaries',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'custom_columns',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'name' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'step' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'case',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'conditions',
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'variable',
                                                            },
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'value',
                                                            },
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value:
                                                                'operation',
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'binary',
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'then',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'filters' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'name' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'conditions',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'variable',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'value',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'operation',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'binary',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 's3_delivery' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 's3_bucket' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 's3_file_prefix',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 's3_format' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'snowflake_delivery',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'snowflake_db',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'snowflake_schema',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'snowflake_file_prefix',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updated_at' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetClientDeliverablesQuery,
  GetClientDeliverablesQueryVariables
>;
export const GetGameResultsStatsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetGameResultsStats' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'msa' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'role_k150' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'seniority' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'rawComposition' },
            name: { kind: 'Name', value: 'composition' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'msa' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'msa' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'role_k150' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'role_k150' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'seniority' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'seniority' },
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dim1' },
                value: { kind: 'EnumValue', value: 'geography' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'metadata' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'shortName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'longName' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'metrics' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'headcount' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'timeseries' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'date' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'value' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'growth_rate' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'timeseries' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'date' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'value' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hiring_rate' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'timeseries' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'date' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'value' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'attrition_rate' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'timeseries' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'date' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'value' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tenure' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'timeseries' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'date' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'value' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'salary' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'timeseries' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'date' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'value' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'posting' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'msa' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'msa' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'role_k150' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'role_k150' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'seniority' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'seniority' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'provider' },
                      value: {
                        kind: 'ListValue',
                        values: [
                          { kind: 'IntValue', value: '1' },
                          { kind: 'IntValue', value: '2' },
                          { kind: 'IntValue', value: '3' },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dim1' },
                value: { kind: 'EnumValue', value: 'geography' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dim2' },
                value: { kind: 'EnumValue', value: 'date' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'metadata' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'shortName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'longName' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'category' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'metadata' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'shortName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'longName' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'metrics' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'time_to_fill' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'active' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'currentYearSentiment' },
            name: { kind: 'Name', value: 'sentiment2d' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'msa' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'msa' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'role_k150' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'role_k150' },
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dim1' },
                value: { kind: 'EnumValue', value: 'geography' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dim2' },
                value: { kind: 'EnumValue', value: 'month' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'metrics' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'overallRating' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'previousYearSentiment' },
            name: { kind: 'Name', value: 'sentiment2d' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'msa' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'msa' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'role_k150' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'role_k150' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'end_date' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'endDate' },
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dim1' },
                value: { kind: 'EnumValue', value: 'geography' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dim2' },
                value: { kind: 'EnumValue', value: 'month' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'metrics' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'overallRating' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetGameResultsStatsQuery,
  GetGameResultsStatsQueryVariables
>;
export const GetResumeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetResume' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getResume' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'resume_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'download_link' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'enriched_data' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetResumeQuery, GetResumeQueryVariables>;
export const SaveResumeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SaveResume' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ResumeRequest' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'saveResume' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'resume_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'download_link' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'excel_location' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SaveResumeMutation, SaveResumeMutationVariables>;
export const DeleteResumeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteResume' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteResume' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteResumeMutation,
  DeleteResumeMutationVariables
>;
export const DecryptTalentDiscoveryDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'DecryptTalentDiscoveryData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'decryptString' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DecryptTalentDiscoveryDataQuery,
  DecryptTalentDiscoveryDataQueryVariables
>;
export const TalentDiscoveryDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'TalentDiscoveryData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'query' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'TalentDiscoveryV1_5Filter' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'talent_discovery_search_v1_5' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'query' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'query' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'columns' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'users' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'first_name' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'last_name' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'personal_email' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'current_title' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'current_company' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'start_date' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'end_date' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'longitude' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'latitude' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'RCID' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'location' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'gender' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'ethnicity' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'location' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'highest_degree' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'salary_base' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'salary_total' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'prestige' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'remote_suitability' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'years_of_experience' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'flight_risk' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'linkedin_url' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'phone_number' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'history' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'first_name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'last_name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'email' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'current_title' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'current_company' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'start_date' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'end_date' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'RCID' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'location' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'highest_degree' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'salary_base' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'salary_total' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'prestige' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'remote_suitability',
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'years_of_experience',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'flight_risk' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'linkedin_url' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'map_data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'share' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lat' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lon' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'location' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'metadata' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'total_pages' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  TalentDiscoveryDataQuery,
  TalentDiscoveryDataQueryVariables
>;
export const CreateUserServerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateUserServer' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UserRequest' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateUserServerMutation,
  CreateUserServerMutationVariables
>;
export const GetClientDetailsServerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetClientDetailsServer' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'clients' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ids' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'client_name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'users' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetClientDetailsServerQuery,
  GetClientDetailsServerQueryVariables
>;
export const UpdateUserServerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateUserServer' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UserRequest' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateUserServerMutation,
  UpdateUserServerMutationVariables
>;
export const GetTalentDiscoveryMapStatsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetTalentDiscoveryMapStats' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'company' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'msa' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'country' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'job_category' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'role_k150' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'role_k1500' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'seniority' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'ethnicity' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'start_date' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'end_date' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'dim1' } },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'Dimension1' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'composition' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'company' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'company' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'msa' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'msa' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'country' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'country' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'job_category' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'job_category' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'role_k150' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'role_k150' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'role_k1500' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'role_k1500' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'seniority' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'seniority' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'ethnicity' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'ethnicity' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'start_date' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'start_date' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'end_date' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'end_date' },
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dim1' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'dim1' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'metadata' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'shortName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'longName' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'metrics' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'growth_rate' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'timeseries' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'date' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'value' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'rawComposition' },
            name: { kind: 'Name', value: 'composition' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'company' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'company' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'msa' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'msa' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'country' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'country' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'job_category' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'job_category' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'role_k150' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'role_k150' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'role_k1500' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'role_k1500' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'seniority' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'seniority' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'ethnicity' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'ethnicity' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'raw_metrics' },
                      value: { kind: 'BooleanValue', value: true },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'start_date' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'start_date' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'end_date' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'end_date' },
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dim1' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'dim1' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'metadata' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'shortName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'longName' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'metrics' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'growth_rate' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'timeseries' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'date' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'value' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'headcount' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'timeseries' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'date' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'value' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'posting' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'company' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'company' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'msa' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'msa' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'country' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'country' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'job_category' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'job_category' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'role_k150' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'role_k150' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'role_k1500' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'role_k1500' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'seniority' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'seniority' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'provider' },
                      value: {
                        kind: 'ListValue',
                        values: [
                          { kind: 'IntValue', value: '1' },
                          { kind: 'IntValue', value: '2' },
                          { kind: 'IntValue', value: '3' },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dim1' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'dim1' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dim2' },
                value: { kind: 'EnumValue', value: 'date' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'metadata' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'shortName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'longName' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'category' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'metrics' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'time_to_fill' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'active' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetTalentDiscoveryMapStatsQuery,
  GetTalentDiscoveryMapStatsQueryVariables
>;
export const GetUserSimpleDetailsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetUserSimpleDetails' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ids' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'ids' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'active' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'username' } },
                { kind: 'Field', name: { kind: 'Name', value: 'role' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetUserSimpleDetailsQuery,
  GetUserSimpleDetailsQueryVariables
>;
export const GetUserResumesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetUserResumes' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ids' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'ids' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'resumes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'resume_id' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'enriched_data' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'download_link' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetUserResumesQuery, GetUserResumesQueryVariables>;
export const GetUserDetailsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetUserDetails' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ids' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'ids' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'username' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                { kind: 'Field', name: { kind: 'Name', value: 'client_name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'live' } },
                { kind: 'Field', name: { kind: 'Name', value: 'active' } },
                { kind: 'Field', name: { kind: 'Name', value: 'tabs' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'company_lists' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'linkup_postings' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'unified_postings' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'num_seats' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'metadata' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetUserDetailsQuery, GetUserDetailsQueryVariables>;
export const MonthWeekIndexQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'MonthWeekIndexQuery' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'index' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'startMonthIndex' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'endMonthIndex' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'startWeekIndex' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'endWeekIndex' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'maxWeekIndex' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'endMonth' } },
                { kind: 'Field', name: { kind: 'Name', value: 'maxWeek' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  MonthWeekIndexQueryQuery,
  MonthWeekIndexQueryQueryVariables
>;
export const GetCompensationDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCompensationData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filters' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'CompensationFilters' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'dim1' } },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'Dimension1' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'dim2' } },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'CompensationDimension' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'compensation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filters' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dim1' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'dim1' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dim2' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'dim2' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'metadata' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'shortName' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'category' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'metadata' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'shortName' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'metrics' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'count' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'share' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetCompensationDataQuery,
  GetCompensationDataQueryVariables
>;
export const CompositionDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CompositionData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filters' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Filters' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'dim1' } },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'Dimension1' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'composition' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filters' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dim1' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'dim1' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dim2' },
                value: { kind: 'EnumValue', value: 'month' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'metadata' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'shortName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'longName' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: '__typename' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'metrics' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: '__typename' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'headcount' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'timeseries' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'date' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'value' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'growth_rate' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'timeseries' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'date' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'value' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hiring_rate' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'timeseries' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'date' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'value' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'attrition_rate' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'timeseries' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'date' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'value' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tenure' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'timeseries' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'date' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'value' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'salary' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'timeseries' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'date' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'value' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'skills' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'category' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'metadata' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'shortName',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'longName',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'type' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: '__typename',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'timeseries' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'date' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'label',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'value',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'count',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'share',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'genders' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'category' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'metadata' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'shortName',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'longName',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'type' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: '__typename',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'timeseries' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'date' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'count',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'share',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'ethnicities' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'category' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'metadata' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'shortName',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'longName',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'type' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: '__typename',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'timeseries' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'date' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'count',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'share',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'educations' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'category' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'metadata' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'shortName',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'longName',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'type' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: '__typename',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'timeseries' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'date' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'count',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'share',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'geographies' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'category' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'metadata' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'shortName',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'longName',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'type' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: '__typename',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'timeseries' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'date' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'count',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'share',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'job_categories' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'category' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'metadata' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'shortName',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'longName',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'type' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: '__typename',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'timeseries' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'date' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'count',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'share',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'industries' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'category' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'metadata' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'shortName',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'longName',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'type' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: '__typename',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'timeseries' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'date' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'count',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'share',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CompositionDataQuery,
  CompositionDataQueryVariables
>;
export const CompositionSummaryDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CompositionSummaryData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filters' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Filters' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'dim1' } },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'Dimension1' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'composition' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filters' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dim1' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'dim1' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dim2' },
                value: { kind: 'EnumValue', value: 'month' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'metadata' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'shortName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'longName' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: '__typename' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'metrics' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: '__typename' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'headcount' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'timeseries' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'date' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'value' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'growth_rate' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'timeseries' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'date' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'value' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hiring_rate' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'timeseries' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'date' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'value' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'attrition_rate' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'timeseries' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'date' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'value' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CompositionSummaryDataQuery,
  CompositionSummaryDataQueryVariables
>;
export const GetEntityDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetEntityData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filters' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'EntityFilters' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'entity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filters' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'summary' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'description' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'founded' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'headquarters' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'competitors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'metadata' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'shortName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'longName' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'closeness_score' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetEntityDataQuery, GetEntityDataQueryVariables>;
export const CompanySummaryMapDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CompanySummaryMapData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'query' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'TalentDiscoveryV1_5Filter' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'talent_discovery_search_v1_5' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'query' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'query' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'map_data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'share' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lat' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lon' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'location' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CompanySummaryMapDataQuery,
  CompanySummaryMapDataQueryVariables
>;
export const GeographySummaryMapDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GeographySummaryMapData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'query' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'MapCoordsReq' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mapCoords' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'req' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'query' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'region' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lat' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lon' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'country' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lat' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lon' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'msa' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lat' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lon' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GeographySummaryMapDataQuery,
  GeographySummaryMapDataQueryVariables
>;
export const GetNameRecommendationsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetNameRecommendations' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filters' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'TalentDiscoveryV1_5Filter' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'talent_discovery_name_recommendations_v1_5',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'query' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filters' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'user_name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetNameRecommendationsQuery,
  GetNameRecommendationsQueryVariables
>;
export const PostingsDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'PostingsData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filters' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'PostingFilters' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'dim1' } },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'Dimension1' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'posting' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filters' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dim1' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'dim1' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dim2' },
                value: { kind: 'EnumValue', value: 'date' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'metadata' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'shortName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'longName' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'category' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'metadata' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'shortName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'longName' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'metrics' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'salary' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'time_to_fill' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'active' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'new' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'removed' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'expected_hires' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PostingsDataQuery, PostingsDataQueryVariables>;
export const GeoSummaryPostingsDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GeoSummaryPostingsData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filters' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'PostingFilters' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'dim1' } },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'Dimension1' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'posting' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filters' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dim1' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'dim1' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dim2' },
                value: { kind: 'EnumValue', value: 'date' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'metadata' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'shortName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'longName' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'category' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'metadata' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'shortName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'longName' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'metrics' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'salary' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'time_to_fill' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'active' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GeoSummaryPostingsDataQuery,
  GeoSummaryPostingsDataQueryVariables
>;
export const PostingsGetActiveDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'PostingsGetActive' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filters' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'PostingFilters' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'dim1' } },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'Dimension1' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'posting' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filters' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dim1' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'dim1' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dim2' },
                value: { kind: 'EnumValue', value: 'date' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'metadata' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'shortName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'longName' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'category' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'metadata' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'shortName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'longName' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'metrics' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'active' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PostingsGetActiveQuery,
  PostingsGetActiveQueryVariables
>;
export const PostingsRolesDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'PostingsRolesData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filters' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'PostingFilters' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'dim1' } },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'Dimension1' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'posting' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filters' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dim1' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'dim1' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dim2' },
                value: { kind: 'EnumValue', value: 'top' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'metadata' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'shortName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'longName' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'category' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'metadata' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'shortName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'longName' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'metrics' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'count' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'share' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PostingsRolesDataQuery,
  PostingsRolesDataQueryVariables
>;
export const GetSchoolInfoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetSchoolInfo' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'schoolInfo' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'in' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'name' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'name' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'page' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'page' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rsid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetSchoolInfoQuery, GetSchoolInfoQueryVariables>;
export const GetScreenerDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetScreenerData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filters' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ScreenerReq' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'screener' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filters' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pagination' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'total_results' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'entities' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'short_name' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'long_name' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'entity_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'headcount' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'inflow' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'outflow' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hiring' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'attrition' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'growth' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'growth_yoy' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'salary' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tenure' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetScreenerDataQuery,
  GetScreenerDataQueryVariables
>;
export const SentimentSnapshotDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SentimentSnapshotData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filters' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'SentimentFilters' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'dim1' } },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'Dimension1' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'sentiment1d' },
            name: { kind: 'Name', value: 'sentiment2d' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filters' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dim1' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'dim1' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dim2' },
                value: { kind: 'EnumValue', value: 'month' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'metadata' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'shortName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'longName' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'metrics' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'overallRating' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'overallRatingCount' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'businessOutlookRating' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'businessOutlookRatingCount',
                        },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'careerOpportunitiesRating',
                        },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'careerOpportunitiesRatingCount',
                        },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'compensationsBenefitsRating',
                        },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'compensationsBenefitsRatingCount',
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'workLifeBalanceRating' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'workLifeBalanceRatingCount',
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cultureValuesRating' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'cultureValuesRatingCount',
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'seniorLeadershipRating' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'seniorLeadershipRatingCount',
                        },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'diversityInclusionRating',
                        },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'diversityInclusionRatingCount',
                        },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'recommendToAFriendRating',
                        },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'recommendToAFriendRatingCount',
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'careerAdvancementScore' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'careerAdvancementScoreCount',
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'companySizeScore' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'companySizeScoreCount' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'compensationScore' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'compensationScoreCount' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cultureScore' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cultureScoreCount' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'coworkersScore' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'coworkersScoreCount' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'diversityAndInclusionScore',
                        },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'diversityAndInclusionScoreCount',
                        },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'innovativeTechnologyScore',
                        },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'innovativeTechnologyScoreCount',
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'managementScore' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'managementScoreCount' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mentorshipScore' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mentorshipScoreCount' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'onboardingScore' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'onboardingScoreCount' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'perksScore' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'perksScoreCount' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'remoteWorkScore' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'remoteWorkScoreCount' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'workLifeBalanceScore' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'workLifeBalanceScoreCount',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SentimentSnapshotDataQuery,
  SentimentSnapshotDataQueryVariables
>;
export const SentimentOvertimeDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SentimentOvertimeData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filters' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'SentimentFilters' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'dim1' } },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'Dimension1' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sentiment2d' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filters' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dim1' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'dim1' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dim2' },
                value: { kind: 'EnumValue', value: 'month' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'metadata' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'shortName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'longName' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'category' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'metadata' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'shortName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'longName' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'metrics' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'overallRating' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'overallRatingCount',
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'businessOutlookRating',
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'businessOutlookRatingCount',
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'careerOpportunitiesRating',
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'careerOpportunitiesRatingCount',
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'compensationsBenefitsRating',
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'compensationsBenefitsRatingCount',
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'workLifeBalanceRating',
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'workLifeBalanceRatingCount',
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'cultureValuesRating',
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'cultureValuesRatingCount',
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'seniorLeadershipRating',
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'seniorLeadershipRatingCount',
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'diversityInclusionRating',
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'diversityInclusionRatingCount',
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'recommendToAFriendRating',
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'recommendToAFriendRatingCount',
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'careerAdvancementScore',
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'careerAdvancementScoreCount',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'companySizeScore' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'companySizeScoreCount',
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'compensationScore',
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'compensationScoreCount',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'cultureScore' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'cultureScoreCount',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'coworkersScore' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'coworkersScoreCount',
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'diversityAndInclusionScore',
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'diversityAndInclusionScoreCount',
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'innovativeTechnologyScore',
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'innovativeTechnologyScoreCount',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'managementScore' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'managementScoreCount',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'mentorshipScore' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'mentorshipScoreCount',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'onboardingScore' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'onboardingScoreCount',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'perksScore' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'perksScoreCount' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'remoteWorkScore' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'remoteWorkScoreCount',
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'workLifeBalanceScore',
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'workLifeBalanceScoreCount',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SentimentOvertimeDataQuery,
  SentimentOvertimeDataQueryVariables
>;
export const SentimentGetCompanySummaryDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SentimentGetCompanySummaryData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filters' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'SentimentFilters' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'dim1' } },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'Dimension1' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sentiment2d' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filters' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dim1' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'dim1' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dim2' },
                value: { kind: 'EnumValue', value: 'month' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'metadata' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'shortName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'longName' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'category' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'metadata' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'shortName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'longName' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'metrics' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'overallRating' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'overallRatingCount',
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'businessOutlookRating',
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'businessOutlookRatingCount',
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'compensationsBenefitsRating',
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'compensationsBenefitsRatingCount',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SentimentGetCompanySummaryDataQuery,
  SentimentGetCompanySummaryDataQueryVariables
>;
export const SentimentReviewsPositiveDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SentimentReviewsPositive' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filters' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'SentimentFilters' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'dim1' } },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'Dimension1' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sentimentReviews' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filters' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dim1' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'dim1' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dim2' },
                value: { kind: 'EnumValue', value: 'positive' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'metadata' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'shortName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'longName' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'category' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'metadata' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'shortName' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'metrics' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'count' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SentimentReviewsPositiveQuery,
  SentimentReviewsPositiveQueryVariables
>;
export const SentimentReviewsNegativeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SentimentReviewsNegative' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filters' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'SentimentFilters' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'dim1' } },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'Dimension1' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sentimentReviews' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filters' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dim1' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'dim1' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dim2' },
                value: { kind: 'EnumValue', value: 'negative' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'metadata' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'shortName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'longName' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'category' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'metadata' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'shortName' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'metrics' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'count' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SentimentReviewsNegativeQuery,
  SentimentReviewsNegativeQueryVariables
>;
export const TalentDiscoveryDataOldDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'TalentDiscoveryDataOLD' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'query' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'TalentDiscoveryV1_5Filter' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'talent_discovery_search_v1_5' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'query' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'query' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'columns' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'users' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'first_name' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'last_name' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'personal_email' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'current_title' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'current_company' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'start_date' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'end_date' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'longitude' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'latitude' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'RCID' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'location' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'gender' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'ethnicity' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'location' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'highest_degree' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'salary_base' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'salary_total' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'prestige' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'remote_suitability' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'years_of_experience' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'flight_risk' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'linkedin_url' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'phone_number' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'history' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'first_name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'last_name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'email' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'current_title' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'current_company' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'start_date' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'end_date' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'RCID' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'location' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'highest_degree' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'salary_base' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'salary_total' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'prestige' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'remote_suitability',
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'years_of_experience',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'flight_risk' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'linkedin_url' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'map_data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'share' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lat' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lon' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'location' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'metadata' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'total_pages' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  TalentDiscoveryDataOldQuery,
  TalentDiscoveryDataOldQueryVariables
>;
export const TransitionDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'TransitionData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filters' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'TransitionFilters' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'dim1' } },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'Dimension1' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'dim2' } },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'TransitionDimension' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transitions2D' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filters' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dim1' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'dim1' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dim2' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'dim2' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'metadata' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'shortName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'longName' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'metrics' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'role' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'shortName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'longName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'current' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'currentShare' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'inflow' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'inflowShare' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'outflow' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'outflowShare' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'industry' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'shortName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'longName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'current' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'currentShare' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'inflow' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'inflowShare' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'outflow' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'outflowShare' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'geography' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'shortName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'longName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'current' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'currentShare' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'inflow' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'inflowShare' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'outflow' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'outflowShare' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'seniority' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'shortName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'longName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'current' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'currentShare' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'inflow' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'inflowShare' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'outflow' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'outflowShare' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'gender' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'shortName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'longName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'current' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'currentShare' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'inflow' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'inflowShare' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'outflow' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'outflowShare' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'ethnicity' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'shortName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'longName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'current' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'currentShare' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'inflow' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'inflowShare' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'outflow' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'outflowShare' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'education' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'shortName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'longName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'current' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'currentShare' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'inflow' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'inflowShare' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'outflow' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'outflowShare' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'category' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'metadata' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'shortName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'longName' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'metrics' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'value' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'count' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TransitionDataQuery, TransitionDataQueryVariables>;
export const SelectionListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SelectionList' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'selectionList' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'region' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'shortName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'longName' },
                      },
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'label' },
                        name: { kind: 'Name', value: 'longName' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'country' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'shortName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'longName' },
                      },
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'label' },
                        name: { kind: 'Name', value: 'longName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'region' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'state' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'shortName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'longName' },
                      },
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'label' },
                        name: { kind: 'Name', value: 'longName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'region' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'country' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'metro_area' },
                  name: { kind: 'Name', value: 'msa' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'shortName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'longName' },
                      },
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'label' },
                        name: { kind: 'Name', value: 'longName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'region' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'country' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'job_category' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'shortName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'longName' },
                      },
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'label' },
                        name: { kind: 'Name', value: 'longName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'topCleanedTitles' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'topRawTitles' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'role_k50' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'shortName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'longName' },
                      },
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'label' },
                        name: { kind: 'Name', value: 'longName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'topCleanedTitles' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'topRawTitles' },
                      },
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'job_category' },
                        name: { kind: 'Name', value: 'roleK7' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'role_k150' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'shortName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'longName' },
                      },
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'label' },
                        name: { kind: 'Name', value: 'longName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'topCleanedTitles' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'topRawTitles' },
                      },
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'job_category' },
                        name: { kind: 'Name', value: 'roleK7' },
                      },
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'role_k50' },
                        name: { kind: 'Name', value: 'roleK50' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'role_k300' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'shortName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'longName' },
                      },
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'label' },
                        name: { kind: 'Name', value: 'longName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'topCleanedTitles' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'topRawTitles' },
                      },
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'job_category' },
                        name: { kind: 'Name', value: 'roleK7' },
                      },
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'role_k50' },
                        name: { kind: 'Name', value: 'roleK50' },
                      },
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'role_k150' },
                        name: { kind: 'Name', value: 'roleK150' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'role_k500' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'shortName' },
                      },
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'longName' },
                        name: { kind: 'Name', value: 'shortName' },
                      },
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'label' },
                        name: { kind: 'Name', value: 'shortName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'topCleanedTitles' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'topRawTitles' },
                      },
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'job_category' },
                        name: { kind: 'Name', value: 'roleK7' },
                      },
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'role_k50' },
                        name: { kind: 'Name', value: 'roleK50' },
                      },
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'role_k150' },
                        name: { kind: 'Name', value: 'roleK150' },
                      },
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'role_k300' },
                        name: { kind: 'Name', value: 'roleK300' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'role_k1000' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'shortName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'longName' },
                      },
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'label' },
                        name: { kind: 'Name', value: 'longName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'topCleanedTitles' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'topRawTitles' },
                      },
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'job_category' },
                        name: { kind: 'Name', value: 'roleK7' },
                      },
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'role_k50' },
                        name: { kind: 'Name', value: 'roleK50' },
                      },
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'role_k150' },
                        name: { kind: 'Name', value: 'roleK150' },
                      },
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'role_k300' },
                        name: { kind: 'Name', value: 'roleK300' },
                      },
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'role_k500' },
                        name: { kind: 'Name', value: 'roleK500' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'role_k1250' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'shortName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'longName' },
                      },
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'label' },
                        name: { kind: 'Name', value: 'longName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'topCleanedTitles' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'topRawTitles' },
                      },
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'job_category' },
                        name: { kind: 'Name', value: 'roleK7' },
                      },
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'role_k50' },
                        name: { kind: 'Name', value: 'roleK50' },
                      },
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'role_k150' },
                        name: { kind: 'Name', value: 'roleK150' },
                      },
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'role_k300' },
                        name: { kind: 'Name', value: 'roleK300' },
                      },
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'role_k500' },
                        name: { kind: 'Name', value: 'roleK500' },
                      },
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'role_k1000' },
                        name: { kind: 'Name', value: 'roleK1000' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'role_k1500' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'shortName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'longName' },
                      },
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'label' },
                        name: { kind: 'Name', value: 'longName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'topCleanedTitles' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'topRawTitles' },
                      },
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'job_category' },
                        name: { kind: 'Name', value: 'roleK7' },
                      },
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'role_k50' },
                        name: { kind: 'Name', value: 'roleK50' },
                      },
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'role_k150' },
                        name: { kind: 'Name', value: 'roleK150' },
                      },
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'role_k300' },
                        name: { kind: 'Name', value: 'roleK300' },
                      },
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'role_k500' },
                        name: { kind: 'Name', value: 'roleK500' },
                      },
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'role_k1000' },
                        name: { kind: 'Name', value: 'roleK1000' },
                      },
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'role_k1250' },
                        name: { kind: 'Name', value: 'roleK1250' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'seniority' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'shortName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'longName' },
                      },
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'label' },
                        name: { kind: 'Name', value: 'longName' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'ethnicity' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'shortName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'longName' },
                      },
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'label' },
                        name: { kind: 'Name', value: 'longName' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'gender' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'shortName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'longName' },
                      },
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'label' },
                        name: { kind: 'Name', value: 'longName' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'highest_degree' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'shortName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'longName' },
                      },
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'label' },
                        name: { kind: 'Name', value: 'longName' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'education' },
                  name: { kind: 'Name', value: 'highest_degree' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'shortName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'longName' },
                      },
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'label' },
                        name: { kind: 'Name', value: 'longName' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'skill_k25' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'shortName' },
                      },
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'longName' },
                        name: { kind: 'Name', value: 'shortName' },
                      },
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'label' },
                        name: { kind: 'Name', value: 'shortName' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'skill_k50' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'shortName' },
                      },
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'longName' },
                        name: { kind: 'Name', value: 'shortName' },
                      },
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'label' },
                        name: { kind: 'Name', value: 'shortName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'skill_k25' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'skill_k75' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'shortName' },
                      },
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'longName' },
                        name: { kind: 'Name', value: 'shortName' },
                      },
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'label' },
                        name: { kind: 'Name', value: 'shortName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'skill_k25' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'skill_k50' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'topSkills' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'skill_k700' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'shortName' },
                      },
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'longName' },
                        name: { kind: 'Name', value: 'shortName' },
                      },
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'label' },
                        name: { kind: 'Name', value: 'shortName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'skill_k25' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'skill_k50' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'skill_k75' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'topSkills' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'skill_k2500' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'shortName' },
                      },
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'longName' },
                        name: { kind: 'Name', value: 'shortName' },
                      },
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'label' },
                        name: { kind: 'Name', value: 'shortName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'skill_k25' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'skill_k50' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'skill_k75' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'skill_k700' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'skill_mapped' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'shortName' },
                      },
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'longName' },
                        name: { kind: 'Name', value: 'shortName' },
                      },
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'label' },
                        name: { kind: 'Name', value: 'shortName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'skill_k25' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'skill_k50' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'skill_k75' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'skill_k700' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'skill_k2500' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'skill_k3000' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'shortName' },
                      },
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'longName' },
                        name: { kind: 'Name', value: 'shortName' },
                      },
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'label' },
                        name: { kind: 'Name', value: 'shortName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'skill_k25' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'skill_k50' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'skill_k75' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'skill_k700' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'skill_k2500' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'topSkills' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SelectionListQuery, SelectionListQueryVariables>;
export const CompanyReportSelectionListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CompanyReportSelectionList' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'selectionList' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'company_report' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'shortName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'longName' },
                      },
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'label' },
                        name: { kind: 'Name', value: 'shortName' },
                      },
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'industry' },
                        name: { kind: 'Name', value: 'industryId' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'rank' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isin' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sedol' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'ticker' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'rcid' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'version' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dataUnavailableIn' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'position' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'posting' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'sentiment' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'sentimentReview' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'transition' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CompanyReportSelectionListQuery,
  CompanyReportSelectionListQueryVariables
>;
export const CompanyIndustySelectionListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CompanyIndustySelectionList' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'selectionList' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'company' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'shortName' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'rank' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'ticker' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'longName' },
                      },
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'label' },
                        name: { kind: 'Name', value: 'longName' },
                      },
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'industry' },
                        name: { kind: 'Name', value: 'industryId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'ricsK50Id' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'sedol' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isin' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'rcid' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'industry' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'shortName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'longName' },
                      },
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'label' },
                        name: { kind: 'Name', value: 'longName' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CompanyIndustySelectionListQuery,
  CompanyIndustySelectionListQueryVariables
>;
export const CustomRoleTaxonomyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CustomRoleTaxonomy' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'taxonomyId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CustomRoleTaxonomySelection' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'selectionList' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'custom_role_taxonomy' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'taxonomy_id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'taxonomyId' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'orderedLevels' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'label' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leafLevelData' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'label' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'levelId' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'parents' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'levelId' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'label' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CustomRoleTaxonomyQuery,
  CustomRoleTaxonomyQueryVariables
>;
