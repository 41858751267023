import {
  CategorySubfilter,
  CustomRoleFilterElement,
  Dimension1,
  Filters,
  TalentDiscoveryV1_5Filter,
  TransitionDimension,
} from '@revelio/data-access';
import {
  FilterName,
  FilterParameterKeys,
  SelectionCategories,
  SelectionList,
  SerializedFiltersForQuery,
  ValidValueTypes,
} from '../engine/filters.model';
import { createRequest, GraphQLRequest } from 'urql';
import { get, mapKeys } from 'lodash';
import { GqlFilterKeyMapper } from '../engine/transformers.gql';
import {
  COMPOSITION_GET_DATA,
  POSTING_GET_DATA,
  POSTING_TOP_ROLES_GET_DATA,
  SENTIMENT_GET_OVERTIME_DATA,
  SENTIMENT_GET_SNAPSHOT_DATA,
  SENTIMENT_REVIEWS_NEGATIVE,
  SENTIMENT_REVIEWS_POSITIVE,
  TALENT_DISCOVERY_GET_DATA,
  TRANSITIONS_DATA,
} from '../engine/gql-models';
import { DataView, PrimaryView, TimeFrameView } from '@revelio/core';
import { getFilterChildSelectionListIds } from '../filter-components/collection';
import { SkillFilterState } from '../filter-components/talent-discovery-filter-api';
import { talentDiscoveryFilterTransformation } from '../filter-components/talent-discovery-filter-api/talent-discovery-filter-transformation';
import { RoleTaxonomyToSelectionListOverride } from '../engine/selection-lists-data-transforms';

const getDim1 = (primaryView: PrimaryView): Dimension1 => {
  switch (primaryView) {
    case PrimaryView.COMPANY:
      return Dimension1.Company;
    case PrimaryView.GEOGRAPHY:
      return Dimension1.Geography;
    case PrimaryView.ROLE:
      return Dimension1.Role;
  }
};

interface OverviewView {
  dataView: DataView.OVERVIEW;
  timeFrameView: TimeFrameView;
  selectionLists: SelectionList<ValidValueTypes>[] | undefined;
}

interface SentimentView {
  dataView: DataView.SENTIMENT_RATING;
  timeFrameView: TimeFrameView;
}

interface OtherView {
  dataView:
  | DataView.TALENT_DISCOVERY
  | DataView.SENTIMENT_REVIEW_POSITIVE
  | DataView.SENTIMENT_REVIEW_NEGATIVE
  | DataView.POSTING
  | DataView.POSTING_TOP_ROLES
  | DataView.TRANSITION;
}
type GetQueryRequestProps = (OverviewView | SentimentView | OtherView) & {
  filters: SerializedFiltersForQuery;
  primaryView: PrimaryView | null;
  skillFilters?: SkillFilterState;
};

export const getQueryRequest = (
  props: GetQueryRequestProps
): GraphQLRequest => {
  const { dataView, filters, primaryView, skillFilters } = props;

  const activeFilters = filters?.filters || {};
  const mappedFilters: Filters = mapKeys(
    activeFilters,
    (_v, key): FilterName | FilterParameterKeys => {
      const typedKey = key as keyof typeof activeFilters;
      return GqlFilterKeyMapper[typedKey] || typedKey;
    }
  );

  const dim1 = primaryView
    ? getDim1(primaryView)
    : (filters.dim1 as Dimension1);

  switch (dataView) {
    case DataView.TALENT_DISCOVERY: {
      return createRequest(TALENT_DISCOVERY_GET_DATA, {
        query: talentDiscoveryFilterTransformation({
          skillFilters: skillFilters || [],
          filters: filters as TalentDiscoveryV1_5Filter,
        }) as TalentDiscoveryV1_5Filter,
      });
    }

    case DataView.OVERVIEW: {
      const { timeFrameView, selectionLists } = props;
      return (() => {
        switch (timeFrameView) {
          case TimeFrameView.SNAPSHOT:
            return createRequest(COMPOSITION_GET_DATA, {
              // to get snapshot, date filters should be same for start + end
              filters: { ...mappedFilters, start_date: mappedFilters.end_date },
              dim1,
            });

          case TimeFrameView.OVERTIME:
            return createRequest(COMPOSITION_GET_DATA, {
              filters: {
                ...mappedFilters,
                category_sub_filter: getCompositionOvertimeRequiredSubFilterIds(
                  {
                    selectedPageFilters: filters.filters,
                    selectionLists,
                    primaryView: props.primaryView,
                  }
                ),
              },
              dim1,
            });
        }
      })();
    }
    case DataView.SENTIMENT_RATING: {
      const { timeFrameView } = props;
      return (() => {
        switch (timeFrameView) {
          case TimeFrameView.SNAPSHOT:
            return createRequest(SENTIMENT_GET_SNAPSHOT_DATA, {
              filters: mappedFilters,
              dim1,
            });

          case TimeFrameView.OVERTIME:
            return createRequest(SENTIMENT_GET_OVERTIME_DATA, {
              filters: mappedFilters,
              dim1,
            });
        }
      })();
    }

    case DataView.SENTIMENT_REVIEW_POSITIVE:
      return createRequest(SENTIMENT_REVIEWS_POSITIVE, {
        filters: mappedFilters,
        dim1,
      });

    case DataView.SENTIMENT_REVIEW_NEGATIVE:
      return createRequest(SENTIMENT_REVIEWS_NEGATIVE, {
        filters: mappedFilters,
        dim1,
      });

    case DataView.POSTING:
      return createRequest(POSTING_GET_DATA, { filters: mappedFilters, dim1 });

    case DataView.POSTING_TOP_ROLES:
      return createRequest(POSTING_TOP_ROLES_GET_DATA, {
        filters: mappedFilters,
        dim1,
      });

    case DataView.TRANSITION: {
      const dim2 = filters.inflow
        ? TransitionDimension.Inflow
        : TransitionDimension.Outflow;
      return createRequest(TRANSITIONS_DATA, {
        filters: mappedFilters,
        dim1,
        dim2,
      });
    }
  }
};

export const getCompositionOvertimeRequiredSubFilterIds = ({
  selectedPageFilters,
  selectionLists,
  primaryView,
}: {
  selectedPageFilters: SerializedFiltersForQuery['filters'];
  selectionLists: SelectionList[] | undefined;
  primaryView: PrimaryView | null;
}) => {
  const skillFilterSelectionListId = Object.keys(
    selectedPageFilters || {}
  ).find((selectionListId) =>
    [
      SelectionCategories.SKILL_K75,
      SelectionCategories.SKILL_K700,
      SelectionCategories.SKILL_K3000,
    ].includes(selectionListId as SelectionCategories)
  ) as SelectionCategories | undefined;

  const geographyFilterSelectionListId = Object.keys(
    selectedPageFilters || {}
  ).find((selectionListId) =>
    [
      SelectionCategories.REGION,
      SelectionCategories.COUNTRY,
      SelectionCategories.METRO_AREA,
    ].includes(selectionListId as SelectionCategories)
  ) as SelectionCategories | undefined;

  console.log('selectedPageFilters', selectedPageFilters);

  const roleFilterSelectionListId = Object.keys(selectedPageFilters || {}).find(
    (selectionListId) =>
      [
        SelectionCategories.JOB_CATEGORY,
        SelectionCategories.ROLE_K150,
        SelectionCategories.ROLE_K1500,
      ].includes(selectionListId as SelectionCategories)
  ) as SelectionCategories | undefined;

  const roleTaxonomySelectionListId = get(
    selectedPageFilters,
    'custom_role.entities',
    []
  ).find((roleTaxonomyEntity: { levelId: string }) =>
    [
      SelectionCategories.JOB_CATEGORY,
      SelectionCategories.ROLE_K7,
      SelectionCategories.ROLE_K150,
      SelectionCategories.ROLE_K1500,
    ].includes(roleTaxonomyEntity?.levelId as SelectionCategories)
  )?.levelId;

  const newValue = getFilterChildSelectionListIds({
    selectedFilterValues: get(
      selectedPageFilters,
      'custom_role.entities',
      []
    ).map((entity: CustomRoleFilterElement) => entity.id),
    selectionLists,
    selectedFilterSelectionListId: RoleTaxonomyToSelectionListOverride(
      roleTaxonomySelectionListId
    ),
    noSelectedFilterSelectionListId: SelectionCategories.JOB_CATEGORY,
  });
  console.log("🚀 ~ getFilterChildSelectionListIds args", {
    selectedFilterValues: get(
      selectedPageFilters,
      'custom_role.entities',
      []
    ).map((entity: CustomRoleFilterElement) => entity.id),
    selectionLists,
    selectedFilterSelectionListId: RoleTaxonomyToSelectionListOverride(
      roleTaxonomySelectionListId
    ),
    noSelectedFilterSelectionListId: SelectionCategories.JOB_CATEGORY,
  })
  console.log('getFilterChildSelectionListIds', newValue);

  const expectedSubFilterCategories: CategorySubfilter = {
    skill:
      skillFilterSelectionListId === SelectionCategories.SKILL_K3000
        ? []
        : getFilterChildSelectionListIds({
          selectedFilterValues: skillFilterSelectionListId
            ? (selectedPageFilters?.[skillFilterSelectionListId] as string[])
            : [],
          selectionLists,
          selectedFilterSelectionListId: skillFilterSelectionListId,
          noSelectedFilterSelectionListId: SelectionCategories.SKILL_K75,
        }),
    geography:
      primaryView === PrimaryView.GEOGRAPHY ||
        geographyFilterSelectionListId === SelectionCategories.METRO_AREA
        ? []
        : getFilterChildSelectionListIds({
          selectedFilterValues: geographyFilterSelectionListId
            ? (selectedPageFilters?.[
              geographyFilterSelectionListId
            ] as string[])
            : [],
          selectionLists,
          selectedFilterSelectionListId: geographyFilterSelectionListId,
          noSelectedFilterSelectionListId: SelectionCategories.REGION,
        }),
    job_category:
      primaryView === PrimaryView.ROLE ||
        roleFilterSelectionListId === SelectionCategories.ROLE_K1500
        ? []
        : getFilterChildSelectionListIds({
          selectedFilterValues: roleFilterSelectionListId
            ? (selectedPageFilters?.[roleFilterSelectionListId] as string[])
            : [],
          selectionLists,
          selectedFilterSelectionListId: roleFilterSelectionListId,
          noSelectedFilterSelectionListId: SelectionCategories.JOB_CATEGORY,
        }),
    custom_role: [
      {
        levelId: SelectionCategories.ROLE_K150,
        id: '1',
      },
    ],
    industry:
      primaryView && primaryView === PrimaryView.COMPANY
        ? []
        : getFilterChildSelectionListIds({
          selectedFilterValues: [],
          selectionLists, // by not having selectedFilterSelectionListId, it will return all the values
          noSelectedFilterSelectionListId: SelectionCategories.INDUSTRY,
        }),
  };
  return expectedSubFilterCategories;
};
