import { useEffect, useMemo, useState } from 'react';
import { useFlag } from '@unleash/proxy-client-react';
import { FeatureFlag } from '@revelio/core';
import { useIsRevelioAdmin } from '@revelio/auth';
import { clearRoleCacheFilters } from './filters.repository';

export type RoleTaxonomySettingOption = {
  label: string;
  value: RoleTaxonomySettings;
};

export enum RoleTaxonomySettings {
  DEFAULT = 'default',
  ADAPTIVE = 'adaptive',
}

const CUSTOM_ROLE_TAXONOMY_STORAGE_KEY = 'custom_role_taxonomy';
const CustomRoleTaxonomySelectOptions: RoleTaxonomySettingOption[] = [
  { label: 'Generic', value: RoleTaxonomySettings.DEFAULT },
  { label: 'Adaptive', value: RoleTaxonomySettings.ADAPTIVE },
];
const defaultOption = CustomRoleTaxonomySelectOptions[0];

export const useRoleTaxonomySetting = () => {
  const isCustomTaxonomyFeatureFlagEnabled = useFlag(
    FeatureFlag.CustomRoleTaxonomy
  );
  const { isRevelioAdmin, fetching } = useIsRevelioAdmin();
  const [selectedOption, setSelectedOption] =
    useState<RoleTaxonomySettingOption>(
      getRoleTaxonomyStorage() ?? defaultOption
    );

  const isEnabled = useMemo(
    () => isCustomTaxonomyFeatureFlagEnabled && isRevelioAdmin,
    [isCustomTaxonomyFeatureFlagEnabled, isRevelioAdmin]
  );

  useEffect(() => {
    if (fetching) return;
    const roleTaxonomyOption = getRoleTaxonomyStorage();

    if (isEnabled !== !!roleTaxonomyOption) {
      clearRoleCacheFilters();

      if (!isEnabled) {
        setRoleTaxonomyStorage(null);
      } else {
        setRoleTaxonomyStorage(defaultOption);
      }
    }
  }, [isEnabled, fetching]);

  // Logging Taxonomy Settings to assist in debugging issues while we transition to new endpoint
  useEffect(() => {
    logSetting({
      featureFlag: isEnabled,
      option: getRoleTaxonomyStorage()?.label,
    });
  }, [isEnabled, selectedOption]);

  return {
    isEnabled,
    value: isEnabled ? selectedOption.value : null,
    selectedOption,
    options: CustomRoleTaxonomySelectOptions,
    setOption: (option: RoleTaxonomySettingOption) => {
      setRoleTaxonomyStorage(option);
      setSelectedOption(option);
      clearRoleCacheFilters();
    },
  };
};

const getRoleTaxonomyStorage = () => {
  const storedValue = localStorage.getItem(CUSTOM_ROLE_TAXONOMY_STORAGE_KEY);
  if (!storedValue) return null;
  return (
    CustomRoleTaxonomySelectOptions.find((o) => o.value === storedValue) ||
    defaultOption
  );
};

const setRoleTaxonomyStorage = (option: RoleTaxonomySettingOption | null) => {
  if (option === null) {
    localStorage.removeItem(CUSTOM_ROLE_TAXONOMY_STORAGE_KEY);
  } else {
    localStorage.setItem(CUSTOM_ROLE_TAXONOMY_STORAGE_KEY, option.value);
  }
};

const logSetting = (() => {
  let lastLog = '';

  return (value: Record<string, any>) => {
    const currentLog = JSON.stringify(value);
    if (currentLog !== lastLog) {
      console.log('Custom Role Taxonomy: Settings: ', value);
      lastLog = currentLog;
    }
  };
})();
